import React from "react";
import { Box, Collapse, List, Typography } from "@mui/material";

const CollapsibleList = ({ isOpen, items, label }) => {
  // Function to parse items, clean text, and remove "Bookmark this Case"
  const formattedItems = items?.flat(1)?.map((item) => {
    try {
      const parsedItem = JSON.parse(item);
      let citation = parsedItem?.Citation_Name || item;
      return citation.replace("Bookmark this Case", "").trim(); // Remove "Bookmark this Case"
    } catch (error) {
      return item; // If not JSON, return as is
    }
  });

  // Function to extract the year from the citation
  const extractYear = (text) => {
    const match = text?.match(/\b(19|20)\d{2}\b/); // Match years like 1992, 2023, 2000
    return match ? parseInt(match[0], 10) : -Infinity; // Default to -Infinity if no year found
  };

  // Sort items: Latest year first (descending order)
  const sortedItems = [...formattedItems]
    .flat(1)
    .sort((a, b) => extractYear(b) - extractYear(a));

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      {formattedItems?.length > 0 ? (
        <List
          component="div"
          disablePadding
          sx={{
            maxHeight: "24rem",
            overflowY: "auto",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {sortedItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #ddd",
                marginX: "16px",
                paddingY: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "11px",
                    md: "12px",
                  },
                  flexGrow: 1,
                }}
              >
                {`${index + 1}- ${item}`}
              </Typography>
            </Box>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "8px",
            color: "#303f9f",
          }}
        >
          <Typography fontSize="13px" fontWeight="bold">
            No relevant {label} found
          </Typography>
        </Box>
      )}
    </Collapse>
  );
};

export default CollapsibleList;
