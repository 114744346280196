const config = {
  backendUrl: "https://rg-law-gpt-be-auth2.azurewebsites.net/api",
  apiUrl: "https://rg-law-gpt-openai-functions.azurewebsites.net/api",
  petitionUrl:
    "https://rg-lawgpt-legal-document-generation.azurewebsites.net/api",
  petitionForm:
    "https://rg-lawgpt-legal-document-generation.azurewebsites.net/api",
  azureCasesIndexUrl:
    "https://rg-law-gpt-ai-search-v4.search.windows.net/indexes/law-cases-detailed-keyword/docs/search?api-version=2024-07-01",
  azureStatutesIndexUrl:
    "https://rg-law-gpt-ai-search-v3.search.windows.net/indexes/legal-chat-statutes-index/docs/search?api-version=2024-07-01",
  baseURL: "https://dev.platform.lawgpt.pk",
  //PRODUCTION_GA_TRACKING_ID: "G-MLMSMEKZ2K",
  PRODUCTION_GA_TRACKING_ID: "G-MLMSMEKZ2K",
  documentAnalysisABaseUrl:
    "https://rg-omniaisuite-document-analysis.azurewebsites.net/api",
  legalTermUrl:
    "https://dev-legal-term-search-lawgpt.azurewebsites.net/api/search",
};

export default config;
