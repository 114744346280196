import { Drawer, Stack } from "@mui/material";
import { useMediaQuery } from "@mui/system";
import React, { useState } from "react";
import Sidebar from "../../components/layout/Sidebar";
import ChatInterface from "../../components/ChatInterface";

export const LegalChat = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
   const [isSidebarVisible, setIsSidebarVisible] = useState(false); 
    const toggleSidebarVisibility = () => {
      setIsSidebarVisible(!isSidebarVisible);
    };
  return (
    <Stack direction="row" height="calc(100vh - 50px)" width="100%">
      {isMobileView ? (
        <Drawer
          anchor="left"
          open={isSidebarVisible}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={toggleSidebarVisibility}
        >
          <Sidebar closeSidebar={toggleSidebarVisibility} />
        </Drawer>
      ) : (
        !isMobileView && <Sidebar />
      )}

      <ChatInterface toggleSidebar={toggleSidebarVisibility} />
    </Stack>
  );
};
