import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import SearchBar from "../UI/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import CenteredPagination from "../UI/CenteredPagination";
import Listing from "./Listing";
import ResultsHeader from "./ResultsHeader";
import {
  fetchListing,
  updateListingSearchTerm,
} from "../../store/CaseSearch/actions";
import NoResultsFound from "../UI/NoResultsFound";
import CaseDetailDialog from "../CaseDetailDialog";

const LegalTerms = ({ toggleSidebar }) => {
  const listingType = "legalTerms";
  const category = "legal_term";
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const dispatch = useDispatch();

  const legalTermsFilters = useSelector(
    (state) => state.listingFilters?.legalTerms
  );
  const letter = legalTermsFilters?.letter;

  const legalTermsSearchTerm = useSelector(
    (state) => state.listingSearchTerms?.legalTerms
  );
  const searchTerm = legalTermsSearchTerm?.searchTerm;

  const legalTerms = useSelector((state) => state.listings.legalTerms);
  const { loading, data } = legalTerms;

  const legalTermsCount = data?.["@odata.count"];

  const updateSearchTerm = (value) => {
    dispatch(updateListingSearchTerm(listingType, value));
  };

  const [resultsCount, setResultsCount] = useState(10);
  const [openCitations, setOpenCitations] = useState({});
  const [openCitationText, setOpenCitationText] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasSearched, setHasSearched] = useState(false);
  const [openCaseInsights, setOpenCaseInsights] = useState(false);
  const [loadingCaseInsights, setLoadingCaseInsights] = useState(false);

  const closeCitationAndText = () => {
    if (Object.keys(openCitations)?.length > 0) {
      setOpenCitations({});
    }
    if (Object.keys(openCitationText)?.length > 0) {
      setOpenCitationText({});
    }
  };

  const handleCitationsClick = (id) => {
    setOpenCitations((prev) => {
      const isAlreadyOpen = prev[id];

      const newState = isAlreadyOpen ? {} : { [id]: true };
      isAlreadyOpen && setOpenCitationText({});

      return newState;
    });
  };

  const handleKeyPress = (e) => {
    if (!searchTerm) return;
    if (e.key === "Enter") {
      setHasSearched(true);
      setCurrentPage(1);
      closeCitationAndText();
      dispatch(
        fetchListing(listingType, 1, resultsCount, searchTerm, letter, category)
      );
    }
  };

  const defaultMaxims = () => {
    setHasSearched(false);
    setCurrentPage(1);
    closeCitationAndText();
    dispatch(fetchListing(listingType, 1, resultsCount, "", letter, category));
  };

  const handlePageChange = (e, value) => {
    setCurrentPage(value);
    closeCitationAndText();
    dispatch(
      fetchListing(
        listingType,
        value,
        resultsCount,
        searchTerm,
        letter,
        category
      )
    );
  };

  const handleResultsCountChange = (e) => {
    const newCount = e.target.value;
    setResultsCount(newCount);
    setCurrentPage(1);
    dispatch(
      fetchListing(listingType, 1, newCount, searchTerm, letter, category)
    );
  };

  useEffect(() => {
    dispatch(
      fetchListing(
        listingType,
        currentPage,
        resultsCount,
        searchTerm,
        letter,
        category
      )
    );
  }, [letter]);

  useEffect(() => {
    return () => {
      dispatch(updateListingSearchTerm(listingType, ""));
    };
  }, [dispatch]);

  return (
    <>
      <Stack flexDirection="row">
        <IconButton
          size="small"
          sx={{ ml: 1, p: 0, display: { xs: "flex", lg: "none" } }}
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faFilter} color="#3c50b1" />
        </IconButton>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={updateSearchTerm}
          placeholder="Search Legal Terms..."
          onKeyPress={handleKeyPress}
          defaultMaxims={defaultMaxims}
          hasSearched={hasSearched}
          setHasSearched={setHasSearched}
        />
      </Stack>
      {/* Chips for mobile view */}
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        sx={{
          display: { xs: "flex", lg: "none" },
          justifyContent: "start",
          width: "100%",
          mb: "8px",
        }}
      >
        {[{ label: "Letter", value: letter }]
          .filter((filter) => filter.value)
          .map((filter, index) => (
            <Chip
              key={index}
              size="small"
              label={`${filter.label}: ${filter.value}`}
              sx={{
                backgroundColor: "#e3f2fd",
                color: "#1976d2",
                fontSize: { xs: "12px", sm: "14px" },
                fontWeight: "500",
              }}
            />
          ))}
      </Stack>
      {loading ? (
        <Box
          sx={{
            height: "10%",
            marginTop: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={isMobileView ? 16 : 20} />
        </Box>
      ) : legalTermsCount === 0 ? (
        <NoResultsFound />
      ) : (
        <>
          <ResultsHeader
            totalResults={legalTermsCount}
            resultsCount={resultsCount}
            handleResultsCountChange={handleResultsCountChange}
            isMobileView={isMobileView}
            label="Legal Terms Found"
          />
          <Listing
            data={data?.value}
            openCitations={openCitations}
            openCitationText={openCitationText}
            setOpenCitationText={setOpenCitationText}
            handleCitationsClick={handleCitationsClick}
            loading={loading}
            isMobileView={isMobileView}
            setOpenCaseInsights={setOpenCaseInsights}
            setLoadingCaseInsights={setLoadingCaseInsights}
          />
          <CenteredPagination
            totalResults={legalTermsCount}
            resultsCount={resultsCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <CaseDetailDialog
        open={openCaseInsights}
        onClose={() => setOpenCaseInsights(false)}
        loading={loadingCaseInsights}
      />
    </>
  );
};

export default LegalTerms;
