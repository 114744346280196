import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  InputAdornment,
  useMediaQuery,
  Tab,
  Tabs,
  CircularProgress,
  ListItemIcon,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InsightsIcon from "@mui/icons-material/Insights";
import DescriptionIcon from "@mui/icons-material/Description";
import GavelIcon from "@mui/icons-material/Gavel";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PersonIcon from "@mui/icons-material/Person";
import { fetchMaxims } from "../actions/searchActions";
import { getContentAfterHeadings } from "../utils/HelperFunction/textFormatting";

const formatCourtName = (courtName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(courtName, "text/html");

  const formatTextContent = (name) => {
    return name
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return "";

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    )
    .join(", ");

  return titleCaseNames;
};

const formatCaseTitle = (title) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(title, "text/html");

  const formatTextContent = (text) => {
    return text
      .split("Honorable")[0]
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const TabList = ({ data }) => {
  if (!data) return null;
  return (
    <List>
      {Object.entries(data)?.map(([key, value], index) => (
        <ListItem key={index}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: {
                xs: "12px",
                md: "13px",
              },
              fontWeight: "bold",
            }}
            primary={`${index + 1}- ${key}`}
            secondary={
              <Typography
                sx={{
                  fontSize: {
                    xs: "12px",
                    md: "13px",
                  },
                  mt: 0.5,
                  flexGrow: 1,
                }}
              >
                <span style={{ fontWeight: "bold", color: "#303f9f" }}>
                  Description:
                </span>{" "}
                {value}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const CaseDetail = ({ toggleAskChatbot, closeOnBack }) => {
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [checkedItems, setCheckedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalMatches, setTotalMatches] = React.useState(0);
  const [currentMatch, setCurrentMatch] = React.useState(0);
  const [highlightedHtml, setHighlightedHtml] = React.useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [maxims, setMaxims] = useState({});

  const formattedItems =
    caseItem?.Statute_Collection?.map((item) => {
      try {
        const parsedItem = JSON.parse(item);
        let citation = parsedItem?.Citation_Name || item;
        return citation.replace("Bookmark this Case", "").trim();
      } catch (error) {
        return item;
      }
    }) ?? [];

  // Function to extract the year from the citation
  const extractYear = (text) => {
    const match = text.match(/\b(19|20)\d{2}\b/);
    return match ? parseInt(match[0], 10) : -Infinity;
  };

  // Sort items: Latest year first (descending order)
  const sortedItems = [...formattedItems]?.sort(
    (a, b) => extractYear(b) - extractYear(a)
  );

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const checkboxItems = [
    {
      label: "Facts",
      color: "#ffec94",
      value: "facts",
      available: caseItem?.facts?.length > 0,
    },
    {
      label: "Issues",
      color: "#ffaeae",
      value: "issues",
      available: caseItem?.issues?.length > 0,
    },
    {
      label: "Petitioner's Arguments",
      color: "#ccffbb",
      value: "argument_by_petitioner",
      available: caseItem?.argument_by_petitioner?.length > 0,
    },
    {
      label: "Respondent's Arguments",
      color: "#cccc99",
      value: "argument_by_responses",
      available: caseItem?.argument_by_responses?.length > 0,
    },
    {
      label: "Analysis of the Law",
      color: "#56baec",
      value: "section_analysis",
      available: caseItem?.section_analysis?.length > 0,
    },
    {
      label: "Precedent Analysis",
      color: "#b4d8e7",
      value: "precedent_analysis",
      available: caseItem?.precedent_analysis?.length > 0,
    },
    {
      label: "Court's Reasoning",
      color: "#fce6e6",
      value: "courts_reasoning",
      available: caseItem?.courts_reasoning?.length > 0,
    },
    {
      label: "Conclusion",
      color: "#c0bcb6",
      value: "conclusion",
      available: caseItem?.conclusion?.length > 0,
    },
  ];

  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    const highlights = document.querySelectorAll(".highlight");
    setHighlightedElements(highlights);

    if (highlights.length > 0) {
      highlights.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = "orange";
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          el.style.backgroundColor = "yellow";
        }
      });
    }

    // Cleanup function
    return () => {};
  }, [searchTerm, currentIndex]);

  const handleCheckboxChange = (value) => {
    setActiveTab(0);
    setCheckedItems((prev) => {
      // Check if the value is already in the array
      const isValueIncluded = prev?.includes(value);

      // If included, remove it; otherwise, add it
      const updatedItems = isValueIncluded
        ? prev?.filter((item) => item !== value) // Remove the value
        : [...prev, value]; // Add the value

      return updatedItems;
    });
  };

  const highlightText = (html, search) => {
    if (!search) return { html, matchCount: 0 };
    setActiveTab(0);

    // Escape special characters in the search term
    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(
      `(${escapedTerm.replace(/\s+/g, "(\\s|<[^>]+>|\\n|\\r)*")})`,
      "gi"
    );
    let matchCount = 0;
    // Function to highlight text recursively
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(regex, (match) => {
          matchCount++;
          return `<span class="highlight" data-index="${matchCount}">${match}</span>`;
        });
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join("");
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return "";
    };

    // Parse the input HTML and highlight matches
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const highlightedHtml = Array.from(tempDiv.childNodes)
      .map(highlightNode)
      .join("");

    return { html: highlightedHtml, matchCount };
  };

  const highlightParatagsText = (text, searchTerm) => {
    if (!searchTerm) {
      return { html: text, matchCount: 0 };
    }

    const regex = new RegExp(`(${searchTerm})`, "gi");
    let matchCount = 0;

    const html = text.replace(regex, (match) => {
      matchCount++;
      return `<span style="background-color: yellow; font-weight: bold;">${match}</span>`;
    });

    return { html, matchCount };
  };

  React.useEffect(() => {
    let styledSections = "";
    let totalMatches = 0;

    if (checkedItems?.length > 0) {
      // Generate content with dynamic styles for checked items
      styledSections = checkedItems
        .map((key) => {
          const tagItem = checkboxItems.find((item) => item.value === key);
          if (!tagItem || !caseItem[key]) return "";

          const sectionContent = caseItem[key]
            .map((item) => {
              const highlighted = highlightParatagsText(item, searchTerm);
              totalMatches += highlighted.matchCount; // Count matches for each section
              return `<div>${highlighted.html}</div>`;
            })
            .join("");

          return `
            <div style="margin-top: 12px;">
              <div style="background-color: ${tagItem.color}; font-size: 14px; font-weight: bold; padding-left: 5px;">
                ${tagItem.label}
              </div>
              <div>${sectionContent}</div>
            </div>
          `;
        })
        .join("");
    } else {
      // Generate content for the default case
      const result = highlightText(
        getContentAfterHeadings(caseItem?.Case_Description_HTML || ""),
        searchTerm
      );
      styledSections = `
        <div class="default-content">
          ${result.html}
        </div>
      `;
      totalMatches = result.matchCount;
    }

    const styledHtml = `
      <style>
        .MsoNormal {
          font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
          font-size: 13px;

          text-align: justify;
        }
        .MsoNormal b {
          color: #303f9f;
        }

        img {
        display:none
      }

        .highlight-section {
          background-color: yellow;
          font-weight: bold;
        }

      @media (max-width: 480px) {
        .MsoNormal {
          font-size: 12px;
        }

      </style>
      ${styledSections}
    `;
    // Set the generated HTML and match data
    setHighlightedHtml(styledHtml);
    setTotalMatches(totalMatches);
    setCurrentMatch(totalMatches > 0 ? 1 : 0);
  }, [searchTerm, caseItem, checkedItems]);

  React.useEffect(() => {
    if (currentMatch > 0) {
      const element = document.querySelector(
        `.highlight[data-index="${currentMatch}"]`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.style.border = "1px solid #303f9f";
      }
    }
  }, [currentMatch]);

  useEffect(() => {
    const handleFetchMaxims = async () => {
      try {
        setLoading(true);
        const data = await fetchMaxims(caseItem?.Bookmark_ID);
        setMaxims(data);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Failed to fetch maxims:", error);
        }
      } finally {
        setLoading(false);
      }
    };
    handleFetchMaxims();
  }, [caseItem?.Bookmark_ID]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        flex: 5,
        flexGrow: 1,
        px: "8px",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
      }}
    >
      <React.Fragment>
        {/* Search bar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginY: "16px",
          }}
        >
          <Box sx={{ display: "flex", mr: { xs: "0px", lg: "4px" } }}>
            <IconButton
              sx={{ color: "#303f9f", paddingLeft: "0px" }}
              onClick={closeOnBack}
            >
              <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              backgroundColor: "white",
              borderRadius: "24px",
              "& .MuiInputBase-root": {
                height: "40px",
                fontSize: "14px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "24px",
              },
              "& input": {
                padding: "10px 10px",
                fontSize: "0.875rem",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#303f9f" }} />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {currentMatch}/{totalMatches}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setCurrentMatch((prev) =>
                        prev > 1 ? prev - 1 : totalMatches
                      )
                    }
                    disabled={totalMatches === 0}
                  >
                    <KeyboardArrowUpIcon
                      fontSize="small"
                      sx={{ color: "#303f9f" }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setCurrentMatch((prev) =>
                        prev < totalMatches ? prev + 1 : 1
                      )
                    }
                    disabled={totalMatches === 0}
                  >
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      sx={{ color: "#303f9f" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <IconButton
              sx={{ color: "#303f9f", paddingRight: "0px" }}
              onClick={toggleAskChatbot}
            >
              <InsightsIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            bgcolor: "#fff",
            flexGrow: 1,
            p: "12px 12px",
            m: "0px 8px 8px 8px",
            borderRadius: "8px",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              gutterBottom
              sx={{ color: "#303f9f", fontSize: "16px", fontWeight: "bold" }}
            >
              Judgment Details
            </Typography>
            <Typography
              align="center"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#303f9f" }}
            >
              Title:{" "}
              <span style={{ fontWeight: "normal", color: "#000" }}>
                {formatCaseTitle(caseItem?.Title)}
              </span>
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: "16px",
                    background: "#f5f5f5",
                    height: "123px",
                    overflowY: "auto",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#303f9f",
                      mb: 1,
                      fontSize: "14px",
                      letterSpacing: "0.5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    Parties Involved
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pl: 2,
                      pt: 0.4,
                      pb: 0.4,
                      gap: 2,
                    }}
                  >
                    {/* Appellant */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ color: "#d2042d" }} />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "text.secondary",
                            fontSize: "12px",
                            lineHeight: 1,
                            mb: 0.5,
                          }}
                        >
                          Appellant
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 600,
                            lineHeight: 1.2,
                          }}
                        >
                          {formatCaseTitle(caseItem?.Title).split(
                            "Versus"
                          )[0] || "N/A"}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Respondent */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ color: "#d2042d" }} />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "text.secondary",
                            fontSize: "12px",
                            lineHeight: 1,
                            mb: 0.5,
                          }}
                        >
                          Respondent
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 600,
                            lineHeight: 1.2,
                          }}
                        >
                          {formatCaseTitle(caseItem?.Title).split(
                            "Versus"
                          )[1] || "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    borderRadius: "16px",
                    background: "#f5f5f5",
                    height: "123px",
                    overflowY: "auto",
                    "::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#bbb",
                      borderRadius: "6px",
                    },
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#303f9f",
                      mb: 1,
                      textAlign: "center",
                      fontSize: "14px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Bench Composition
                  </Typography>

                  <Box sx={{ overflowY: "auto", pr: 1 }}>
                    <List dense sx={{ py: 0 }}>
                      {[
                        "Justice Asif Saeed Khosa",
                        "Justice Saqib Nisar",
                        "Justice Ayesha Malik",
                      ].map((judge, index) => (
                        <ListItem key={index} sx={{ px: 0, py: 0.4 }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "10px", color: "#d2042d" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={judge}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: "13px",
                                lineHeight: 1.1,
                                wordBreak: "break-word",
                              },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: "16px",
                    background: "#f5f5f5",
                    height: "123px",
                    overflowY: "auto",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#303f9f",
                      mb: 1,
                      textAlign: "center",
                      fontSize: "14px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Case Overview
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "calc(100% - 40px)",
                    }}
                  >
                    {/* Date Section */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: 1.2,
                          textAlign: "center",
                        }}
                      >
                        24 Jul 2007
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          fontSize: "12px",
                          textAlign: "center",
                          mt: 0.5,
                        }}
                      >
                        Date
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        height: "60%",
                        borderColor: "grey.300",
                        borderWidth: "1px",
                        mt: 1,
                        mx: 1,
                      }}
                    />

                    {/* Result Section */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: 1.2,
                          color: "#4CAF50",
                          textAlign: "center",
                        }}
                      >
                        Appeal Accepted
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          fontSize: "12px",
                          textAlign: "center",
                          mt: 0.5,
                        }}
                      >
                        Result
                      </Typography>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        height: "60%",
                        borderColor: "grey.300",
                        borderWidth: "1px",
                        mx: 1,
                        mt: 1,
                      }}
                    />

                    {/* Court Section */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: 1.2,
                          textAlign: "center",
                        }}
                      >
                        {formatCourtName(caseItem?.Court || "Not Available")}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          fontSize: "12px",
                          textAlign: "center",
                          mt: 0.5,
                        }}
                      >
                        Court
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 2, borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label="Judgment"
                  sx={{
                    minHeight: "40px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#303f9f",
                      color: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
                <Tab
                  label="Citations"
                  sx={{
                    minHeight: "40px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#303f9f",
                      color: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
                <Tab
                  label="Advocates"
                  sx={{
                    minHeight: "40px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#303f9f",
                      color: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
                <Tab
                  label="Statutes"
                  sx={{
                    minHeight: "40px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#303f9f",
                      color: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
                <Tab
                  label="Maxims & Terms"
                  sx={{
                    minHeight: "40px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#303f9f",
                      color: "#fff",
                      borderRadius: "12px",
                    },
                  }}
                />
              </Tabs>
            </Box>

            <Box
              sx={{
                mt: 2,
              }}
            >
              {activeTab === 0 && (
                <>
                  {/* Tags */}
                  {checkboxItems?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: "8px",
                        boxSizing: "border-box",
                        bgcolor: "#fff",
                        borderRadius: "8px",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          display: "flex",
                          fontWeight: "bold",
                          marginBottom: "6px",
                        }}
                      >
                        Select the following parts of the judgement
                      </Typography>
                      <Grid container spacing={isMobileView ? 1 : 2}>
                        {checkboxItems.map(
                          ({ label, color, value, available }) => (
                            <Grid item xs={6} lg={3} key={label}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  bgcolor: color,
                                  p: "5px",
                                  borderRadius: "4px",
                                }}
                              >
                                <Checkbox
                                  size="small"
                                  disabled={!available}
                                  checked={
                                    checkedItems?.includes(value) || false
                                  }
                                  onChange={() => handleCheckboxChange(value)}
                                  sx={{
                                    p: 0,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    color: "#000",
                                    ml: "4px",
                                    fontWeight: "bold",
                                    fontSize: { xs: "12px", lg: "13px" },
                                  }}
                                >
                                  {label}
                                </Typography>
                                {!available && (
                                  <Tooltip
                                    title={`No ${label} available for this case`}
                                    arrow
                                  >
                                    <InfoOutlinedIcon
                                      sx={{
                                        fontSize: "16px",
                                        ml: "4px",
                                        color: "#888",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  )}
                  {checkedItems?.length === 0 ? (
                    <Box
                      sx={{
                        wordBreak: "break-word",
                        fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
                        fontSize: "13px",
                        lineHeight: 1.3,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: highlightedHtml,
                      }}
                    />
                  ) : (
                    <div>
                      {checkedItems.map((key) => {
                        const tagItem = checkboxItems.find(
                          (item) => item.value === key
                        );
                        if (!tagItem || !caseItem[key]) return null;

                        return (
                          <div key={key} style={{ marginTop: "12px" }}>
                            <Typography
                              sx={{
                                backgroundColor: tagItem.color,
                                fontSize: { xs: "12px", md: "13px" },
                                fontWeight: "bold",
                                paddingLeft: "5px",
                              }}
                            >
                              {tagItem.label}
                            </Typography>
                            <List>
                              {caseItem[key].map((item, index) => {
                                const highlighted = highlightText(
                                  item,
                                  searchTerm
                                );

                                return (
                                  <ListItem key={index}>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: { xs: "12px", md: "13px" },
                                        backgroundColor: tagItem.color,
                                      }}
                                      primary={
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: highlighted.html,
                                          }}
                                        />
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {activeTab === 1 && (
                <Box
                  sx={{
                    p: 2,
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {caseItem?.Citation_Name ? (
                    <List dense sx={{ py: 0 }}>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 24 }}>
                          <DescriptionIcon
                            sx={{
                              fontSize: "16px",
                              color: "#303f9f",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 500,
                            color: "text.primary",
                          }}
                        >
                          {caseItem.Citation_Name}
                        </Typography>
                      </ListItem>
                    </List>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        minHeight: 120,
                        gap: 1.5,
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ color: "#757575", fontSize: 24 }}
                      />
                      <Typography
                        sx={{
                          color: "#757575",
                          fontSize: "13px",
                          textAlign: "center",
                        }}
                      >
                        No citation available for this case
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              {activeTab === 2 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    p: 2,
                    backgroundColor: "#fafafa",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {/* Appellant's Counsel */}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#303f9f",
                        mb: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ color: "#d2042d" }} />
                      Appellant's Counsel
                    </Typography>
                    {caseItem?.lawyers?.length > 0 ? (
                      <List dense sx={{ py: 0 }}>
                        {caseItem?.lawyers?.map((lawyer, index) => (
                          <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 24 }}>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "8px", color: "#a56d51" }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              {lawyer.name}
                              <Typography
                                component="span"
                                sx={{
                                  color: "text.secondary",
                                  fontSize: "12px",
                                  ml: 1,
                                }}
                              >
                                {lawyer.designation}
                              </Typography>
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          pl: 3,
                          color: "#757575",
                        }}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                        <Typography sx={{ fontSize: "13px" }}>
                          No counsel information available
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Respondent's Counsel */}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#303f9f",
                        mb: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ color: "#d2042d" }} />
                      Respondent's Counsel
                    </Typography>
                    {caseItem?.lawyers?.length > 0 ? (
                      <List dense sx={{ py: 0 }}>
                        {caseItem?.lawyers?.map((lawyer, index) => (
                          <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 24 }}>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "8px", color: "#a56d51" }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              {lawyer.name}
                              <Typography
                                component="span"
                                sx={{
                                  color: "text.secondary",
                                  fontSize: "12px",
                                  ml: 1,
                                }}
                              >
                                {lawyer.designation}
                              </Typography>
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          pl: 3,
                          color: "#757575",
                        }}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                        <Typography sx={{ fontSize: "13px" }}>
                          No counsel information available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {activeTab === 3 &&
                (formattedItems?.length > 0 ? (
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 2,
                      backgroundColor: "#fafafa",
                      borderRadius: "8px",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    {sortedItems?.map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          p: 0.3,
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{ display: "flex", alignItems: "baseline" }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  color: "#303f9f",
                                  fontWeight: 600,
                                  minWidth: "32px",
                                  fontSize: { xs: "12px", md: "13px" },
                                }}
                              >
                                {index + 1}.
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", md: "13px" },
                                  color: "text.primary",
                                  lineHeight: 1.4,
                                }}
                              >
                                {item}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      minHeight: 120,
                      gap: 1.5,
                    }}
                  >
                    <InfoOutlinedIcon sx={{ color: "#757575", fontSize: 24 }} />
                    <Typography
                      sx={{
                        color: "#757575",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      No relevant statutes found
                    </Typography>
                  </Box>
                ))}
              {activeTab === 4 && (
                <>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: 120,
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "#303f9f",
                          animationDuration: "0.8s",
                        }}
                        size={isMobileView ? 16 : 18}
                      />
                    </Box>
                  ) : maxims && Object.keys(maxims)?.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2.5,
                      }}
                    >
                      {/* Maxims Section */}
                      <Box
                        sx={{
                          backgroundColor: "#fafafa",
                          borderRadius: "8px",
                          p: 1.5,
                          borderLeft: "3px solid #303f9f",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#303f9f",
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <DescriptionIcon
                            sx={{ fontSize: 18, color: "#d2042d" }}
                          />{" "}
                          Maxims
                        </Typography>
                        <TabList data={maxims?.Maxims} />
                      </Box>

                      {/* Legal Terms Section */}
                      <Box
                        sx={{
                          backgroundColor: "#fafafa",
                          borderRadius: "8px",
                          p: 1.5,
                          borderLeft: "3px solid #303f9f",
                          mt: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#303f9f",
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <GavelIcon sx={{ fontSize: 18, color: "#d2042d" }} />
                          Legal Terms
                        </Typography>
                        <TabList data={maxims?.Legal_Terms} />
                      </Box>

                      {/* Words and Phrases Section */}
                      <Box
                        sx={{
                          backgroundColor: "#fafafa",
                          borderRadius: "8px",
                          p: 1.5,
                          borderLeft: "3px solid #303f9f",
                          mt: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#303f9f",
                            mb: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <LibraryBooksIcon
                            sx={{ fontSize: 18, color: "#d2042d" }}
                          />{" "}
                          Words and Phrases
                        </Typography>
                        <TabList data={maxims?.Words_and_Phrases} />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        minHeight: 120,
                        gap: 1.5,
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ color: "#757575", fontSize: 24 }}
                      />
                      <Typography
                        sx={{
                          color: "#757575",
                          fontSize: "13px",
                          textAlign: "center",
                        }}
                      >
                        No legal terms or maxims found
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default CaseDetail;
