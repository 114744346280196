import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  Avatar,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  DialogTitle,
  Modal,
  keyframes,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import SendIcon from "@mui/icons-material/Send";
import IosShareIcon from "@mui/icons-material/IosShare";
import modelLogo from "./../assets/images/modelLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import config from "../config/api";
import { toast } from "react-toastify";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import Markdown from "react-markdown";
import { markdownStyles } from "./ChatInterface";
import CaseLawPreviewModal from "./UI/CaseLawPreviewModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";
import { findCaseFromAzureAiSearch } from "../actions/searchActions";
import Feedback from "./Feedback";
import { formatRefLabel, wait } from "../utils/HelperFunction/textFormatting";
import UploadAndPreview from "./UploadAndPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretLeft } from "@fortawesome/free-regular-svg-icons";
import { faSquareCaretRight } from "@fortawesome/free-regular-svg-icons";
import DocumentUploadDA from "./DocumentUploadDA";
import { Drawer } from "@mui/material";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import { styled } from "@mui/system";
import PredefinedMessages from "./PreDefinedMessages";
import SearchIcon from "@mui/icons-material/Search";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const deleteCaseUrl = `${baseUrl}/deleteCaseOrStatuteFromGroup`;
const DocumentAnalysis = ({
  toggleSidebar,
  setChatTitlesList,
  fileUrl,
  docFileUrl,
  setDocFileUrl,
  setFileType,
  setFileUrl,
  file,
  setFile,
  loadingDocx,
  setSelectedChat,
  metaData,
  setMetaData,
  selectedChat,
  previewOpen,
  setPreviewOpen,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const [inputMessage, setInputMessage] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const chatContainerStartRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [fullscreen, setFullscreen] = React.useState(false);
  const chatContainerEndRef = useRef(null);
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [messages, setMessages] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [response, setResponse] = useState("");
  const [feedbackQuery, setFeebackQuery] = useState("");
  const [feedbackRefs, setFeedbackRefs] = useState([]);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [messageIndex, setMessageIndex] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [bookmarkedCaseIds, setBookmarkedCaseIds] = useState(new Set());
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [statuteNote, setStatuteNote] = useState("");
  const [caseNoteError, setCaseNoteError] = useState("");
  const [statuteNoteError, setStatuteNoteError] = useState("");
  const [openStatuteModal, setOpenStatuteModal] = useState(false);
  const [recentChatData, setRecentChatData] = useState({});
  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const [responseAwaiting, setResponseAwaiting] = useState(false);
  const { groups } = bookmarkGroups;

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setCaseNote("");
    setCaseNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleCloseStatuteBookmarkModal = () => {
    setOpenStatuteModal(false);
    setSelectedGroup("");
    setStatuteNote("");
    setStatuteNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setCaseNote(value);
      setCaseNoteError("");
    } else {
      setCaseNoteError("You can only input 2000 characters");
    }
  };

  const handleStatuteNoteChange = (value) => {
    if (value.length <= 2000) {
      setStatuteNote(value);
      setStatuteNoteError("");
    } else {
      setStatuteNoteError("You can only input 2000 characters");
    }
  };

  const handlePreviewClick = (caseItem) => {
    dispatch(findCaseFromAzureAiSearch(caseItem.label, caseItem.court));
    setOpenPreview(true);
  };

  const handleFeedbackGiven = (messageIndex) => {
    setFeedbackGiven({ ...feedbackGiven, [messageIndex]: true });
  };

  const handleCopyClick = async (responseText) => {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(responseText);
      await wait();
      setIsCopied(false);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formatCaseTitle = (title) => {
    if (!title) return;
    const splitTitle = title.split("Honorable")[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const formatCourtName = (courtName) => {
    if (!courtName) return "";

    return courtName
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  const handleExportChat = () => {
    const chatText = messages
      .map((message) => {
        return message.isUser
          ? `User: ${message.text.content}`
          : `Assistant: ${message.text.content}\nReferences:\n${
              message.text.references
                ? message.text.references
                    ?.map((ref) => {
                      return `Label: ${ref?.label}\n Type: ${ref?.type}`;
                    })
                    .join("\n\n")
                : "Not Applicable"
            }`;
      })
      .join("\n");

    const blob = new Blob([chatText], { type: "text/plain" });

    saveAs(blob, `${metaData?.chat_title}.txt`);
  };

  const statuteContentWithStyles = `
  <style>
    .Section1 * {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
    }
     .Section1 b {
      color: #303f9f;
    }
  </style>
  ${modalContent?.content}
`;

  // Define a keyframes animation for a pulsing effect
  const pulse = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

  // Create a styled Typography component with the animation
  const ReasoningText = styled(Typography)(({ theme }) => ({
    animation: `${pulse} 1.5s ease-in-out infinite`,
    fontSize: "1rem",
    fontWeight: "normal",
    textAlign: "center",
    // Apply gradient to the text color
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(45deg, #1976d2, #64b5f6)"
        : "linear-gradient(45deg, #90caf9, #e3f2fd)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }));

  const generateChatbotResponse = async (userMessage, action) => {
    const docId = recentChatData?.doc_id || selectedChat;
    setResponseAwaiting(true);
    if (!docId) {
      console.error("Missing document ID:", { selectedChat, recentChatData });
      toast.error("Please wait for document processing to complete");
      return;
    }
    const apiUrl = `${config.documentAnalysisABaseUrl}/chat`;

    const data = {
      user_id: userInfo._id,
      doc_id: recentChatData?.doc_id ?? selectedChat,
      action: action,
      query: userMessage,
    };

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to response");
      }

      return await response.json();
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
      setResponseAwaiting(false);
    }
  };

  // Handle sending a message
  const handleSendMessage = useCallback(
    async (overrideMessage, overrideAction) => {
      const rawMessage =
        overrideMessage !== undefined
          ? String(overrideMessage)
          : String(inputMessage);
      const action =
        overrideAction !== undefined ? overrideAction : "Document Chat";

      // For "Document Chat", ensure the user message is not empty.
      if (action === "Document Chat" && rawMessage.trim() === "") return;

      // For actions other than "Document Chat", the query must be an empty string.
      const query = action === "Document Chat" ? rawMessage : "";

      if (responseAwaiting) {
        return;
      }

      // Check for sufficient credits
      const creditsToDeduct = 1;
      if (credits <= 0 || credits < creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }

      // Append the user's message
      const newMessages = [
        ...messages,
        { isUser: true, text: { content: rawMessage } },
      ];
      setMessages(newMessages);
      setInputMessage("");

      // Get the chatbot's response from the API
      const chatbotResponse = await generateChatbotResponse(query, action);
      if (chatbotResponse && chatbotResponse.response) {
        let botText = "";
        // When action is "Document Chat", the response is nested.
        if (
          typeof chatbotResponse.response === "object" &&
          action === "Document Chat"
        ) {
          botText = chatbotResponse.response;
        } else {
          botText = chatbotResponse.response;
        }

        const updatedMessages = [
          ...newMessages,
          { isUser: false, text: botText },
        ];
        setMessages(updatedMessages);

        // Deduct credits after sending message
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct, event: "Send Message on Legal Chat" },
          dispatch
        );
        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      }
    },
    [
      credits,
      dispatch,
      inputMessage,
      messages,
      userId,
      selectedChat,
      recentChatData,
    ]
  );
  // Send message on Enter key press
  const handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Handle chip click suggestions
  const handleChipClick = (suggestion) => {
    // Append chip suggestion as a user message
    setMessages((prev) => [
      ...prev,
      { isUser: true, text: { content: suggestion } },
    ]);
    // Optionally trigger sending the message with chip suggestion as both query and action
    handleSendMessage(suggestion, suggestion);
  };

  const handleFileChange = async (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    // Clear previous state
    setFile(null);
    setFileUrl(null);
    setDocFileUrl(null);
    setIsUploading(true);

    try {
      // Check user authentication
      if (!userInfo || !userInfo._id) {
        throw new Error("User not authenticated");
      }

      // Create form-data according to Postman configuration
      const formData = new FormData();

      // 1. Add file parameter
      formData.append("file", uploadedFile, uploadedFile.name);

      // 2. Add body parameter as JSON string
      const bodyData = {
        user_id: userInfo._id,
        doc_id: uuidv4(),
      };
      formData.append("body", JSON.stringify(bodyData));

      // API call
      const response = await fetch(
        `${config.documentAnalysisABaseUrl}/upload`,
        {
          method: "POST",
          body: formData,
          // Headers are automatically set by browser for FormData
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData || "Upload failed");
      }

      // Handle response as text first
      const responseText = await response.json();

      setChatTitlesList((prev) => [
        {
          chat_title: responseText.title,
          doc_id: bodyData.doc_id,
          isPinned: false,
          user_id: userInfo._id,
        },
        ...prev, // Always uses latest state
      ]);
      // Add timeout to ensure state propagation
      await new Promise((resolve) => setTimeout(resolve, 50));
      setSelectedChat(bodyData?.doc_id);
      setRecentChatData(bodyData);
      toast.success("File uploaded successfully");
      // Set file preview
      if (
        uploadedFile.type === "application/pdf" ||
        uploadedFile.type === "text/plain"
      ) {
        setFileUrl(URL.createObjectURL(uploadedFile));
      } else {
        const type = uploadedFile.name.split(".").pop();
        setFileType(type);
        setDocFileUrl(URL.createObjectURL(uploadedFile));
      }
      setFile(uploadedFile);

      // Handle successful upload (e.g., update state, show notification)
      // You might want to store the doc_id in your state here
      return responseText;
    } catch (error) {
      // Reset file input on error
      e.target.value = "";

      // Handle specific error cases
      if (error.message.includes("User not authenticated")) {
        toast.error("Please login to upload documents");
      } else if (error.message.includes("file size")) {
        toast.error("File size exceeds maximum allowed");
      } else {
        toast.error(`Upload failed`);
      }

      // Reset preview states
      setFile(null);
      setFileUrl(null);
      setDocFileUrl(null);
    } finally {
      setIsUploading(false); // Stop loading regardless of success/error
      e.target.value = ""; // Reset file input
    }
  };

  const handleScroll = () => {
    const container = chatContainerStartRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const threshold = 100;
      const shouldShow = scrollTop + clientHeight < scrollHeight - threshold;
      setShowScrollButton(shouldShow);
    }
  };

  const handleBookmarkClick = (caseItem) => {
    setSelectedCase(caseItem);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    //fetchBookmarkGroups();
    setOpenBookmarkModal(true);
  };

  const handleStatuteBookmark = (statute) => {
    setSelectedStatute(statute);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    //fetchBookmarkGroups();
    setOpenStatuteModal(true);
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/createBookmarkGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupName: bookmarkGroupName,
          caseId: selectedCase.id,
          caseNote,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setCaseNote("");
        setIsCreatingNewGroup(false);
        dispatch(fetchBookmarkGroups(userInfo._id));
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const handleCreateStatuteGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/createBookmarkGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupName: bookmarkGroupName,
          statuteId: selectedStatute.id,
          statuteNote,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );
        setOpenStatuteModal(false);
        setBookmarkGroupName("");
        setStatuteNote("");
        dispatch(fetchBookmarkGroups(userInfo._id));
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      console.error("Error creating bookmark group:", error);
    }
  };

  const handleAddCaseToGroup = async () => {
    if (!selectedGroup) return;

    try {
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/addCaseToGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupId: selectedGroup,
          caseId: selectedCase.id,
          caseNote,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setCaseNote("");
      } else {
        throw new Error(data.message || "Failed to add case to group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const handleFeedbackClick = (response, references, index) => {
    const userQuery =
      messages[index - 1]?.text.response || messages[index - 1]?.text;
    setFeebackQuery(userQuery);
    setFeedbackRefs(references);
    setResponse(response);
    setOpenFeedbackDialog(true);
    setMessageIndex(index);
  };

  const findGroupIdByCaseId = (documents, caseId) => {
    // Find the document where the caseId exists in the 'cases' array
    const group = documents.find((doc) =>
      doc.cases.some((caseItem) => caseItem.id === caseId)
    );

    // Return the _id of the group or null if not found
    return group ? group._id : null;
  };

  const handleUnBookmark = async (caseId) => {
    try {
      //fetchBookmarkGroups();
      const groupId = findGroupIdByCaseId(groups, caseId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the case is bookmarked
          caseId, // Case ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark Removed!",
        });
        // Update the state by removing the case ID from bookmarked case IDs
        setBookmarkedCaseIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(caseId);
          return updatedIds;
        });
      } else {
        console.error("Error deleting bookmarked case");
      }
    } catch (error) {
      console.error("Error deleting bookmarked case:", error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/addStatuteToGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupId: selectedGroup,
          statuteId: selectedStatute.id,
          statuteNote, // Include notes when adding to a group
          statuteName: formatRefLabel(selectedStatute.label),
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );

        setOpenStatuteModal(false);
        setStatuteNote(""); // Clear notes after submission
      } else {
        throw new Error(data.message || "Failed to add statute to group.");
      }
    } catch (error) {
      console.error("Error adding statute to group:", error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      //fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(groups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
      } else {
        console.error("Error deleting bookmarked statute");
      }
    } catch (error) {
      console.error("Error deleting bookmarked statute:", error);
    }
  };

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    const greeting = {
      isUser: false,
      text: { content: "Hello! How would you like to analyze the document?" },
    };

    const metaMessages =
      metaData?.messages?.map((msg) => {
        if (msg.role === "user") {
          return { isUser: true, text: msg };
        }
        if (msg.role === "assistant") {
          const text =
            typeof msg.content === "object" ? msg.content.answer || "" : msg;
          return { isUser: false, text };
        }
        return { isUser: false, text: msg || "" };
      }) || [];

    setMessages([greeting, ...metaMessages]);
  }, [metaData, userInfo]);

  useEffect(() => {
    if (chatContainerEndRef.current) {
      chatContainerEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const container = chatContainerStartRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [messages]);

  const handleStatutePreview = (content, label) => {
    setModalContent({
      content,
      label,
    });
    setOpenModal(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 50px)",
          flex: 5,
          bgcolor: "#f5f5f5",
          boxSizing: "border-box",
          p: "12px 12px 0 12px",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            bgcolor: "#fff",
            borderRadius: "8px",
            p: 2,
            position: "relative",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: 1,
              pb: 1,
              borderBottom: 1,
              borderColor: "#ccc",
            }}
          >
            <IconButton
              size="small"
              sx={{ display: { xs: "flex", lg: "none" } }}
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon icon={faBarsStaggered} />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                marginLeft: { xs: "1rem", md: "2rem" },
                fontSize: { xs: "16px", md: "20px" },
              }}
            >
              Document Analysis Chatbot
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {file && messages && messages?.length > 1 && (
                <Tooltip
                  title="Export Chat"
                  placement="bottom"
                  disableInteractive
                  arrow
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#101936",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#101936",
                      },
                    },
                  }}
                >
                  <IconButton
                    onClick={handleExportChat}
                    size="small"
                    sx={{ mb: "5px" }}
                  >
                    <IosShareIcon sx={{ color: "#2196f3" }} />
                  </IconButton>
                </Tooltip>
              )}

              {file && (
                <Tooltip
                  title="Open & Close Document Preview"
                  placement="bottom"
                  disableInteractive
                  arrow
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#101936",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#101936",
                      },
                    },
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setPreviewOpen(!previewOpen);
                    }}
                    size="small"
                    sx={{ color: "#0d47a1" }}
                  >
                    {previewOpen ? (
                      <FontAwesomeIcon
                        color="#2196f3"
                        icon={faSquareCaretRight}
                      />
                    ) : (
                      <FontAwesomeIcon
                        color="#2196f3"
                        icon={faSquareCaretLeft}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          {/* Add this at the end of your Card component */}
          {isUploading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                backdropFilter: "blur(4px)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    px: 2,
                    py: 1.5,
                    // borderRadius: '24px',
                    // bgcolor: 'rgba(0, 61, 120, 0.05)',
                  }}
                >
                  {[0, 1, 2].map((index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        background: (theme) =>
                          theme.palette.mode === "light"
                            ? "linear-gradient(45deg, #1976d2, #64b5f6)"
                            : "linear-gradient(45deg, #90caf9, #e3f2fd)",
                        animation: "bounce 1.4s infinite",
                        animationDelay: `${index * 0.2}s`,
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  "& span": {
                    animation: "bounce 1.5s infinite",
                  },
                }}
              >
                <Box component="span" sx={{ animationDelay: "0.1s" }}>
                  •
                </Box>
                <Box component="span" sx={{ animationDelay: "0.2s" }}>
                  Processing
                </Box>
                <Box component="span" sx={{ animationDelay: "0.3s" }}>
                  your
                </Box>
                <Box component="span" sx={{ animationDelay: "0.4s" }}>
                  document
                </Box>
                <Box component="span" sx={{ animationDelay: "0.5s" }}>
                  •
                </Box>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: "text.secondary",
                  maxWidth: 300,
                  textAlign: "center",
                }}
              >
                This usually takes 10-30 seconds depending on document size
              </Typography>
            </Box>
          ) : !selectedChat ? (
            <Box
              sx={{
                height: "100%",
              }}
            >
              <DocumentUploadDA
                handleFileChange={handleFileChange}
                setFile={setFile}
              />
            </Box>
          ) : (
            <>
              {" "}
              <Paper
                ref={chatContainerStartRef}
                sx={{
                  flexGrow: 1,
                  p: 2,
                  boxShadow: "none",
                  overflowY: "auto",
                  mb: 2,
                }}
              >
                <style>
                  {`
          div::-webkit-scrollbar {
            width: 5px;
          }
          div::-webkit-scrollbar-track {
            background: #fff;
          }
          div::-webkit-scrollbar-thumb {
            background: #bbb;
            border-radius: 6px;
          }
        `}
                </style>

                {loadingDocx || isUploading ? (
                  <Box
                    sx={{
                      height: "30%",
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  messages.length > 0 &&
                  messages.map((message, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: message.isUser
                          ? "flex-end"
                          : "flex-start",
                        boxSizing: "border-box",
                        padding: { xs: "2px", md: "8px" },
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      {!message.isUser && (
                        <Avatar
                          sx={{
                            width: "32px",
                            height: "32px",
                            marginRight: "10px",
                          }}
                          src={modelLogo}
                        />
                      )}
                      <Box
                        sx={{
                          // p: 1,
                          borderRadius: "12px",
                          maxWidth: message.isUser
                            ? { xs: "80%", md: "60%" }
                            : { xs: "90%", md: "85%" },
                          backgroundColor: message.isUser
                            ? "#F1F9FF"
                            : "#f5f5f5",
                          alignSelf: message.isUser ? "flex-end" : "flex-start",
                          marginLeft: message.isUser ? "42px" : "0",
                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* <Typography variant="subtitle2">{message.text}</Typography> */}
                        <Box
                          sx={{
                            display: "flex",
                            alignSelf: message.isUser
                              ? "flex-end"
                              : "flex-start",
                          }}
                        >
                          <Typography
                            variant="body1"
                            textAlign="justify"
                            sx={{
                              wordBreak: "break-word",
                              width: "97%",
                              padding: { xs: "0.5rem", md: "1rem" },
                              color: "#565454",
                            }}
                          >
                            <Markdown
                              components={{
                                ul: ({ ...props }) => (
                                  <ul style={markdownStyles.ul} {...props} />
                                ),
                                ol: ({ ...props }) => (
                                  <ol style={markdownStyles.ol} {...props} />
                                ),
                                li: ({ ...props }) => (
                                  <li style={markdownStyles.li} {...props} />
                                ),
                                p: ({ ...props }) => (
                                  <Typography
                                    style={markdownStyles.p}
                                    {...props}
                                    component="p"
                                  />
                                ),
                                pre: ({ ...props }) => (
                                  <Typography
                                    style={markdownStyles.pre}
                                    {...props}
                                    component="pre"
                                  />
                                ),
                              }}
                            >
                              {message?.text?.content?.replace(
                                /\n\n/gi,
                                "  \n"
                              )}
                            </Markdown>
                            {message.text.references?.filter(
                              (reference) => reference.type === "statute"
                            ).length > 0 && (
                              <Typography
                                component="div"
                                sx={{
                                  fontWeight: "bold",
                                  marginTop: 2,
                                  marginBottom: "8px",
                                  fontSize: "14px",
                                }}
                              >
                                Relevant Statutes:
                              </Typography>
                            )}
                            {message.text?.references
                              ?.filter(
                                (reference) => reference.type === "statute"
                              )
                              .map((ref, refIndex) => (
                                <Box
                                  key={uuidv4()}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    mb: "5px",
                                    alignItems: {
                                      xs: "flex-start",
                                      lg: "center",
                                    },
                                    gap: { xs: "4px", lg: "unset" },
                                  }}
                                >
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    {ref?.id && (
                                      <IconButton
                                        onClick={() =>
                                          handleStatutePreview(
                                            ref.html_content,
                                            ref.label
                                          )
                                        }
                                        size="small"
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          sx={{ color: "#2196f3" }}
                                        />
                                      </IconButton>
                                    )}
                                    {ref?.id && (
                                      <IconButton
                                        sx={{
                                          p: 0,
                                          color: "#d2042d",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                        }}
                                      >
                                        {bookmarkedStatuteIds.has(ref.id) ? (
                                          <>
                                            <Tooltip title="Unbookmark">
                                              <TurnedInIcon
                                                onClick={() =>
                                                  handleUnBookmarkStatute(
                                                    ref.id
                                                  )
                                                }
                                                fontSize="small"
                                              />
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <Tooltip title="Bookmark">
                                            <TurnedInNotIcon
                                              sx={{
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                              }}
                                              onClick={() =>
                                                handleStatuteBookmark(ref)
                                              }
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                        )}
                                      </IconButton>
                                    )}
                                  </Stack>
                                  <Typography
                                    component="div"
                                    sx={{
                                      //fontWeight: 'bold',
                                      display: "flex",
                                      alignItems: {
                                        xs: "flex-start",
                                        lg: "center",
                                      },
                                    }}
                                    variant="subtitle2"
                                  >
                                    {formatRefLabel(ref.label)}
                                  </Typography>
                                </Box>
                              ))}
                            {/* UI for the Case Law References */}
                            {message.text.references?.filter(
                              (reference) =>
                                reference.type !== "case" &&
                                reference.type !== "statute"
                            ).length > 0 && (
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  marginBottom: "6px",
                                  marginTop: 2,
                                  fontSize: "14px",
                                }}
                              >
                                Relevant Others References:
                              </Typography>
                            )}
                            {message.text.references
                              ?.filter(
                                (reference) =>
                                  reference.type !== "case" &&
                                  reference.type !== "statute"
                              )
                              .map((law, index) => (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        lg: "row",
                                      },
                                      marginBottom: "12px",
                                      alignItems: {
                                        xs: "flex-start",
                                        lg: "center",
                                      },
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: {
                                          xs: "flex-start",
                                          lg: "unset",
                                        },
                                      }}
                                    >
                                      {law?.id && (
                                        <IconButton
                                          onClick={() =>
                                            handlePreviewClick(law)
                                          }
                                          sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                        >
                                          <VisibilityIcon
                                            fontSize="small"
                                            sx={{ color: "#2196f3" }}
                                          />
                                        </IconButton>
                                      )}
                                      {law?.id && (
                                        <IconButton
                                          sx={{
                                            p: 0,
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                        >
                                          {bookmarkedCaseIds.has(law.id) ? (
                                            <>
                                              <Tooltip title="Unbookmark">
                                                <TurnedInIcon
                                                  onClick={() =>
                                                    handleUnBookmark(law.id)
                                                  }
                                                  sx={{ color: "#d2042d" }}
                                                  fontSize="small"
                                                />
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              <Tooltip title="Bookmark">
                                                <TurnedInNotIcon
                                                  onClick={() =>
                                                    handleBookmarkClick(law)
                                                  }
                                                  sx={{
                                                    color: "#d2042d",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "transparent",
                                                    },
                                                  }}
                                                  fontSize="small"
                                                />
                                              </Tooltip>
                                            </>
                                          )}
                                        </IconButton>
                                      )}
                                      <Typography variant="subtitle2">
                                        {formatCaseTitle(law?.title)}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          xs: "column",
                                          lg: "row",
                                        },
                                        gap: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: law?.id
                                            ? "bold"
                                            : "normal",
                                        }}
                                        variant="subtitle2"
                                      >
                                        {law?.id
                                          ? `({law.label}) -`
                                          : law?.label}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        fontWeight="bold"
                                      >
                                        {formatCourtName(law.court)}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                  <CaseLawPreviewModal
                                    open={openPreview}
                                    onClose={() => {
                                      setOpenPreview(false);
                                      dispatch({ type: "CASE_DETAIL_RESET" });
                                    }}
                                  />
                                </>
                              ))}
                            {message.text.references?.filter(
                              (reference) => reference.type === "case"
                            ).length > 0 && (
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  marginBottom: "6px",
                                  marginTop: 2,
                                  fontSize: "14px",
                                }}
                              >
                                Relevant Case Laws:
                              </Typography>
                            )}
                            {message.text.references
                              ?.filter((reference) => reference.type === "case")
                              ?.map((law, index) => (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        lg: "row",
                                      },
                                      marginBottom: "12px",
                                      alignItems: {
                                        xs: "flex-start",
                                        lg: "center",
                                      },
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: {
                                          xs: "flex-start",
                                          lg: "unset",
                                        },
                                      }}
                                    >
                                      {law?.id && (
                                        <IconButton
                                          onClick={() =>
                                            handlePreviewClick(law)
                                          }
                                          sx={{ p: 0, ml: 0.8, mr: "4px" }}
                                        >
                                          <VisibilityIcon
                                            fontSize="small"
                                            sx={{ color: "#2196f3" }}
                                          />
                                        </IconButton>
                                      )}
                                      {law?.id && (
                                        <IconButton
                                          sx={{
                                            p: 0,
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                        >
                                          {bookmarkedCaseIds.has(law.id) ? (
                                            <>
                                              <Tooltip title="Unbookmark">
                                                <TurnedInIcon
                                                  onClick={() =>
                                                    handleUnBookmark(law.id)
                                                  }
                                                  sx={{ color: "#d2042d" }}
                                                  fontSize="small"
                                                />
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              <Tooltip title="Bookmark">
                                                <TurnedInNotIcon
                                                  onClick={() =>
                                                    handleBookmarkClick(law)
                                                  }
                                                  sx={{
                                                    color: "#d2042d",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "transparent",
                                                    },
                                                  }}
                                                  fontSize="small"
                                                />
                                              </Tooltip>
                                            </>
                                          )}
                                        </IconButton>
                                      )}
                                      <Typography variant="subtitle2">
                                        {formatCaseTitle(law.title)}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          xs: "column",
                                          lg: "row",
                                        },
                                        gap: 0.4,
                                        ml: 0.8,
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        fontWeight={law?.id ? "bold" : "normal"}
                                      >
                                        {law?.id
                                          ? `(${law.label})`
                                          : law?.label}
                                      </Typography>
                                      <Typography
                                        sx={{ fontWeight: "bold" }}
                                        component="span"
                                        variant="subtitle2"
                                      >
                                        - {formatCourtName(law.court)}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                  <CaseLawPreviewModal
                                    open={openPreview}
                                    onClose={() => {
                                      setOpenPreview(false);
                                      dispatch({ type: "CASE_DETAIL_RESET" });
                                    }}
                                  />
                                </>
                              ))}
                            {!message.isUser && (
                              <Stack
                                flexDirection="row"
                                sx={{ marginTop: "10px" }}
                              >
                                <Tooltip
                                  title={isCopied ? "Copied" : "Copy"}
                                  arrow
                                  PopperProps={{
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: "#101936",
                                      },
                                      "& .MuiTooltip-arrow": {
                                        color: "#101936",
                                      },
                                    },
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      color: isCopied ? "#4caf50" : "#2196f3",
                                    }}
                                    onClick={() =>
                                      handleCopyClick(
                                        message?.text?.content || message?.text
                                      )
                                    }
                                  >
                                    {isCopied ? (
                                      <CheckCircleOutlineIcon fontSize="small" />
                                    ) : (
                                      <ContentCopyIcon fontSize="small" />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Bad Response"
                                  arrow
                                  PopperProps={{
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: "#101936",
                                      },
                                      "& .MuiTooltip-arrow": {
                                        color: "#101936",
                                      },
                                    },
                                  }}
                                >
                                  <IconButton
                                    disabled={feedbackGiven[index]}
                                    sx={{ color: "#d32f2f" }}
                                    onClick={() =>
                                      handleFeedbackClick(
                                        message?.text?.content || message?.text,
                                        message?.text?.references ||
                                          "No reference extract",
                                        index
                                      )
                                    }
                                  >
                                    <ThumbDownOffAltIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))
                )}

                {isLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      p: 1.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: (theme) =>
                          theme.palette.background.default, // optional: add background color
                      }}
                    >
                      <ReasoningText>Analysing</ReasoningText>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.7,
                        px: "4px",
                        mt: 1,
                        // py: 1.5,
                      }}
                    >
                      {[0, 1, 2].map((index) => (
                        <Box
                          key={index}
                          sx={{
                            width: 4,
                            height: 4,
                            borderRadius: "50%",
                            background: (theme) =>
                              theme.palette.mode === "light"
                                ? "linear-gradient(45deg, #1976d2, #64b5f6)"
                                : "linear-gradient(45deg, #90caf9, #e3f2fd)",
                            animation: "bounce 1.4s infinite",
                            animationDelay: `${index * 0.2}s`,
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}

                <div ref={chatContainerEndRef} />
              </Paper>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  width: "90%",
                  mx: "auto",
                }}
              >
                <PredefinedMessages
                  messages={messages}
                  handleChipClick={handleChipClick}
                  loadingDocx={loadingDocx}
                  isUploading={isUploading}
                  responseAwaiting={responseAwaiting}
                />
              </Box>
              {/* Input Field and Send Button */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                  width: "90%",
                  mx: "auto",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Ask question about the document..."
                  value={inputMessage}
                  onKeyDown={handleEnter}
                  onChange={(e) => setInputMessage(e.target.value)}
                  disabled={loadingDocx || isUploading}
                  InputProps={{
                    sx: {
                      p: 0,
                      backgroundColor: "#ffffff",
                      borderRadius: "20px",
                      padding: "0 5px",
                    },
                    startAdornment: (
                      <IconButton sx={{ color: "#303f9f" }}>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    width: "75%",
                    mb: "4px",
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#gray",
                      },
                      "&:hover fieldset": {
                        borderColor: "#00509e",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00509e",
                      },
                    },
                    "& input": {
                      padding: "10px 10px",
                      fontSize: { xs: "12px", lg: "14px" },
                    },
                  }}
                />
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => {
                    handleSendMessage();
                  }}
                  sx={{
                    backgroundColor:
                      loadingDocx || isUploading || responseAwaiting
                        ? "#9fa8da"
                        : "#303f9f",
                    "&:hover": {
                      backgroundColor:
                        loadingDocx || isUploading || responseAwaiting
                          ? "#9fa8da"
                          : "#1a237e",
                    },
                    transition: "all 0.2s ease",
                  }}
                >
                  <SendIcon
                    fontSize="small"
                    sx={{
                      color:
                        loadingDocx || isUploading || responseAwaiting
                          ? "#e0e0e0"
                          : "#fff",
                      transition: "color 0.2s ease",
                    }}
                  />
                </IconButton>
              </Box>{" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "10px", lg: "12px" },
                    color: "#555",
                  }}
                >
                  LawGPT provides relevant information but is not a replacement
                  for legal advice.
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {isMobileView ? (
          <Drawer
            anchor="right"
            open={previewOpen}
            ModalProps={{ keepMounted: true }}
            onClose={() => {
              setPreviewOpen(false);
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: "70vw", // Adjust for mobile width
                maxWidth: "400px",
                backgroundColor: "#fff",
                padding: "16px",
              },
            }}
          >
            <UploadAndPreview
              closeSidebar={() => {
                setPreviewOpen(false);
              }}
              isUploading={isUploading}
              loadingDocx={loadingDocx}
              file={file}
              setFile={setFile}
              setFileUrl={setFileUrl}
              handleFileChange={handleFileChange}
              setDocFileUrl={setDocFileUrl}
              fileUrl={fileUrl}
              docFileUrl={docFileUrl}
              setMessages={setMessages}
              setMetaData={setMetaData}
              setSelectedChat={setSelectedChat}
            />
          </Drawer>
        ) : (
          previewOpen && (
            <UploadAndPreview
              toggleSidebar={toggleSidebar}
              isUploading={isUploading}
              loadingDocx={loadingDocx}
              file={file}
              setFile={setFile}
              setFileUrl={setFileUrl}
              handleFileChange={handleFileChange}
              setDocFileUrl={setDocFileUrl}
              fileUrl={fileUrl}
              docFileUrl={docFileUrl}
              setMessages={setMessages}
              setMetaData={setMetaData}
              setSelectedChat={setSelectedChat}
            />
          )
        )}
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        fullScreen={fullscreen}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              color: "#303f9f",
              fontWeight: "bold",
            }}
          >
            {formatRefLabel(modalContent?.label)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Full Screen">
              <IconButton onClick={handleFullscreenToggle}>
                {fullscreen ? (
                  <FullscreenExitIcon sx={{ color: "#36454f" }} />
                ) : (
                  <FullscreenIcon sx={{ color: "#36454f" }} />
                )}
              </IconButton>
            </Tooltip>
            <IconButton edge="end" color="inherit" onClick={handleCloseModal}>
              <CancelIcon color="error" />
            </IconButton>
          </Box>
        </Stack>
        <DialogContent dividers>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
              "& p": {
                margin: 0,
              },
              "& h1": {
                fontFamily: "Roboto, Arial, sans-serif",
                margin: "16px 0",
              },
              "& i": {
                fontStyle: "italic",
              },
              "& a": {
                color: "#303f9f",
                textDecoration: "none",
              },
            }}
            component="div"
            dangerouslySetInnerHTML={{ __html: statuteContentWithStyles }}
          />
        </DialogContent>
      </Dialog>

      <Feedback
        open={openFeedbackDialog}
        modelResponse={response}
        feedbackQuery={feedbackQuery}
        references={feedbackRefs}
        handleClose={() => {
          setOpenFeedbackDialog(false);
        }}
        messageIndex={messageIndex}
        feedbackGiven={handleFeedbackGiven}
      />
      <Modal
        open={openBookmarkModal}
        onClose={handleCloseBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Box
          sx={{
            width: 400,
            margin: "auto",
            mt: "10%",
            p: 2,
            borderRadius: 2,
            bgcolor: "white",
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#303f9f",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Bookmark Case
            </Typography>
            <IconButton
              sx={{
                padding: 0,
                color: "gray",
                "&:hover": { color: "#d32f2f" },
              }}
              onClick={handleCloseBookmarkModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Dropdown for selecting an existing group or creating a new group */}
          <TextField
            label="Select or Create Group"
            variant="outlined"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              setSelectedGroup(e.target.value);
              setIsCreatingNewGroup(e.target.value === "new");
            }}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              value="new"
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  key={group._id}
                  value={group._id}
                  selected={group.groupName === "Bookmarks"}
                  sx={{
                    fontSize: "13px",
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Input field for creating a new group, only visible if 'Create New Group' is selected */}
          {isCreatingNewGroup && (
            <TextField
              fullWidth
              size="small"
              label="New Group Name"
              variant="outlined"
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "13px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}

          {/* Text input for adding a note */}
          <TextField
            label="Add a Note"
            fullWidth
            sx={{ mb: 2 }}
            value={caseNote}
            onChange={(e) => handleNoteChange(e.target.value)}
            multiline
            rows={3}
            error={Boolean(caseNoteError)}
            helperText={caseNoteError}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCloseBookmarkModal}
              color="error"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#d32f2f",
                textTransform: "none",
                fontSize: "14px",
                "&:hover": { backgroundColor: "#b71c1c" },
                transition: "background-color 0.3s ease-in-out",
              }}
            >
              Cancel
            </Button>
            {/* Conditional rendering for buttons based on selection */}
            {isCreatingNewGroup ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleCreateBookmarkGroup();
                  setOpenBookmarkModal(false);
                }}
                disabled={!bookmarkGroupName}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#003D78",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: "primary.main" },
                  transition: "background-color 0.3s ease-in-out",
                }}
              >
                Add
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedGroup || caseNoteError}
                onClick={() => {
                  handleAddCaseToGroup();
                  setOpenBookmarkModal(false);
                }}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#003D78",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: "primary.main" },
                  transition: "background-color 0.3s ease-in-out",
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={openStatuteModal}
        onClose={handleCloseStatuteBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiPaper-root": {
            width: "400px",
            borderRadius: 2,
            padding: 2,
            boxShadow: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            color: "#303f9f",
            fontSize: "18px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Bookmark Statute
          <IconButton
            color="error"
            onClick={handleCloseStatuteBookmarkModal}
            sx={{
              padding: 0,
              color: "gray",
              "&:hover": { color: "#d32f2f" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            overflow: "visible",
            padding: 0,
            marginTop: 1,
          }}
        >
          <TextField
            sx={{
              mt: 1,
              mb: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            label="Add to existing group"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "create-new") {
                setBookmarkGroupName("");
              }
              setSelectedGroup(selectedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
              value="create-new"
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  key={group._id}
                  value={group._id}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === "create-new" && (
            <TextField
              label="New Group Name"
              fullWidth
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: "white",
                "& .MuiInputBase-root": {
                  fontSize: "13px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}

          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            onChange={(e) => handleStatuteNoteChange(e.target.value)}
            sx={{
              mt: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            mt: 1,
            justifyContent: "flex-end",
            padding: "8px 0px 8px 8px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleCloseStatuteBookmarkModal}
            color="error"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#d32f2f",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "#b71c1c" },
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#003D78",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "primary.main" },
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={
              selectedGroup === "create-new"
                ? handleCreateStatuteGroup
                : handleAddStatuteToGroup
            }
            disabled={
              selectedGroup === "create-new"
                ? !bookmarkGroupName
                : !selectedGroup || statuteNoteError
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentAnalysis;
