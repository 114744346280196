import React, { useEffect, useState } from "react";
import {
  Collapse,
  Tabs,
  Tab,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  styled,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { fetchMaxims } from "../../actions/searchActions";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  maxWidth: "40%",
  borderRadius: "8px 8px 0 0",
  borderBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiTabs-indicator": {
    backgroundColor: "#303f9f",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "13px",
  color: "#303f9f",
  "&.Mui-selected": {
    fontWeight: "bold",
    color: "#303f9f",
  },
}));

const TabList = ({ data, label }) => {
  const hasData = data && Object.keys(data).length > 0;
  return (
    <Box
      sx={{
        maxHeight: "16rem",
        overflowY: "auto",
        scrollBehavior: "smooth",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      {hasData ? (
        <List>
          {Object.entries(data).map(([key, value], index) => (
            <ListItem key={index}>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                primary={`${index + 1}- ${key}`}
                secondary={
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "11px",
                        md: "12px",
                      },
                      flexGrow: 1,
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#303f9f" }}>
                      Description:
                    </span>{" "}
                    {value}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "bold",
            color: "#303f9f",
            textAlign: "left",
            marginTop: "1rem",
          }}
        >
          {`No ${label} available.`}
        </Typography>
      )}
    </Box>
  );
};

const Maxims = ({ isOpen, id }) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const hanldeFetchMaxims = async () => {
      setLoading(true);
      try {
        const data = await fetchMaxims(id);
        setListings(data);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Failed to fetch maxims:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    hanldeFetchMaxims();
  }, [id]);

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <CircularProgress
            sx={{ color: "#303f9f" }}
            size={isMobileView ? 16 : 18}
          />
        </Box>
      ) : listings && Object.keys(listings)?.length > 0 ? (
        <Box sx={{ borderRadius: "8px" }}>
          {/* Tabs */}
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            variant="fullWidth"
          >
            <StyledTab label="Maxims" />
            <StyledTab label="Legal Terms" />
            <StyledTab label="Words & Phrases" />
          </StyledTabs>

          {/* Tab Panels */}
          <TabPanel value={value} index={0}>
            <TabList data={listings?.Maxims} label="Maxims" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabList data={listings?.Legal_Terms} label="Legal Terms" />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabList
              data={listings?.Words_and_Phrases}
              label="Words and Phrases"
            />
          </TabPanel>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "8px",
            color: "#303f9f",
          }}
        >
          <Typography fontSize="13px" fontWeight="bold">
            No Relevant Maxims & Terms Found.
          </Typography>
        </Box>
      )}
    </Collapse>
  );
};

export default Maxims;
