import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import ListItemButton from "@mui/material/ListItemButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteDialog from "../UI/DeleteDialog";
import {
  deleteApplication,
  editApplicationTitle,
  getApplicationTitles,
} from "../../actions/applicationActions";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({
  isCollapsed = false,
  toggleSidebar,
  setIsHistoryClicked,
  setApplicationToEdit,
  setGeneratedApplication,
  setReadOnly,
}) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const hasInitiated = useRef(false);

  const dispatch = useDispatch();
  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const applicationTitles = useSelector((state) => state.applicationTitles);
  const { loading, titles } = applicationTitles;

  const [selectedChat, setSelectedChat] = useState({ chatId: "", title: "" });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [isEditing, setIsEditing] = useState({ chatId: "" });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const filteredData = titles && titles?.filter((item) => item.Title !== null);

  const groupedData =
    filteredData && Object.groupBy(filteredData, (title) => title?.Category);

  const open = Boolean(anchorEl);
  const inputRef = useRef(null);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleNewChatClick = () => {
    setSelectedChat({ chatId: "", title: "" });
    setExpanded(null);
    setIsHistoryClicked(false);
    setApplicationToEdit({});
    setReadOnly(false);
    dispatch({ type: "NEW_APPLICATION_REQUEST" });
    dispatch({ type: "SELECTED_APPLICATION_RESET" });
    isMobileView && toggleSidebar();
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewTitle(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleSaveEdit = (chatId) => {
    if (!newTitle) return;
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }

    dispatch(editApplicationTitle(userInfo._id, chatId, newTitle));

    // Reset editing state after saving
    setIsEditing({ chatId: "" });
    setNewTitle("");

    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Application title edit on application drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCancelEdit = (index) => {
    setIsEditing({ chatId: "" });
    setNewTitle("");
  };

  const handleListItemClick = (application) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }
    setIsEditing({ chatId: "" });
    setSelectedChat({ chatId: application.chat_id, title: application.Title });
    setAnchorEl(null);
    setReadOnly(true);
    setGeneratedApplication("");
    dispatch({ type: "SET_SELECTED_APPLICATION", payload: application });
    isMobileView && toggleSidebar();
    setIsHistoryClicked(false);
    setApplicationToEdit({});

    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Application preview on application drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditTitle = (event, chatId) => {
    event.stopPropagation();
    setIsEditing({ chatId });
    const findChat = titles?.find((title) => title.chat_id === chatId);
    setNewTitle(findChat.Title);
  };

  const handleConfirmDelete = (event, chatId) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteApplication(userInfo?._id, chatId));
    setSelectedChat({ chatId: "", title: "" });
    dispatch({ type: "SELECTED_APPLICATION_RESET" });

    // Close the delete dialog
    setOpenDeleteDialog(false);

    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Delete application on application drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleDeleteChat = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);

    setOpenDeleteDialog(true);
  };

  const handleAccordionToggle = (category) => {
    setExpanded((prev) => {
      if (prev === category) {
        setSelectedChat({ chatId: "", title: "" });
        dispatch({ type: "SELECTED_APPLICATION_RESET" });
        return null;
      } else {
        return category;
      }
    });
  };

  useEffect(() => {
    dispatch(getApplicationTitles(userInfo?._id));
    return () => {
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
    };
  }, []);

  useEffect(() => {
    if (!hasInitiated.current && titles && titles.length > 0) {
      hasInitiated.current = true;
      const firstCategory = Object.keys(groupedData)[0];
      setExpanded(firstCategory);

      // Get and sort the applications by timecreated
      const categoryItems = groupedData[firstCategory];
      if (categoryItems?.length > 0) {
        const sortedItems = [...categoryItems]
          .filter((x) => x.Title !== null)
          .sort((a, b) => new Date(b.timecreated) - new Date(a.timecreated));

        // Select the last added application
        const lastApplication = sortedItems[0];
        if (lastApplication) {
          setSelectedChat({
            chatId: lastApplication.chat_id,
            title: lastApplication.Title,
          });
          dispatch({
            type: "SET_SELECTED_APPLICATION",
            payload: lastApplication,
          });
        }
      }
    }
  }, [dispatch, titles, groupedData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        ...(isMobileView
          ? { width: "278px" }
          : {
              flexBasis: isCollapsed ? "60px" : "17%",
              flexShrink: 0,
              maxWidth: isCollapsed ? "60px" : "17%",
              transition: "flex-basis 0.3s ease",
            }),

        height: "calc(100vh - 50px)",
        p: "12px 0px 0 12px",
        bgcolor: "#F5F5F5",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: isCollapsed ? 0.5 : 1,
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        {!isMobileView && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <IconButton
              onClick={toggleSidebar}
              sx={{
                width: "40px",
                height: "40px",
              }}
            >
              {isCollapsed ? (
                <MenuIcon />
              ) : (
                <ChevronLeftIcon
                  color="#0000008a"
                  sx={{ color: "#0000008a", fontSize: "25px" }}
                />
              )}
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {!isCollapsed && (
                <Tooltip title="New Application" placement="bottom">
                  <Button
                    sx={{
                      minWidth: "40px",
                      width: "40px",
                      padding: 0,
                    }}
                    disabled={titles?.length === 0}
                    onClick={handleNewChatClick}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      fontSize={"18px"}
                      color={titles?.length === 0 ? "#ccc" : "#0000008a"}
                    />
                  </Button>
                </Tooltip>
              )}
              {!isCollapsed && (
                <Tooltip title="View History" placement="bottom">
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsHistoryClicked(true);
                      setSelectedChat({ chatId: "", title: "" });
                      setExpanded(null);
                      isMobileView && toggleSidebar();
                    }}
                  >
                    <ManageSearchIcon
                      color="#0000008a"
                      sx={{ color: "#0000008a", fontSize: "25px" }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
        {isMobileView && (
          <>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#003D78",
                display: "flex",
                justifyContent: "center",
                marginBottom: "8px",
                mr: 0.5,
                marginTop: "8px",
                textTransform: "none",
                "&:hover": { backgroundColor: "primary.main", color: "#fff" },
                transition: "background-color 0.3s ease-in-out",
              }}
              onClick={handleNewChatClick}
            >
              New Application
            </Button>
            <Box
              sx={{ textAlign: "right", paddingY: "8px" }}
              onClick={() => {
                setIsHistoryClicked(true);
                setSelectedChat({ chatId: "", title: "" });
                setExpanded(null);
                isMobileView && toggleSidebar();
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  mr: 0.5,
                  color: "#3C50B1",
                  "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
              >
                View History
              </Typography>
            </Box>
          </>
        )}
        {isCollapsed && (
          <Tooltip title="New Application" placement="right">
            <Button
              // variant="contained"
              sx={{
                // backgroundColor: "#003D78",
                minWidth: "40px",
                width: "40px",
                height: "40px",
                padding: 0,
                marginBottom: "8px",
                marginTop: "8px",
                borderRadius: "8px",
                // "&:hover": { backgroundColor: "primary.main" },
                // transition: "background-color 0.1s ease-in-out",
              }}
              onClick={handleNewChatClick}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                fontSize={"17px"}
                color="#0000008a"
              />
            </Button>
          </Tooltip>
        )}

        {isCollapsed && (
          <Tooltip title="View History" placement="right">
            <Box
              sx={{
                textAlign: "center",
                paddingY: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsHistoryClicked(true);
                setSelectedChat({ chatId: "", title: "" });
                setExpanded(null);
                isMobileView && toggleSidebar();
              }}
            >
              <ManageSearchIcon color="#0000008a" sx={{ color: "#0000008a" }} />
            </Box>
          </Tooltip>
        )}
        {loading ? (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : titles?.length > 0 && !isCollapsed ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            {Object.entries(groupedData)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([category, items]) => (
                <Accordion
                  key={category}
                  expanded={expanded === category}
                  onChange={() => handleAccordionToggle(category)}
                  sx={{
                    margin: "4px 0",
                    borderRadius: "8px",
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                    "&:before": { display: "none" },
                    "&.Mui-expanded": {
                      margin: "4px 0px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                    aria-controls={`${category}-content`}
                    id={`${category}-header`}
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                      padding: "8px 16px",
                      minHeight: "40px",
                      "&.Mui-expanded": {
                        minHeight: "40px",
                        padding: "8px 16px",
                      },
                      ".MuiAccordionSummary-content": {
                        margin: "0px",
                        "&.Mui-expanded": {
                          margin: "0px",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textAlign: "left",
                      }}
                    >
                      {category} ({items?.length})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "4px 0px 4px 0px",
                      backgroundColor: "#fafafa",
                      maxHeight: "200px",
                      overflowY: "auto",
                      "::-webkit-scrollbar": {
                        width: "5px",
                      },
                      "::-webkit-scrollbar-track": {
                        background: "#fafafa",
                      },
                      "::-webkit-scrollbar-thumb": {
                        background: "#bbb",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    {items?.map((item, index) => (
                      <Box key={item.chat_id}>
                        <ListItem disablePadding>
                          <ListItemButton
                            sx={{
                              width: "100%",
                              mx: 1,
                              marginY: "4px",
                              borderRadius: "8px",
                              height: "38px",
                              padding: "8px 12px",
                              "&.Mui-selected": {
                                backgroundColor: "#ECECEC",
                                "&:hover": {
                                  backgroundColor: "#ECECEC",
                                },
                              },
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            }}
                            selected={selectedChat.chatId === item.chat_id}
                            onClick={() => handleListItemClick(item)}
                          >
                            {isEditing.chatId === item.chat_id ? (
                              <Stack direction="row">
                                <input
                                  ref={inputRef}
                                  style={{
                                    width: "100%",
                                    marginLeft: "0",
                                    fontSize: "13px",
                                  }}
                                  type="text"
                                  autoFocus
                                  value={newTitle}
                                  onClick={(e) => e.stopPropagation()}
                                  onFocus={(e) => e.stopPropagation()}
                                  onChange={handleInputChange}
                                />
                                <IconButton
                                  edge="end"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSaveEdit(item.chat_id);
                                    setAnchorEl(null);
                                  }}
                                >
                                  <DoneIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  edge="end"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCancelEdit();
                                    setAnchorEl(null);
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Stack>
                            ) : (
                              <Stack
                                direction="row"
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    fontSize: "13px",
                                  }}
                                >
                                  {item.Title}
                                </Typography>
                                {selectedChat.chatId === item.chat_id && (
                                  <>
                                    <IconButton
                                      size="small"
                                      id="basic-button"
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={handleMenuOpen}
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      id="basic-menu"
                                      open={open}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        sx={{ fontSize: "14px" }}
                                        onClick={(e) =>
                                          handleEditTitle(e, item.chat_id)
                                        }
                                      >
                                        <EditIcon
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        sx={{
                                          fontSize: "14px",
                                          color: "#d32f2f",
                                        }}
                                        onClick={(e) => handleDeleteChat(e)}
                                      >
                                        <DeleteIcon
                                          color="error"
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </>
                                )}
                              </Stack>
                            )}
                          </ListItemButton>
                        </ListItem>
                        {/* Divider */}
                        {index !== items.length - 1 && (
                          <Divider
                            sx={{
                              mx: 1.5,
                              backgroundColor: "#E0E0E0",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              m: "auto",
            }}
          >
            {!isCollapsed && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                textAlign="center"
              >
                Get started with your legal drafting.
                <br />
                Create a new application today! 🚀
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleConfirmDelete}
        selectedChat={selectedChat}
      />
    </Box>
  );
};

export default Sidebar;
