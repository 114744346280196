import { Drawer, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import StatuteSearchSidebar from "../../components/layout/StatuteSearchSidebar";
import StatuteSearch from "../../components/StatuteSearch";

export const StatuteSearchParent = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  
  // State management
  const [selectedStatuteLetterFilter, setSelectedStatuteLetterFilter] = useState("");
  const [selectedStatuteYear, setSelectedStatuteYear] = useState("");
  const [statuteResultsCount, setStatuteResultsCount] = useState(10);
  const [statuteCurrentPage, setStatuteCurrentPage] = useState(1);
  const [statuteSearchMode, setStatuteSearchMode] = useState("Title");
  const [statuteSearchQuery, setStatuteSearchQuery] = useState("");
  const [selectedStatuteTypeFilter, setSelectedStatuteTypeFilter] = useState("");
  const [selectedStatuteProvinceFilter, setSelectedStatuteProvinceFilter] = useState("");

  // Cleanup effect
  useEffect(() => () => {
    setSelectedStatuteLetterFilter("");
    setSelectedStatuteProvinceFilter("");
    setSelectedStatuteTypeFilter("");
  }, []);

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  // Common props for sidebar
  const commonSidebarProps = {
    selectedLetter: selectedStatuteLetterFilter,
    selectedYear: selectedStatuteYear,
    resultsCount: statuteResultsCount,
    currentPage: statuteCurrentPage,
    searchMode: statuteSearchMode,
    statuteSearchValue: statuteSearchQuery,
    selectedStatuteTypeFilter: selectedStatuteTypeFilter,
    selectedStatuteProvinceFilter: selectedStatuteProvinceFilter,
    updateSelectedLetter: setSelectedStatuteLetterFilter,
    updateSelectedYear: setSelectedStatuteYear,
    updateCurrentPage: setStatuteCurrentPage,
    setSelectedStatuteTypeFilter: setSelectedStatuteTypeFilter,
    setSelectedStatuteProvinceFilter: setSelectedStatuteProvinceFilter,
  };

  // Common props for StatuteSearch
  const commonSearchProps = {
    selectedYear: selectedStatuteYear,
    selectedLetter: selectedStatuteLetterFilter,
    searchMode: statuteSearchMode,
    resultsCount: statuteResultsCount,
    currentPage: statuteCurrentPage,
    statuteSearchValue: statuteSearchQuery,
    selectedStatuteTypeFilter: selectedStatuteTypeFilter,
    selectedStatuteProvinceFilter: selectedStatuteProvinceFilter,
    updateSelectedYear: setSelectedStatuteYear,
    updateSelectedLetter: setSelectedStatuteLetterFilter,
    updateSearchMode: setStatuteSearchMode,
    updateResultsCount: setStatuteResultsCount,
    updateCurrentPage: setStatuteCurrentPage,
    updateStatuteSearchValue: setStatuteSearchQuery,
    setSelectedStatuteTypeFilter: setSelectedStatuteTypeFilter,
    setSelectedStatuteProvinceFilter: setSelectedStatuteProvinceFilter,
  };

  return (
    <Stack direction="row" height="calc(100vh - 50px)" width="100%">
      {isMobileView ? (
        <Drawer anchor="left" open={isSidebarVisible} onClose={toggleSidebar}>
          <StatuteSearchSidebar {...commonSidebarProps} 
            isSidebarVisible={isSidebarVisible}
            toggleSidebar={toggleSidebar}
          />
        </Drawer>
      ) : (
        <StatuteSearchSidebar {...commonSidebarProps} />
      )}

      <StatuteSearch
        {...commonSearchProps}
        toggleSidebar={toggleSidebar}
      />
    </Stack>
  );
};