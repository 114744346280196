import React from "react";
import { Box, Pagination } from "@mui/material";

const CenteredPagination = ({
  totalResults,
  resultsCount,
  currentPage,
  onPageChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Pagination
        count={Math.ceil(totalResults / resultsCount)}
        page={currentPage}
        onChange={onPageChange}
        sx={{
          m: "5px 0",
          "& .MuiPaginationItem-root": {
            fontSize: "13px",
            minWidth: "24px",
            height: "24px",
            "&:hover": {
              color: "#fff",
              backgroundColor: "#303f9f",
            },
            "&.Mui-selected": {
              color: "#fff",
              backgroundColor: "#213165",
              "&:hover": {
                backgroundColor: "#303f9f",
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default CenteredPagination;
