import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const StatutePreviewModal = ({ open, onClose, statute }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const formatTitle = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  const statuteContentWithStyles = `<style>
    .MsoNormal {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      line-height: 1.6;
      font-size: 14px
    }
     .MsoNormal b {
      font-size: 14px;
      color: #303f9f;
    }
  </style>
  ${statute.Statute_HTML}`;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: fullscreen ? 0 : "50%",
          left: fullscreen ? 0 : "50%",
          transform: fullscreen ? "none" : "translate(-50%, -50%)",
          width: fullscreen ? "100vw" : "40%",
          height: fullscreen ? "100vh" : "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            mr: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              color: "#303f9f",
              fontWeight: "bold",
            }}
          >
            {formatTitle(
              `${statute?.Statute_Name}, Section:${statute?.Section}`
            )}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleFullscreenToggle}>
              {fullscreen ? (
                <FullscreenExitIcon sx={{ color: "#303f9f" }} />
              ) : (
                <FullscreenIcon sx={{ color: "#303f9f" }} />
              )}
            </IconButton>
            <IconButton onClick={onClose}>
              <CancelIcon color="error" />
            </IconButton>
          </Box>
        </Box>
        <Typography
          sx={{
            padding: 2,
            overflowY: "auto",
            maxHeight: fullscreen ? "calc(100vh - 60px)" : "60vh", // Adjusted for fullscreen
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
          dangerouslySetInnerHTML={{ __html: statuteContentWithStyles }}
        />
      </Box>
    </Modal>
  );
};

export default StatutePreviewModal;
