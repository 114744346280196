import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stack,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import Facet from "../UI/Facet";
import { applyFilters, searchResults } from "../../actions/searchActions";
import MaximsFilters from "../CaseSearch/MaximsFilters";
import LegalTermsFilters from "../CaseSearch/LegalTermsFilters";
import WordsAndPhrasesFilters from "../CaseSearch/WordsAndPhrasesFilters";

const transformFacets = (facets) => {
  const transformed = [];

  if (facets.Section) {
    transformed.push({
      category: "Journal",
      options: facets.Section.map((section) => section.value),
    });
  }

  if (facets.Court) {
    const uniqueCourt = new Map();

    facets.Court.forEach((Court) => {
      const originalValue = Court.value && Court.value.trim();

      if (originalValue && originalValue.length > 0) {
        let formattedValue = originalValue
          .replace(/^[.,&]\s*/g, "")
          .replace(/\b\(\w+\)\b/g, " ")
          .trim()
          .replace(/\b\w+(-\w+)*\b/g, (word) =>
            word
              .split("-")
              .map(
                (part) =>
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
              )
              .join(" ")
          )
          .replace(/\s{2,}/g, " ")
          .trim();

        if (
          formattedValue &&
          formattedValue.length > 4 &&
          !/^\d/.test(formattedValue) &&
          formattedValue !== "--"
        ) {
          uniqueCourt.set(formattedValue, originalValue);
        }
      }
    });

    transformed.push({
      category: "Court",
      options: Array.from(uniqueCourt.entries()).map(
        ([formatted, original]) => ({
          formatted,
          original,
        })
      ),
    });
  }
  if (facets.Year) {
    transformed.push({
      category: "Year",
      options: facets.Year.map((year) => year.value),
    });
  }

  transformed.push({
    category: "Page",
    options: [],
  });

  if (facets.Judge_Name) {
    const uniqueJudges = [];

    facets.Judge_Name.forEach((judge) => {
      const originalValue = judge.value && judge.value.trim();

      if (originalValue && originalValue.length > 0) {
        let formattedValue = originalValue
          .replace(/\b(Honourable Justice|Honorable Justice|Justice)\b\s*/i, "")
          .replace(/^[.,&]\s*/g, "")
          .replace(/\b\(\w+\)\b/g, " ")
          .trim()
          .replace(/\b\w+(-\w+)*\b/g, (word) =>
            word
              .split("-")
              .map(
                (part) =>
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
              )
              .join("-")
          )
          .replace(/\s{2,}/g, " ")
          .trim();

        if (
          formattedValue &&
          formattedValue.length > 4 &&
          !/^\d/.test(formattedValue) &&
          formattedValue !== "--"
        ) {
          uniqueJudges.push({ formattedValue, originalValue });
        }
      }
    });

    transformed.push({
      category: "Judge",
      options: uniqueJudges.map(({ formattedValue, originalValue }) => ({
        formatted: formattedValue,
        original: originalValue,
      })),
    });
  }

  if (facets.Lawyer_Name) {
    const uniqueLawyers = [];

    facets.Lawyer_Name.forEach((lawyer) => {
      const originalValue = lawyer.value && lawyer.value.trim();

      if (originalValue && originalValue.length > 0) {
        let formattedValue = originalValue
          .replace(/\b(Honourable Justice|Honorable Justice|Justice)\b\s*/i, "")
          .replace(/^[.,&]\s*/g, "")
          .replace(/\b\(\w+\)\b/g, " ")
          .trim()
          .replace(/\b\w+(-\w+)*\b/g, (word) =>
            word
              .split("-")
              .map(
                (part) =>
                  part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
              )
              .join("-")
          )
          .replace(/\s{2,}/g, " ")
          .trim();

        if (
          formattedValue &&
          formattedValue.length > 4 &&
          !/^\d/.test(formattedValue) &&
          formattedValue !== "--"
        ) {
          uniqueLawyers.push({ formattedValue, originalValue });
        }
      }
    });

    transformed.push({
      category: "Lawyer",
      options: uniqueLawyers.map(({ formattedValue, originalValue }) => ({
        formatted: formattedValue,
        original: originalValue,
      })),
    });
  }

  return transformed;
};

const LegalSearchSidebar = ({
  toggleSidebar,
  searchKeyword,
  sideBarState,
  currentPage,
  setCurrentPage,
  isNewCaseLawSearch,
  setIsNewCaseLawSearch,
  searchMode,
  sortBy,
  resultsCount,
  activeTab,
}) => {
  const dispatch = useDispatch();

  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const searchResultsState = useSelector((state) => state.searchResults);
  const { results } = searchResultsState;

  const searchSelectedFilters = useSelector((state) => state.selectedFilters);
  const { selectedFilters } = searchSelectedFilters;

  const [facetValues, setFacetValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showChips, setShowChips] = useState({});
  const [selectedFilterMode, setSelectedFilterMode] = useState(
    selectedFilters?.selectedFilterMode || "standard"
  );

  const handleApplyCitationFilter = () => {
    const updatedFilter = {
      Citation_Name: `${selectedValues["Year"]} ${selectedValues["Journal"]} ${selectedValues["Page"]}`,
    };
    dispatch(
      applyFilters(
        searchKeyword,
        updatedFilter,
        (currentPage - 1) * resultsCount,
        resultsCount,
        searchMode,
        sortBy
      )
    );
    setIsFilterApplied(true);
    toggleSidebar();
  };

  const handleClearFilters = () => {
    if (selectedValues && Object.keys(selectedValues).length === 0) return;
    setSelectedValues({ Journal: undefined });
    if (isFilterApplied) {
      dispatch({ type: "SEARCH_SELECTED_FILTERS_RESET" });
      dispatch(searchResults(searchKeyword));
      setEndYear(null);
      setStartYear(null);
      setIsFilterApplied(false);
      setShowChips({});
    }
  };

  const handleChange = (category, value) => {
    const updatedSelectedValues = {
      ...selectedValues,
      [category]: value,
    };

    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [category]: value,
    }));

    const updatedShowChips = {
      ...showChips,
      [category]: value ? true : false,
    };

    setShowChips((prevState) => ({
      ...prevState,
      [category]: value ? true : false,
    }));

    if (selectedFilterMode === "standard") {
      setIsFilterApplied(true);
    }

    const updatedFilters = {
      ...updatedSelectedValues,
      Judge: updatedSelectedValues?.["Judge"]?.original,
      Journal: updatedSelectedValues?.["Journal"],
      Court: updatedSelectedValues?.["Court"]?.original,
      Lawyer: updatedSelectedValues?.["Lawyer"]?.original,
    };
    setCurrentPage(1);
    if (category === "Year" && selectedFilterMode === "standard") {
      if (value?.startYear && value?.endYear) {
        // Call applyFilters immediately when Year is valid
        dispatch(
          applyFilters(
            searchKeyword,
            updatedFilters,
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      } else if (!value?.startYear && !value?.endYear) {
        // Remove Year filter and call applyFilters
        const { Year, ...filtersWithoutYear } = updatedFilters;
        dispatch(
          applyFilters(
            searchKeyword,
            filtersWithoutYear, // Exclude Year
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      }
    } else {
      if (selectedFilterMode === "standard") {
        dispatch(
          applyFilters(
            searchKeyword,
            updatedFilters,
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      }
    }
    dispatch({
      type: "SEARCH_SELECTED_FILTERS_SUCCESS",
      payload: { updatedSelectedValues, updatedShowChips, selectedFilterMode },
    });
  };

  const handleChangeFilterMode = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSelectedFilterMode(newAlignment);
      handleClearFilters();
    }
  };

  const handleClearChip = (category) => {
    const updatedSelectedValues = {
      ...selectedValues,
      [category]: "",
    };
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [category]: "",
    }));

    const updatedShowChips = {
      ...showChips,
      [category]: false,
    };

    setShowChips((prevState) => ({
      ...prevState,
      [category]: false,
    }));

    dispatch({
      type: "SEARCH_SELECTED_FILTERS_SUCCESS",
      payload: { updatedSelectedValues, updatedShowChips, selectedFilterMode },
    });
  };

  useEffect(() => {
    if (results?.["search_results"]?.facets) {
      const transformedFacets = transformFacets(
        results?.["search_results"]?.facets
      );
      setFacetValues(transformedFacets);
    }
  }, [results, searchResultsState]);

  useEffect(() => {
    if (isFilterApplied && !isMobileView) {
      const updatedFilters = {
        ...selectedValues,
        Judge: selectedValues?.["Judge"]?.original,
        Journal: selectedValues?.["Journal"],
        Court: selectedValues?.["Court"]?.original,
        Lawyer: selectedValues?.["Lawyer"]?.original,
      };
      dispatch(
        applyFilters(
          searchKeyword,
          updatedFilters,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    }
  }, [currentPage, resultsCount, sortBy]);

  useEffect(() => {
    if (searchKeyword === "" || isNewCaseLawSearch) {
      setSelectedValues({});
      setIsFilterApplied(false);
      setShowChips(false);
      setIsNewCaseLawSearch(false);
    }
  }, [searchKeyword, isNewCaseLawSearch, setIsNewCaseLawSearch]);

  useEffect(() => {
    if (selectedFilters) {
      setIsFilterApplied(true);
      setShowChips(selectedFilters.updatedShowChips);
      const updatedFilters = {
        ...selectedFilters.updatedSelectedValues,
        Judge: selectedFilters?.updatedSelectedValues?.["Judge"]?.original,
        Journal: selectedFilters?.updatedSelectedValues?.["Journal"],
        Court: selectedFilters?.updatedSelectedValues?.["Court"]?.original,
        Lawyer: selectedFilters?.updatedSelectedValues?.["Lawyer"]?.original,
      };
      setSelectedValues(selectedFilters.updatedSelectedValues);
      setStartYear(selectedFilters.updatedSelectedValues?.Year?.startYear);
      setEndYear(selectedFilters.updatedSelectedValues?.Year?.endYear);
      if (isMobileView && !sideBarState) {
        dispatch(
          applyFilters(
            searchKeyword,
            updatedFilters,
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      } else if (!isMobileView) {
        dispatch(
          applyFilters(
            searchKeyword,
            updatedFilters,
            (currentPage - 1) * resultsCount,
            resultsCount,
            searchMode,
            sortBy
          )
        );
      }
    }
    return () => {
      if (!isMobileView) {
        dispatch({ type: "SEARCH_SELECTED_FILTERS_RESET" });
      }
      setSelectedValues({});
      setStartYear(null);
      setEndYear(null);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        backgroundColor: "#f5f5f5",
        flex: 1,
        width: { xs: "278px", lg: "20%" },
        height: "calc(100vh - 50px)",
        p: "12px 8px 0 12px",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: "8px 5px 8px 8px",
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        {activeTab === 0 ? (
          <>
            <ToggleButtonGroup
              exclusive
              size="small"
              value={selectedFilterMode}
              onChange={handleChangeFilterMode}
              sx={{
                width: "95%",
                display: "flex",
                borderRadius: "8px",
                marginLeft: "5px",
                marginTop: "4px",
                marginBottom:
                  selectedValues &&
                  selectedFilterMode === "standard" &&
                  Object.keys(selectedValues)?.length > 0 &&
                  Object.values(selectedValues)?.some((value) => value)
                    ? "4px"
                    : "18px",
              }}
            >
              <ToggleButton
                size="small"
                value="standard"
                sx={{
                  width: "50%",
                  textTransform: "none",
                  padding: "8px 24px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  backgroundColor:
                    selectedFilterMode === "standard"
                      ? "#00509e"
                      : "transparent",
                  color: selectedFilterMode === "standard" ? "#fff" : "inherit",
                  fontWeight:
                    selectedFilterMode === "standard" ? "bold" : "normal",
                  "&:hover": {
                    backgroundColor:
                      selectedFilterMode === "standard" ? "#00509e" : "#e3f2fd",
                  },
                }}
              >
                Filters
              </ToggleButton>
              <ToggleButton
                size="small"
                value="citation"
                sx={{
                  width: "50%",
                  textTransform: "none",
                  padding: "8px 24px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  backgroundColor:
                    selectedFilterMode === "citation"
                      ? "#00509e"
                      : "transparent",
                  color: selectedFilterMode === "citation" ? "#fff" : "inherit",
                  fontWeight:
                    selectedFilterMode === "citation" ? "bold" : "normal",
                  "&:hover": {
                    backgroundColor:
                      selectedFilterMode === "citation" ? "#00509e" : "#e3f2fd",
                  },
                }}
              >
                Citation
              </ToggleButton>
            </ToggleButtonGroup>
            {selectedValues &&
              isFilterApplied &&
              Object.keys(selectedValues)?.length > 0 &&
              Object.values(selectedValues)?.some((value) => value) && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="small"
                    color="error"
                    sx={{ textTransform: "none" }}
                    onClick={handleClearFilters}
                  >
                    Clear filter(s)
                  </Button>
                </Box>
              )}

            <Facet
              startYear={startYear}
              endYear={endYear}
              setStartYear={setStartYear}
              setEndYear={setEndYear}
              facetsData={facetValues}
              handleChange={handleChange}
              selectedValues={selectedValues}
              showChips={showChips}
              handleClearChip={handleClearChip}
              filterMode={selectedFilterMode}
            />
            {selectedFilterMode === "standard" && (
              <Stack
                sx={{
                  display: { xs: "display", lg: "none" },
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "6px",
                  mt: "16px",
                  mb: "8px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<FilterAltIcon />}
                  disabled={Object.keys(selectedValues)?.length === 0}
                  sx={{
                    mb: "8px",
                    textTransform: "none",
                    backgroundColor: "#00509e",
                    "&:hover": {
                      backgroundColor: "#003366",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#336ba3",
                      color: "#fff",
                    },
                  }}
                  onClick={toggleSidebar}
                >
                  Apply
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<CancelIcon />}
                  sx={{
                    mb: "8px",
                    textTransform: "none",
                    "&.Mui-disabled": {
                      backgroundColor: "#d65f5f",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleSidebar}
                >
                  Close
                </Button>
              </Stack>
            )}

            {selectedFilterMode === "citation" && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "6px",
                    mt: "16px",
                    mb: "8px",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography
                        sx={{
                          maxWidth: "130px",
                          whiteSpace: "normal",
                          textAlign: "center",
                          fontSize: "11px",
                        }}
                      >
                        Please fill all above filters to enable the apply button
                      </Typography>
                    }
                    placement="top"
                    arrow
                    disableHoverListener={
                      !(
                        selectedValues &&
                        (Object.keys(selectedValues)?.length !== 3 ||
                          Object.values(selectedValues)?.some(
                            (value) => value === null || value === ""
                          ))
                      )
                    }
                    disableFocusListener={
                      !(
                        selectedValues &&
                        (Object.keys(selectedValues)?.length !== 3 ||
                          Object.values(selectedValues)?.some(
                            (value) => value === null || value === ""
                          ))
                      )
                    }
                  >
                    <span
                      style={{
                        cursor:
                          selectedValues &&
                          (Object.keys(selectedValues)?.length !== 3 ||
                            Object.values(selectedValues)?.some(
                              (value) => value === null || value === ""
                            ))
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<FilterAltIcon />}
                        disabled={
                          selectedValues &&
                          (Object.keys(selectedValues)?.length !== 3 ||
                            Object.values(selectedValues)?.some(
                              (value) => value === null || value === ""
                            ))
                        }
                        sx={{
                          mb: "8px",
                          textTransform: "none",
                          backgroundColor: "#00509e",
                          "&:hover": {
                            backgroundColor: "#003366",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#A0A0A0",
                            color: "#fff",
                            cursor: "not-allowed",
                          },
                        }}
                        onClick={handleApplyCitationFilter}
                      >
                        Apply
                      </Button>
                    </span>
                  </Tooltip>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    disabled={
                      selectedValues &&
                      (Object.keys(selectedValues)?.length === 0 ||
                        Object.values(selectedValues)?.every((value) => !value))
                    }
                    startIcon={<CancelIcon />}
                    sx={{
                      mb: "8px",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        backgroundColor: "#d65f5f",
                        color: "#ffffff",
                      },
                    }}
                    onClick={handleClearFilters}
                  >
                    Clear
                  </Button>
                </Stack>
              </>
            )}
          </>
        ) : activeTab === 1 ? (
          <MaximsFilters />
        ) : activeTab === 2 ? (
          <LegalTermsFilters />
        ) : (
          <WordsAndPhrasesFilters />
        )}
      </Box>
    </Box>
  );
};

export default LegalSearchSidebar;
