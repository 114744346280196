import { Typography } from "@mui/material";
import { Box } from "@mui/system";

// List of prepositions to keep lowercase
const lowercase = [
  "a",
  "an",
  "the",
  "to",
  "of",
  "in",
  "on",
  "at",
  "by",
  "for",
  "and",
  "but",
  "or",
  "nor",
];

const isRomanNumeral = (word) => {
  const romanRegex =
    /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/i;
  return romanRegex.test(word);
};

export const formatRefLabel = (input) => {
  const formattedParts = input?.split(",").map((part) =>
    part
      .trim()
      .split(" ")
      .map((word, index) => {
        const lowerCaseWord = word.toLowerCase();
        if (
          index !== 0 &&
          lowercase.includes(lowerCaseWord) &&
          !isRomanNumeral(word)
        ) {
          return lowerCaseWord;
        }
        return isRomanNumeral(word)
          ? word.toUpperCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ")
  );

  const uniqueParts = [...new Set(formattedParts)];

  return uniqueParts.join(", ");
};

export const wait = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const processNode = (node) => {
  if (node.nodeType === Node.TEXT_NODE) {
    node.textContent = toTitleCase(node.textContent);
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    node.childNodes.forEach(processNode);
  }
};

export const convertHtmlToTitleCase = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  doc.body.childNodes.forEach(processNode);
  return doc.body.innerHTML;
};

export const formatTitle = (title) => {
  if (!title) return;
  const titleCaseTitle = title
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  return titleCaseTitle;
};

export const renderMarkdown = (markdown) => {
  const lines = markdown.split("\n");

  return lines.map((line, index) => {
    // Handle headings
    if (line.startsWith("### ")) {
      return (
        <Typography variant="h6" key={index}>
          {line.replace("### ", "")}
        </Typography>
      );
    }
    if (line.startsWith("## ")) {
      return (
        <Typography variant="h5" key={index}>
          {line.replace("## ", "")}
        </Typography>
      );
    }
    if (line.startsWith("# ")) {
      return (
        <Typography variant="h4" key={index}>
          {line.replace("# ", "")}
        </Typography>
      );
    }

    // Handle numbered lists (e.g., 1., 2., 3.)
    const numberedListMatch = line.match(/^(\d+)\. /);
    if (numberedListMatch) {
      const cleanLine = line.replace(/^\d+\. /, ""); // Remove leading `1.`
      const parts = cleanLine.split(/\*\*(.+?)\*\*/); // Split bold text

      return (
        <Box key={index}>
          <Typography variant="body1" component="div">
            {numberedListMatch[1]}.{" "}
            {parts.map((part, i) =>
              i % 2 === 1 ? (
                <Typography component="span" fontWeight="bold" key={i}>
                  {part}
                </Typography>
              ) : (
                part
              )
            )}
          </Typography>
        </Box>
      );
    }

    // Handle bullet points within numbered lists
    if (line.startsWith("- ")) {
      const cleanLine = line.replace("- ", "• "); // Replace `-` with bullet
      const parts = cleanLine.split(/\*\*(.+?)\*\*/); // Split bold text

      return (
        <Box key={index} sx={{ paddingLeft: "20px" }}>
          {" "}
          {/* Indented for bullets */}
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "justify" }}
          >
            {parts.map((part, i) =>
              i % 2 === 1 ? (
                <Typography component="span" fontWeight="bold" key={i}>
                  {part}
                </Typography>
              ) : (
                part
              )
            )}
          </Typography>
        </Box>
      );
    }

    // Handle bold text for non-list items
    if (/\*\*(.+?)\*\*/.test(line)) {
      const parts = line.split(/\*\*(.+?)\*\*/);
      return (
        <Typography variant="body1" paragraph key={index}>
          {parts.map((part, i) =>
            i % 2 === 1 ? (
              <Typography component="span" fontWeight="bold" key={i}>
                {part}
              </Typography>
            ) : (
              part
            )
          )}
        </Typography>
      );
    }

    // Handle regular paragraphs
    return (
      <Typography variant="body1" marginBottom="8px" key={index}>
        {line}
      </Typography>
    );
  });
};

export const textHighlight = (text, highlight) => {
  if (!highlight.trim()) {
    return text;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ backgroundColor: "yellow" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const formatCourtName = (courtName) => {
  if (!courtName) return "";

  return courtName
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatJudgeName = (names) => {
  if (!names || names.length === 0) return "";

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    )
    .join(", ");

  return titleCaseNames;
};

export const getContentAfterHeadings = (html) => {
  // Parse the HTML string into a DOM structure
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Define the target keywords to search for
  const targetKeywords = ["ORDER", "JUDGEMENT", "JUDGMENT", "Judgment"];

  // Find all elements that could contain the target keywords
  const allElements = Array.from(doc.querySelectorAll("h1, h2, p, b"));

  // Look for elements whose trimmed text content exactly matches any of the target keywords
  const targetElement = allElements.find((el) =>
    targetKeywords.includes(el.textContent.trim())
  );

  // Initialize an array to store the content after the target heading
  let contentAfterHeadings = [];

  if (targetElement) {
    // Start with the next sibling node of the target element
    let currentNode = targetElement.nextSibling;

    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        // If the node is a text node, add its content
        contentAfterHeadings.push(currentNode.textContent.trim());
      } else if (currentNode.nodeName === "BR") {
        // If the node is a <br> tag, treat it as a line break
        contentAfterHeadings.push("\n");
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        // If it's an element node, add its outer HTML
        contentAfterHeadings.push(currentNode.outerHTML);
      }
      // Move to the next sibling node
      currentNode = currentNode.nextSibling;
    }
  }

  // Join the collected content into a single string
  const finalContent = contentAfterHeadings.join("").trim();

  return finalContent;
};
