import React from "react";
import {
  Box,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const ResultsHeader = ({
  totalResults,
  resultsCount,
  handleResultsCountChange,
  isMobileView,
  label,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgb(255, 255, 255)",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        mt: "8px",
        p: "16px 16px 8px 16px",
      }}
    >
      <Typography
        sx={{
          ml: 0.5,
          fontWeight: "bold",
          fontSize: { xs: "14px", lg: "18px" },
        }}
      >
        <span style={{ color: "#d2042d", fontWeight: "bold" }}>
          {totalResults?.toLocaleString()}{" "}
        </span>
        {label}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InputLabel
          sx={{
            marginRight: "4px",
            fontSize: "13px",
            color: "#555",
            fontWeight: "500",
          }}
          htmlFor="resultsCount"
        >
          Show:
        </InputLabel>
        <FormControl
          variant="outlined"
          sx={{
            width: isMobileView ? 90 : 100,
            "& .MuiOutlinedInput-root": {
              borderRadius: "6px",
              height: "32px",
              padding: "8px",
            },
            "& fieldset": {
              borderColor: "#ddd",
              borderWidth: "1px",
            },
            "& .MuiSelect-icon": { right: "4px" },
            "& .MuiSelect-select": {
              padding: "0px",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Select
            id="resultsCount"
            value={resultsCount}
            onChange={handleResultsCountChange}
            sx={{
              fontSize: { xs: "12px", md: "13px" },
              height: "32px",
              bgcolor: "#fff",
            }}
          >
            <MenuItem value={10} sx={{ fontSize: { xs: "12px", md: "13px" } }}>
              10 results
            </MenuItem>
            <MenuItem value={20} sx={{ fontSize: { xs: "12px", md: "13px" } }}>
              20 results
            </MenuItem>
            <MenuItem value={50} sx={{ fontSize: { xs: "12px", md: "13px" } }}>
              50 results
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ResultsHeader;
