import caseSearchTypes from "./types";

const initialListingState = {
  data: [],
  loading: false,
  error: null,
};

const initialState = {
  maxims: { ...initialListingState },
  legalTerms: { ...initialListingState },
  wordsAndPhrases: { ...initialListingState },
};

export const listingFiltersReducer = (state = {}, action) => {
  const { listingType } = action;
  switch (action.type) {
    case caseSearchTypes.SET_LISTING_FILTERS:
      return {
        ...state,
        [listingType]: { letter: action.payload },
      };

    case caseSearchTypes.RESET_LISTING_FILTERS:
      return {
        ...state,
        [listingType]: { letter: "" },
      };

    default:
      return state;
  }
};

export const listingSearchTermsReducer = (state = {}, action) => {
  const { listingType } = action;
  switch (action.type) {
    case caseSearchTypes.UPDATE_LISTING_SEARCH_TERM:
      return {
        ...state,
        [listingType]: {
          searchTerm: action.payload,
        },
      };
    default:
      return state;
  }
};

export const listingsReducer = (state = initialState, action) => {
  const { listingType } = action;
  switch (action.type) {
    case caseSearchTypes.FETCH_LISTING_REQUEST:
      return {
        ...state,
        [listingType]: {
          ...state[listingType],
          loading: true,
        },
      };
    case caseSearchTypes.FETCH_LISTING_SUCCESS:
      return {
        ...state,
        [listingType]: {
          ...state[listingType],
          data: action.payload,
          loading: false,
        },
      };
    case caseSearchTypes.FETCH_LISTING_FAILURE:
      return {
        ...state,
        [listingType]: {
          ...state[listingType],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
