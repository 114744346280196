import axios from "axios";
import caseSearchTypes from "./types";
import config from "../../config/api";

export const setListingFilters = (listingType, filter) => async (dispatch) => {
  dispatch({
    type: caseSearchTypes.SET_LISTING_FILTERS,
    payload: filter,
    listingType,
  });
};

export const resetListingFilters = (listingType) => async (dispatch) => {
  dispatch({ type: caseSearchTypes.RESET_LISTING_FILTERS, listingType });
};

export const updateListingSearchTerm =
  (listingType, searchTerm) => async (dispatch) => {
    dispatch({
      type: caseSearchTypes.UPDATE_LISTING_SEARCH_TERM,
      payload: searchTerm,
      listingType,
    });
  };

let fetchListingAbortController = null;

export const fetchListing =
  (listingType, currentPage, resultsCount, searchTerm, letter, category) =>
  async (dispatch) => {
    let filterString = "";
    if (letter) {
      const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
      filterString = `type eq '${category}' and (title ge '${letter}' and title lt '${nextLetter}')`;
    } else {
      filterString = `type eq '${category}'`;
    }
    dispatch({ type: caseSearchTypes.FETCH_LISTING_REQUEST, listingType });
    const payload = {
      skip: (currentPage - 1) * resultsCount,
      top: resultsCount,
      search: searchTerm,
      filter: filterString,
    };
    try {
      // Abort any ongoing request
      if (fetchListingAbortController) {
        fetchListingAbortController.abort();
      }

      // Create a new AbortController instance
      fetchListingAbortController = new AbortController();
      const { signal } = fetchListingAbortController;

      const response = await axios.post(config.legalTermUrl, payload, {
        signal,
      });
      dispatch({
        type: caseSearchTypes.FETCH_LISTING_SUCCESS,
        payload: response.data,
        listingType,
      });
    } catch (error) {
      dispatch({
        type: caseSearchTypes.FETCH_LISTING_FAILURE,
        payload: error.message,
        listingType,
      });
      console.log("Error fetching maxims: ", error.message);
    }
  };
