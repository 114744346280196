import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Paper,
  Grow,
  Fade,
} from "@mui/material";
import { keyframes, useMediaQuery } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import GavelIcon from "@mui/icons-material/Gavel";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

// Define keyframes for animations
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PredefinedMessages = ({
  messages,
  handleChipClick,
  loadingDocx,
  isUploading,
  responseAwaiting,
}) => {
  // State for each dropdown menu
  const [documentMenuAnchor, setDocumentMenuAnchor] = useState(null);
  const [referencesMenuAnchor, setReferencesMenuAnchor] = useState(null);
  const [argumentsMenuAnchor, setArgumentsMenuAnchor] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // Animation states
  const [activeButton, setActiveButton] = useState(null);

  // Check if Extract Arguments exists in messages
  const hasExtractArguments = messages.some(
    (message) => message.text.content === "Extract Arguments"
  );

  // Handle opening and closing menus
  const handleMenuOpen = (event, setAnchorFn, buttonId) => {
    if (loadingDocx || isUploading || responseAwaiting) return;
    setAnchorFn(event.currentTarget);
    setActiveButton(buttonId);
  };

  const handleMenuClose = (setAnchorFn) => {
    setAnchorFn(null);
    setTimeout(() => setActiveButton(null), 300); // Delay to allow animation to complete
  };

  // Handle menu item click
  const handleMenuItemClick = (text, setAnchorFn) => {
    handleChipClick(text);
    setAnchorFn(null);

    // Add a small delay to allow the animation to complete
    setTimeout(() => setActiveButton(null), 300);
  };

  // Disabled state
  const isDisabled = loadingDocx || isUploading || responseAwaiting;

  // Common menu props to ensure menus open below buttons
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    TransitionComponent: Grow,
    TransitionProps: { timeout: 200 },
    PaperProps: {
      elevation: 3,
      sx: {
        borderRadius: "8px",
        mt: 1,
        minWidth: isMobile ? "100%" : "180px",
        maxWidth: isMobile ? "100vw" : "none",
        transform: isMobile ? "translateX(-5px)" : "none",
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: 1, md: 2 },
        flexWrap: "wrap",
        animation: `${fadeIn} 0.5s ease-out`,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {/* Document Dropdown */}
      <Paper
        elevation={0}
        sx={{
          width: { xs: "100%", md: "auto" },
          overflow: "hidden",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: isDisabled ? "#e0e0e0" : "#e3f2fd",
          transition: "all 0.3s ease",
          transform: activeButton === "document" ? "scale(1.03)" : "scale(1)",
          animation:
            activeButton === "document" ? `${pulse} 1.5s infinite` : "none",
          "&:hover": {
            transform: isDisabled ? "scale(1)" : "scale(1.03)",
            boxShadow: isDisabled ? "none" : "0 4px 8px rgba(0,0,0,0.1)",
          },
          zIndex: activeButton === "document" ? 10 : 1,
        }}
      >
        <Button
          sx={{
            bgcolor: isDisabled ? "#e0e0e0" : "#e3f2fd",
            color: isDisabled ? "#757575" : "#1976d2",
            "&:hover": {
              bgcolor: isDisabled ? "#e0e0e0" : "#bbdefb",
            },
            textTransform: "none",
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            width: "100%",
            justifyContent: "space-between",
            transition: "all 0.3s ease",
          }}
          onClick={(e) => handleMenuOpen(e, setDocumentMenuAnchor, "document")}
          disabled={isDisabled}
          startIcon={
            <DescriptionIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform:
                  activeButton === "document" ? "scale(1.2)" : "scale(1)",
              }}
            />
          }
          endIcon={
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform: Boolean(documentMenuAnchor)
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          }
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: "12px", md: "13px" },

              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -2,
                left: 0,
                width: activeButton === "document" ? "100%" : "0%",
                height: "2px",
                bgcolor: "#1976d2",
                transition: "width 0.3s ease",
              },
            }}
          >
            Document
          </Typography>
        </Button>
        <Menu
          anchorEl={documentMenuAnchor}
          open={Boolean(documentMenuAnchor)}
          onClose={() => handleMenuClose(setDocumentMenuAnchor)}
          {...menuProps}
        >
          {["Summarize Text", "Improve Text"].map((text, index) => (
            <Fade
              key={text}
              in={Boolean(documentMenuAnchor)}
              timeout={300 + index * 100}
            >
              <MenuItem
                onClick={() => handleMenuItemClick(text, setDocumentMenuAnchor)}
                sx={{
                  py: { xs: 1, md: 1.5 },
                  px: { xs: 1.5, md: 2 },
                  transition: "all 0.2s ease",

                  "&:hover": {
                    bgcolor: "#e3f2fd",
                    paddingLeft: { xs: "12px", md: "16px" },
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: { xs: "12px", lg: "13px" } }}
                  variant="body2"
                >
                  {text}
                </Typography>
              </MenuItem>
            </Fade>
          ))}
        </Menu>
      </Paper>

      {/* References Dropdown */}
      <Paper
        elevation={0}
        sx={{
          width: { xs: "100%", md: "auto" },
          overflow: "hidden",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: isDisabled ? "#e0e0e0" : "#e3f2fd",
          transition: "all 0.3s ease",
          transform: activeButton === "references" ? "scale(1.03)" : "scale(1)",
          animation:
            activeButton === "references" ? `${pulse} 1.5s infinite` : "none",
          "&:hover": {
            transform: isDisabled ? "scale(1)" : "scale(1.03)",
            boxShadow: isDisabled ? "none" : "0 4px 8px rgba(0,0,0,0.1)",
          },
          zIndex: activeButton === "references" ? 10 : 1, // Ensure active button stays on top
        }}
      >
        <Button
          onClick={(e) =>
            handleMenuOpen(e, setReferencesMenuAnchor, "references")
          }
          disabled={isDisabled}
          startIcon={
            <GavelIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform:
                  activeButton === "references" ? "scale(1.2)" : "scale(1)",
              }}
            />
          }
          endIcon={
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform: Boolean(referencesMenuAnchor)
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          }
          sx={{
            bgcolor: isDisabled ? "#e0e0e0" : "#e3f2fd",
            color: isDisabled ? "#757575" : "#1976d2",
            "&:hover": {
              bgcolor: isDisabled ? "#e0e0e0" : "#bbdefb",
            },
            textTransform: "none",
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            width: "100%",
            justifyContent: "space-between",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "relative",
              fontSize: { xs: "12px", md: "13px" },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -2,
                left: 0,
                width: activeButton === "references" ? "100%" : "0%",
                height: "2px",
                bgcolor: "#1976d2",
                transition: "width 0.3s ease",
              },
            }}
          >
            References
          </Typography>
        </Button>
        <Menu
          anchorEl={referencesMenuAnchor}
          open={Boolean(referencesMenuAnchor)}
          onClose={() => handleMenuClose(setReferencesMenuAnchor)}
          {...menuProps}
        >
          {["Similar Laws and Cases", "Extract References"].map(
            (text, index) => (
              <Fade
                key={text}
                in={Boolean(referencesMenuAnchor)}
                timeout={300 + index * 100}
              >
                <MenuItem
                  onClick={() =>
                    handleMenuItemClick(text, setReferencesMenuAnchor)
                  }
                  sx={{
                    py: { xs: 1, md: 1.5 },
                    px: { xs: 1.5, md: 2 },
                    transition: "all 0.2s ease",
                    "&:hover": {
                      bgcolor: "#e3f2fd",
                      paddingLeft: { xs: "12px", md: "16px" },
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: "12px", lg: "13px" } }}
                    variant="body2"
                  >
                    {text}
                  </Typography>
                </MenuItem>
              </Fade>
            )
          )}
        </Menu>
      </Paper>

      {/* Arguments Dropdown */}
      <Paper
        elevation={0}
        sx={{
          width: { xs: "100%", md: "auto" },
          overflow: "hidden",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: isDisabled ? "#e0e0e0" : "#e3f2fd",
          transition: "all 0.3s ease",
          transform: activeButton === "arguments" ? "scale(1.03)" : "scale(1)",
          animation:
            activeButton === "arguments" ? `${pulse} 1.5s infinite` : "none",
          "&:hover": {
            transform: isDisabled ? "scale(1)" : "scale(1.03)",
            boxShadow: isDisabled ? "none" : "0 4px 8px rgba(0,0,0,0.1)",
          },
          zIndex: activeButton === "arguments" ? 10 : 1, // Ensure active button stays on top
        }}
      >
        <Button
          onClick={(e) =>
            handleMenuOpen(e, setArgumentsMenuAnchor, "arguments")
          }
          disabled={isDisabled}
          startIcon={
            <FormatListBulletedIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform:
                  activeButton === "arguments" ? "scale(1.2)" : "scale(1)",
              }}
            />
          }
          endIcon={
            <ExpandMoreIcon
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                transition: "transform 0.3s ease",
                transform: Boolean(argumentsMenuAnchor)
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          }
          sx={{
            bgcolor: isDisabled ? "#e0e0e0" : "#e3f2fd",
            color: isDisabled ? "#757575" : "#1976d2",
            "&:hover": {
              bgcolor: isDisabled ? "#e0e0e0" : "#bbdefb",
            },
            textTransform: "none",
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            width: "100%",
            justifyContent: "space-between",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: "12px", md: "13px" },
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -2,
                left: 0,
                width: activeButton === "arguments" ? "100%" : "0%",
                height: "2px",
                bgcolor: "#1976d2",
                transition: "width 0.3s ease",
              },
            }}
          >
            Arguments
          </Typography>
        </Button>
        <Menu
          anchorEl={argumentsMenuAnchor}
          open={Boolean(argumentsMenuAnchor)}
          onClose={() => handleMenuClose(setArgumentsMenuAnchor)}
          {...menuProps}
        >
          <Fade in={Boolean(argumentsMenuAnchor)} timeout={300}>
            <MenuItem
              onClick={() =>
                handleMenuItemClick("Extract Arguments", setArgumentsMenuAnchor)
              }
              sx={{
                transition: "all 0.2s ease",
                "&:hover": {
                  bgcolor: "#e3f2fd",
                  paddingLeft: "16px",
                },
              }}
            >
              <Typography
                sx={{ fontSize: { xs: "12px", lg: "13px" } }}
                variant="body2"
              >
                Extract Arguments
              </Typography>
            </MenuItem>
          </Fade>

          {hasExtractArguments && (
            <>
              <Divider sx={{ transition: "all 0.3s ease" }} />
              {["Enhance Arguments", "Generate Counter Arguments"].map(
                (text, index) => (
                  <Fade
                    key={text}
                    in={Boolean(argumentsMenuAnchor)}
                    timeout={400 + index * 100}
                  >
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick(text, setArgumentsMenuAnchor)
                      }
                      sx={{
                        py: { xs: 1, md: 1.5 },
                        px: { xs: 1.5, md: 2 },
                        transition: "all 0.2s ease",
                        "&:hover": {
                          bgcolor: "#e3f2fd",
                          paddingLeft: { xs: "12px", md: "16px" },
                        },
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { xs: "12px", lg: "13px" } }}
                        variant="body2"
                      >
                        {text}
                      </Typography>
                    </MenuItem>
                  </Fade>
                )
              )}
            </>
          )}
        </Menu>
      </Paper>
    </Box>
  );
};

export default PredefinedMessages;
