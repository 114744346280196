import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Select,
  ListItemText,
  Collapse,
  useMediaQuery,
  Stack,
  Chip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import CloseIcon from "@mui/icons-material/Close";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { KeyboardVoice } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  searchSections,
  statuteSearchResults,
} from "../actions/statuteSearchActions";
import InfoDialog from "./UI/InfoDialog";
import StatutePdfViewer from "./StatutePdfViewer";
import StatuteDetailDialog from "./StatuteDetailDialog";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";
import { getHighlightedSentences } from "../utils/HelperFunction/getHighlightedSentences";
import SuggestionsDropdown from "./UI/SuggestionsDropdown";
import { debounce } from "lodash";
import CollapsibleList from "./UI/CollapsibleList";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const deleteCaseUrl = `${baseUrl}/deleteCaseOrStatuteFromGroup`;

const StatuteSearch = ({
  toggleSidebar,
  selectedLetter,
  selectedYear,
  searchMode,
  updateSearchMode,
  resultsCount,
  updateResultsCount,
  currentPage,
  updateCurrentPage,
  statuteSearchValue,
  updateStatuteSearchValue,
  selectedStatuteProvinceFilter,
  setSelectedStatuteProvinceFilter,
  selectedStatuteTypeFilter,
  setSelectedStatuteTypeFilter,
}) => {
  const backendApiUrl = process.env.REACT_APP_API_BASE_URL;
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [statuteNote, setStatuteNote] = useState("");
  const [statuteNoteError, setStatuteNoteError] = useState("");
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [placeholder, setPlaceholder] = useState("Search by title...");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [isStatuteSearch, setIsStatuteSearch] = useState(false);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [openSectionsDialog, setOpenSectionsDialog] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const [isAddBookmarkLoading, setIsAddBookmarkLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [openRelevantCases, setOpenRelevantCases] = useState({});

  const isInitialSearchDone = useRef(false);
  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef("");
  const itemRefs = useRef([]);
  const latestQueryRef = useRef("");
  const paperRef = useRef(null);

  const dispatch = useDispatch();

  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const statuteSections = useSelector((state) => state.statuteSections);
  const { loading, sections } = statuteSections;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const statuteSearch = useSelector((state) => state.statuteSearch);
  const { loading: statuteLoading, results } = statuteSearch;

  const isStatuteFilterApplied = useSelector(
    (state) => state.isStatuteFilterApplied
  );

  const totalResults = results?.count || 0;

  // const handleClickInfoDialog = () => {
  //   setOpenInfoDialog(true);
  //   setIsStatuteSearch(true);
  // };

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSuggestions([]);
        setIsDropdownOpen(false);
        return;
      }
      latestQueryRef.current = query;
      setLoadingSuggestions(true);
      try {
        const response = await fetch(
          `${backendApiUrl}/fetchSuggestions/v1?q=${query}&type=statute`
        );
        const responseData = await response?.json();
        if (query === latestQueryRef.current) {
          setSuggestions(responseData?.data || []);
          setIsDropdownOpen(true);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setLoadingSuggestions(false);
      }
    }, 500),
    []
  );

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
    //setIsStatuteSearch(false);
  };

  const formatStatuteTitleHtml = (htmlString) => {
    if (!htmlString) return "";

    // Parse the HTML string into a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Function to apply title case to each word
    const titleCaseText = (text) => {
      return text
        .toLowerCase()
        .split(" ")
        .map((word) => {
          // Preserve acronyms (fully uppercase words)
          if (word.length === 1 || word === word.toUpperCase()) {
            return word;
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    };

    // Function to traverse the DOM and apply title case to text nodes only
    const traverseAndTitleCase = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Only apply title case to the text content, not HTML tags
          child.textContent = titleCaseText(child.textContent);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          // Recurse through element nodes
          traverseAndTitleCase(child);
        }
      });
    };

    // Start traversal and title case application
    traverseAndTitleCase(doc.body);

    // Return the modified HTML string
    return doc.body.innerHTML;
  };

  const formatStatuteTitle = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const formatStatuteSection = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const handlePageChange = (event, value) => {
    updateCurrentPage(value);
    dispatch({
      type: "STATUTE_PAGINATION_SUCCESS",
      payload: { pageNumber: value, resultsCount },
    });
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setStatuteNote(value);
      setStatuteNoteError("");
    } else {
      setStatuteNoteError("You can only input 2000 characters");
    }
  };

  const handleBookmarkClick = (statute, type) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }
    setSelectedStatute({ statute, type });
    const defaultGroup = groups?.find(
      (group) => group.groupName === "Bookmarks"
    );
    setSelectedGroup(defaultGroup?._id);
    //fetchBookmarkGroups();
    setOpenBookmarkModal(true);
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Bookmark on Statute Page" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setStatuteNote("");
    setStatuteNoteError("");
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }
      setIsAddBookmarkLoading(true);
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/createBookmarkGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupName: bookmarkGroupName,
          statuteId: selectedStatute?.statute?.Bookmark_ID,
          type: selectedStatute?.type,
          statuteName:
            selectedStatute?.statute?.Act_Ordinance ||
            selectedStatute?.statute?.Statute_Name,
          statuteNote,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedStatuteIds(
          (prevIds) =>
            new Set([...prevIds, selectedStatute?.statute?.Bookmark_ID])
        );
        setIsAddBookmarkLoading(false);
        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setStatuteNote("");
        dispatch(fetchBookmarkGroups(userInfo._id));
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      setIsAddBookmarkLoading(false);
      console.error("Error creating bookmark group:", error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      setIsAddBookmarkLoading(true);
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/addStatuteToGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupId: selectedGroup,
          statuteId: selectedStatute?.statute?.Bookmark_ID,
          type: selectedStatute?.type,
          statuteName:
            selectedStatute?.statute?.Act_Ordinance ||
            selectedStatute?.statute?.Statute_Name,
          statuteNote,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedStatuteIds(
          (prevIds) =>
            new Set([...prevIds, selectedStatute?.statute?.Bookmark_ID])
        );
        setIsAddBookmarkLoading(false);
        setOpenBookmarkModal(false);
        setStatuteNote(""); // Clear notes after submission
      } else {
        throw new Error(data.message || "Failed to add statute to group.");
      }
    } catch (error) {
      setIsAddBookmarkLoading(false);
      console.error("Error adding statute to group:", error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      //fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(groups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark removed",
        });
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "UnBookmark on Statute Page" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        console.error("Error deleting bookmarked statute");
      }
    } catch (error) {
      console.error("Error deleting bookmarked statute:", error);
    }
  };

  const handleStatuteNameClick = (name) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    const cleanedName = name.replace(/<\/?[^>]+(>|$)/g, "");
    dispatch(searchSections("", cleanedName, searchMode));
    setOpenSectionsDialog(true);
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Sections button click on statute page",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleSectionsClick = (name, id) => {
    setOpenSections((prev) => {
      const isAlreadyOpen = prev[id];

      const newState = isAlreadyOpen ? {} : { [id]: true };

      if (!isAlreadyOpen) {
        const cleanedName = name.replace(/<\/?[^>]+(>|$)/g, "");
        dispatch(searchSections("", cleanedName, searchMode));
        setOpenRelevantCases({});
      }

      return newState;
    });
  };

  const handleRelevantCasesClick = (id) => {
    setOpenRelevantCases((prev) => {
      const isAlreadyOpen = prev[id];

      const newState = isAlreadyOpen ? {} : { [id]: true };

      !isAlreadyOpen && setOpenSections({});

      return newState;
    });
  };

  const handleSearch = (keyword) => {
    if (!keyword) return;
    setIsSearchClicked(true);
    const pageNumber = 1;
    updateCurrentPage(1);
    dispatch(
      statuteSearchResults(
        keyword,
        searchMode,
        resultsCount,
        (pageNumber - 1) * resultsCount,
        {
          year: selectedYear,
          letter: selectedLetter,
          province: selectedStatuteProvinceFilter,
          type: selectedStatuteTypeFilter,
        }
      )
    );
  };

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = "en-US";

  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join("");
    updateStatuteSearchValue(transcript);
    finalTranscriptRef.current = transcript;
    resetSilenceTimer();
  };

  recognition.onerror = (event) => {
    console.error(event.error);
    stopListening();
  };

  const startListening = () => {
    setIsListening(true);
    setPlaceholder("Speak...");
    recognition.start();

    startSilenceTimer();
  };

  const stopListening = () => {
    setIsProcessing(true);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsProcessing(false);
    if (searchMode === "Title") {
      setPlaceholder("Search by title...");
    } else {
      setPlaceholder("Search by content...");
    }
    clearSilenceTimer();
    if (finalTranscriptRef.current) {
      handleSearch(finalTranscriptRef.current);
    }
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    updateStatuteSearchValue(suggestion);
    setIsDropdownOpen(false);
    setSuggestions([]);
    handleSearch(suggestion);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    updateStatuteSearchValue(value);
    setFocusedIndex(-1);

    if (value.trim() === "") {
      fetchSuggestions.cancel();
      setIsDropdownOpen(false);
      setSuggestions([]);
      return;
    }
    setIsDropdownOpen(true);
    fetchSuggestions(value);
  };

  const handleKeyDown = (e) => {
    //if (!isDropdownOpen || suggestions?.length === 0) return;

    if (e.key === "ArrowDown") {
      if (suggestions.length === 0) return;
      setFocusedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (e.key === "ArrowUp") {
      if (suggestions.length === 0) return;
      setFocusedIndex((prevIndex) =>
        prevIndex === -1
          ? suggestions.length - 1
          : (prevIndex - 1 + suggestions.length) % suggestions.length
      );
    } else if (e.key === "Enter") {
      if (statuteSearchValue?.trim() === "") return;
      if (focusedIndex === -1) {
        handleSearch(statuteSearchValue);
      } else {
        handleSearch(suggestions[focusedIndex]?.queryPlusText);
        updateStatuteSearchValue(suggestions[focusedIndex]?.queryPlusText);
      }
      setIsDropdownOpen(false);
      setSuggestions([]);
      setFocusedIndex(-1);
    } else if (e.key === "Escape") {
      setSuggestions([]);
      setIsDropdownOpen(false);
      setFocusedIndex(-1);
    }
  };

  // Update search bar value when focusedIndex changes
  // useEffect(() => {
  //   if (focusedIndex >= 0) {
  //     updateStatuteSearchValue(suggestions[focusedIndex]?.queryPlusTextt);
  //   }
  // }, [focusedIndex]);

  useEffect(() => {
    if (!isInitialSearchDone.current) {
      dispatch(
        statuteSearchResults(
          statuteSearchValue,
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: selectedStatuteProvinceFilter,
            type: selectedStatuteTypeFilter,
          }
        )
      );
      isInitialSearchDone.current = true;
      setIsMount(true);
    }
  }, [currentPage, statuteSearchValue, searchMode, resultsCount, dispatch]);

  useEffect(() => {
    if (isMount) {
      dispatch(
        statuteSearchResults(
          statuteSearchValue,
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: selectedStatuteProvinceFilter,
            type: selectedStatuteTypeFilter,
          }
        )
      );
    }
  }, [currentPage, resultsCount]);

  useEffect(() => {
    setOpenSections({});
  }, [isStatuteFilterApplied]);

  // Default search results on clearing search query
  useEffect(() => {
    if (!statuteSearchValue && isSearchClicked) {
      dispatch(
        statuteSearchResults(
          "",
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: selectedStatuteProvinceFilter,
            type: selectedStatuteTypeFilter,
          }
        )
      );
      setOpenSections({});
      setIsSearchClicked(false);
    }
  }, [
    dispatch,
    statuteSearchValue,
    isSearchClicked,
    searchMode,
    resultsCount,
    currentPage,
  ]);

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
    return () => updateStatuteSearchValue("");
  }, [userInfo, dispatch, updateStatuteSearchValue]);

  useEffect(() => {
    if (groups) {
      // Extract all statute IDs from the statutes array in each group document
      const allStatuteIds = groups?.flatMap((group) =>
        group.statutes.map((statute) => statute.id)
      );

      // Set the bookmark groups and statute IDs
      setBookmarkedStatuteIds(new Set(allStatuteIds)); // Store IDs in a Set for fast lookup
      const defaultGroup = groups?.find(
        (group) => group.groupName === "Bookmarks"
      );
      setSelectedGroup(defaultGroup?._id);
    }
  }, [groups]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSuggestions([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      fetchSuggestions.cancel();
    };
  }, [fetchSuggestions]);

  // Scroll to the focused item when focusedIndex changes
  useEffect(() => {
    if (focusedIndex >= 0 && itemRefs.current[focusedIndex]) {
      itemRefs.current[focusedIndex].scrollIntoView({
        behavior: "auto",
        block: "nearest",
      });
    }
  }, [focusedIndex]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
          width: { xs: "100%", lg: "80%" },
          flex: 5,
          p: { xs: "8px 8px 0 8px", lg: "12px 12px 0 4px" },
          paddingBottom: "4px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: "#ffffff",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              size="small"
              sx={{ mr: "2px", p: 0, display: { xs: "flex", lg: "none" } }}
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon
                icon={faFilter}
                color="#3c50b1"
                fontSize="medium"
              />
            </IconButton>
            <TextField
              variant="outlined"
              autoFocus
              placeholder={placeholder}
              value={statuteSearchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                width: "85%",
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                mx: "8px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#00509e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00509e",
                  },
                },
                "& input": {
                  padding: { xs: "5px", lg: "10px" },
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                sx: { borderRadius: 8, p: 0 },
                startAdornment: (
                  <SearchIcon
                    sx={{ color: "#303f9f", padding: "8px" }}
                    fontSize={isMobileView ? "small" : "medium"}
                  />
                ),
                endAdornment: loadingSuggestions ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={18} sx={{ marginRight: "8px" }} />
                  </Box>
                ) : (
                  <IconButton
                    sx={{
                      color: isListening ? "#FF4081" : "#303f9f",
                      animation: isListening ? "pulse 1.5s infinite" : "none",
                    }}
                    variant="contained"
                    onClick={toggleListening}
                    disabled={isProcessing}
                  >
                    <KeyboardVoice
                      fontSize={isMobileView ? "small" : "medium"}
                    />
                  </IconButton>
                ),
              }}
            />
            <FormControl
              variant="outlined"
              sx={{
                width: { xs: "30%", md: "15%" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "32px",
                  padding: { xs: "4px", md: "8px" },
                },
                "& fieldset": {
                  borderColor: "#ddd",
                  borderWidth: "1px",
                },
                "& .MuiSelect-icon": {
                  right: { xs: "0px", md: "4px" },
                },
                "& .MuiSelect-select": {
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", md: "0.875rem" },
                },
              }}
            >
              <Select
                id="searchModeSelector"
                value={searchMode}
                onChange={(e) => {
                  e.target.value === "Title"
                    ? setPlaceholder("Search by title...")
                    : setPlaceholder("Search by content...");
                  updateSearchMode(e.target.value);
                }}
                sx={{
                  fontSize: { xs: "0.75rem", md: "0.875rem" },
                  height: "32px",
                  bgcolor: "#fff",
                }}
              >
                <MenuItem
                  value="Title"
                  sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
                >
                  {isMobileView ? "Title" : "Search by Title"}
                </MenuItem>
                <MenuItem
                  value="Section"
                  sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
                >
                  {isMobileView ? "Content" : "Search by Content"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {isDropdownOpen && suggestions.length > 0 && (
            <SuggestionsDropdown
              suggestions={suggestions}
              focusedIndex={focusedIndex}
              onSuggestionClick={handleSuggestionClick}
              itemRefs={itemRefs}
              ref={paperRef}
              type="statute"
            />
          )}
        </Box>
        {statuteLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <CircularProgress size={18} />
          </Box>
        ) : results?.count === 0 && !loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "35px", lg: "50px" },
                marginBottom: { xs: "5px", lg: "10px" },
                color: "#303f9f",
              }}
            >
              <SearchIcon fontSize="inherit" />
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "16px", lg: "24px" },
                marginBottom: { xs: "5px", lg: "10px" },
              }}
            >
              No Results Found
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "12px", lg: "16px" }, color: "#666" }}
            >
              Please review your search term or filter criteria.
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                m: { xs: "12px 0px 4px 0px", lg: "12px 0" },
              }}
            >
              <Typography
                sx={{
                  ml: 0.5,
                  fontWeight: "bold",
                  fontSize: { xs: "14px", lg: "18px" },
                }}
              >
                <span style={{ color: "#d2042d", fontWeight: "bold" }}>
                  {totalResults.toLocaleString()}{" "}
                </span>{" "}
                {totalResults === 9981
                  ? "Total Statutes"
                  : searchMode === "Section" &&
                    totalResults > 0 &&
                    isSearchClicked
                  ? "Results Found"
                  : "Statutes Found"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputLabel
                  sx={{
                    marginRight: "4px",
                    fontSize: "13px",
                    color: "#555",
                    fontWeight: "500",
                  }}
                  htmlFor="resultsCount"
                >
                  Show:
                </InputLabel>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: isMobileView ? 90 : 100,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "6px",
                      height: "32px",
                      padding: "8px",
                    },
                    "& fieldset": {
                      borderColor: "#ddd",
                      borderWidth: "1px",
                    },
                    "& .MuiSelect-icon": {
                      right: "4px",
                    },
                    "& .MuiSelect-select": {
                      padding: "0px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <Select
                    id="resultsCount"
                    value={resultsCount}
                    onChange={(e) => updateResultsCount(e.target.value)}
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      height: "32px",
                      bgcolor: "#fff",
                    }}
                  >
                    <MenuItem
                      value={10}
                      sx={{ fontSize: { xs: "12px", md: "14px" } }}
                    >
                      10 results
                    </MenuItem>
                    <MenuItem
                      value={20}
                      sx={{ fontSize: { xs: "12px", md: "14px" } }}
                    >
                      20 results
                    </MenuItem>
                    <MenuItem
                      value={50}
                      sx={{ fontSize: { xs: "12px", md: "14px" } }}
                    >
                      50 results
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Stack
              direction="row"
              flexWrap="wrap"
              gap={1}
              sx={{
                display: { xs: "flex", lg: "none" },
                justifyContent: "start",
                width: "100%",
                mb: "8px",
              }}
            >
              {[
                { label: "Letter", value: selectedLetter },
                { label: "Year", value: selectedYear },
                { label: "Statute Type", value: selectedStatuteTypeFilter },
                { label: "Province", value: selectedStatuteProvinceFilter },
              ]
                .filter((filter) => filter.value)
                .map((filter, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={`${filter.label}: ${filter.value}`}
                    sx={{
                      backgroundColor: "#e3f2fd",
                      color: "#1976d2",
                      fontSize: { xs: "12px", sm: "14px" },
                      fontWeight: "500",
                    }}
                  />
                ))}
            </Stack>
            <List
              sx={{
                flexGrow: 1,
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "8px",
                overflowY: "auto",
                scrollBehavior: "smooth",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              {results &&
                results?.["search_results"]?.map((statute, index) => (
                  <>
                    <ListItem
                      key={statute.id}
                      sx={{
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          width: "100%",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          transform: "translateY(-2px)",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "14px", md: "16px" },
                                color: "#303f9f",
                                ml: 0.5,
                                "&:hover": {
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                },
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  formatStatuteTitleHtml(
                                    statute?.highlighted_statute_name
                                  ) ||
                                  formatStatuteTitleHtml(
                                    statute?.highlighted_Statute_Name
                                  ) ||
                                  formatStatuteTitle(statute.Statute_Name) ||
                                  "",
                              }}
                              onClick={() => {
                                handleStatuteNameClick(
                                  statute.Statute_Name || statute.Statute_name
                                );
                              }}
                            />
                          }
                          secondary={
                            <>
                              {statute?.Documents?.length > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "70px",
                                    overflowY: "auto",
                                    scrollBehavior: "smooth",
                                    "::-webkit-scrollbar": {
                                      width: "5px",
                                    },
                                    "::-webkit-scrollbar-track": {
                                      background: "#f1f1f1",
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                      background: "#bbb",
                                      borderRadius: "6px",
                                    },
                                    bgcolor: "#f5f5f5",
                                    color: "black",
                                    p: 1,
                                    mt: 1,
                                    borderRadius: "4px",
                                    fontSize: { xs: "11px", md: "13px" },
                                  }}
                                >
                                  {statute?.Documents?.sort((a, b) => {
                                    if (a.Section === "PREAMBLE") return -1;
                                    if (b.Section === "PREAMBLE") return 1;
                                    const sectionA = parseFloat(a.Section);
                                    const sectionB = parseFloat(b.Section);
                                    return sectionA - sectionB;
                                  })?.map((section, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        mb: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: { xs: "11px", md: "13px" },
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>{`${section.Section}- `}</span>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              section.highlighted_definition ||
                                              section.Definition,
                                          }}
                                        />
                                      </Typography>
                                      <Typography
                                        sx={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 3,
                                          overflow: "hidden",
                                          bgcolor: "#f5f5f5",
                                          color: "black",
                                          borderRadius: "4px",
                                          fontSize: "12px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: getHighlightedSentences(
                                            section.highlighted_statute
                                          ),
                                        }}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: {
                                    xs: "center",
                                    md: "flex-start",
                                  },
                                  alignItems: "center",
                                  mt: 1,
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    handleSectionsClick(
                                      statute.Statute_name ||
                                        statute.Statute_Name,
                                      statute.doc_id || index
                                    );
                                  }}
                                >
                                  <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                    {openSections[statute.doc_id] ? (
                                      <ExpandLessIcon
                                        sx={{ color: "#ff9800" }}
                                      />
                                    ) : (
                                      <ExpandMoreIcon
                                        sx={{ color: "#ff9800" }}
                                      />
                                    )}
                                  </IconButton>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                      fontSize: { xs: "12px", md: "14px" },
                                      mr: 0.5,
                                      color: "#303f9f",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    Sections
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                    <ImportContactsIcon
                                      fontSize="small"
                                      sx={{ color: "#7cbb00" }}
                                    />
                                  </IconButton>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                      fontSize: { xs: "12px", md: "14px" },
                                      mr: 0.5,
                                      color: "#303f9f",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    onClick={() => {
                                      handleStatuteNameClick(
                                        statute.Statute_name ||
                                          statute.Statute_Name
                                      );
                                    }}
                                  >
                                    Read
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    handleRelevantCasesClick(
                                      statute?.Bookmark_ID
                                    );
                                  }}
                                >
                                  <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                    <FontAwesomeIcon
                                      icon={faScaleBalanced}
                                      color="#a56d51"
                                      fontSize="16px"
                                    />
                                  </IconButton>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                      mr: 0.5,
                                      color: "#303f9f",
                                      cursor: "pointer",
                                      fontWeight: openRelevantCases[
                                        statute?.Bookmark_ID
                                      ]
                                        ? "bold"
                                        : "normal",
                                      textDecoration: openRelevantCases[
                                        statute?.Bookmark_ID
                                      ]
                                        ? "underline"
                                        : "none",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    Cases
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() =>
                                    bookmarkedStatuteIds.has(
                                      statute?.Bookmark_ID
                                    )
                                      ? handleUnBookmarkStatute(
                                          statute.Bookmark_ID
                                        )
                                      : handleBookmarkClick(statute, "statute")
                                  }
                                >
                                  <IconButton
                                    sx={{
                                      p: 0,
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    {bookmarkedStatuteIds.has(
                                      statute.Bookmark_ID
                                    ) ? (
                                      <TurnedInIcon
                                        sx={{ color: "#d2042d" }}
                                        fontSize="small"
                                      />
                                    ) : (
                                      <TurnedInNotIcon
                                        sx={{ color: "#d2042d" }}
                                        fontSize="small"
                                      />
                                    )}
                                  </IconButton>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                      fontSize: { xs: "12px", md: "14px" },
                                      ml: 0.5,
                                      color: "#303f9f",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                  >
                                    Bookmark
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          }
                        />
                        {/* Collapsible Section List */}
                        <Collapse
                          in={openSections[statute.doc_id || index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress
                                sx={{ color: "#303f9f" }}
                                size={isMobileView ? 16 : 20}
                              />
                            </Box>
                          ) : (
                            <List
                              component="div"
                              disablePadding
                              sx={{
                                maxHeight: "24rem",
                                overflowY: "auto",
                                scrollBehavior: "smooth",
                                "::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                "::-webkit-scrollbar-track": {
                                  background: "#fff",
                                },
                                "::-webkit-scrollbar-thumb": {
                                  background: "#bbb",
                                  borderRadius: "6px",
                                },
                              }}
                            >
                              {sections?.[0]?.related_data
                                ?.sort((a, b) => {
                                  if (a.Section === "PREAMBLE") return -1;
                                  if (b.Section === "PREAMBLE") return 1;
                                  const sectionA = parseFloat(a.Section);
                                  const sectionB = parseFloat(b.Section);
                                  return sectionA - sectionB;
                                })
                                .map((section, index) => {
                                  const isSectionOpen =
                                    openSections[section.doc_id]?.[
                                      section.Section
                                    ];
                                  return (
                                    <Box
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderTop: "1px solid #ddd",
                                        marginX: "16px",
                                        paddingY: "4px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {/* Toggle Icon */}
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setOpenSections((prev) => ({
                                              ...prev,
                                              [section.doc_id]: {
                                                ...prev[section.doc_id],
                                                [section.Section]:
                                                  !isSectionOpen,
                                              },
                                            }));
                                          }}
                                          sx={{ color: "#303f9f", ml: 1 }}
                                        >
                                          {isSectionOpen ? (
                                            <RemoveCircleOutlineIcon fontSize="small" />
                                          ) : (
                                            <AddCircleOutlineIcon fontSize="small" />
                                          )}
                                        </IconButton>
                                        {/* Section Title */}
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "11px",
                                              md: "13px",
                                            },
                                            fontWeight: "bold",
                                            flexGrow: 1,
                                          }}
                                        >
                                          {`${
                                            section.Section
                                          }- ${formatStatuteSection(
                                            section.Definition
                                          )}`}
                                        </Typography>

                                        {/* Bookmark Icon */}
                                        <IconButton
                                          onClick={() =>
                                            bookmarkedStatuteIds.has(
                                              section?.Bookmark_ID
                                            )
                                              ? handleUnBookmarkStatute(
                                                  section.Bookmark_ID
                                                )
                                              : handleBookmarkClick(
                                                  section,
                                                  "section"
                                                )
                                          }
                                          sx={{ color: "#d2042d", ml: 1 }}
                                        >
                                          {bookmarkedStatuteIds.has(
                                            section.Bookmark_ID
                                          ) ? (
                                            <TurnedInIcon
                                              sx={{ color: "#d2042d" }}
                                              fontSize="small"
                                            />
                                          ) : (
                                            <TurnedInNotIcon
                                              sx={{ color: "#d2042d" }}
                                              fontSize="small"
                                            />
                                          )}
                                        </IconButton>
                                      </Box>

                                      {/* Collapsible Text */}
                                      {isSectionOpen && (
                                        <Collapse
                                          in={isSectionOpen}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "11px",
                                                md: "12px",
                                              },
                                              textAlign: "justify",
                                              marginTop: "8px",
                                              marginLeft: "16px",
                                            }}
                                          >
                                            {section.Statute ||
                                              "No additional information available."}
                                          </Typography>
                                        </Collapse>
                                      )}
                                    </Box>
                                  );
                                })}
                            </List>
                          )}
                        </Collapse>
                        <CollapsibleList
                          isOpen={openRelevantCases[statute?.Bookmark_ID]}
                          items={statute?.Citations || []}
                          label="cases"
                        />
                      </Box>
                    </ListItem>
                  </>
                ))}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Pagination
                count={Math.ceil(totalResults / resultsCount)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  m: "5px 0",
                  "& .MuiPaginationItem-root": {
                    fontSize: "13px",
                    minWidth: "24px",
                    height: "24px",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#303f9f",
                    },
                    "&.Mui-selected": {
                      color: "#fff",
                      backgroundColor: "#213165",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    },
                  },
                }}
              />
              {snackbarMessage && (
                <Snackbar
                  open={!!snackbarMessage}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  onClose={() => setSnackbarMessage(null)}
                >
                  <Alert
                    onClose={() => setSnackbarMessage(null)}
                    severity={snackbarMessage.type}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage.text}
                  </Alert>
                </Snackbar>
              )}
            </Box>
          </>
        )}
      </Box>
      <StatuteDetailDialog
        open={openSectionsDialog}
        onClose={() => setOpenSectionsDialog(false)}
      />
      <StatutePdfViewer
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />
      <Dialog
        open={openBookmarkModal}
        onClose={handleCloseBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiPaper-root": {
            width: "400px",
            borderRadius: 2,
            padding: 2,
            boxShadow: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            color: "#303f9f",
            fontSize: { xs: "16px", lg: "18px" },
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Bookmark Statute
          <IconButton
            color="error"
            onClick={handleCloseBookmarkModal}
            sx={{
              padding: 0,
              color: "gray",
              "&:hover": { color: "#d32f2f" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            width: "100%",
            overflow: "visible",
            padding: 0,
            marginTop: 1,
          }}
        >
          <TextField
            sx={{
              mt: 1,
              mb: { xs: 0, lg: 1 },
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            label="Add to existing group"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "create-new") {
                setBookmarkGroupName("");
              }
              setSelectedGroup(selectedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
              value="create-new"
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  key={group._id}
                  value={group._id}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === "create-new" && (
            <TextField
              label="New Group Name"
              fullWidth
              size="small"
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{
                mt: { xs: 1, lg: 2 },
                mb: { xs: 0, lg: 2 },
                bgcolor: "white",
                "& .MuiInputBase-root": {
                  fontSize: "13px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            error={Boolean(statuteNoteError)}
            helperText={statuteNoteError}
            onChange={(e) => handleNoteChange(e.target.value)}
            sx={{
              mt: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            mt: 1,
            justifyContent: "flex-end",
            padding: "8px 0px 8px 8px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCloseBookmarkModal}
            color="error"
            sx={{
              fontSize: { xs: "13px", lg: "14px" },
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              isAddBookmarkLoading && (
                <CircularProgress size={16} sx={{ color: "white" }} />
              )
            }
            sx={{
              fontSize: { xs: "13px", lg: "14px" },
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              backgroundColor: "#003D78",
              "&:hover": { backgroundColor: "primary.main", color: "#fff" },
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={
              selectedGroup === "create-new"
                ? handleCreateBookmarkGroup
                : handleAddStatuteToGroup
            }
            disabled={
              selectedGroup === "create-new"
                ? !bookmarkGroupName
                : !selectedGroup || statuteNoteError
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <InfoDialog
        open={openInfoDialog}
        handleClose={handleCloseInfoDialog}
        isStatuteSearch={isStatuteSearch}
      />
    </>
  );
};

export default StatuteSearch;
