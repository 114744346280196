import React from "react";
import { Tabs, Tab, styled, Box, Container } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Search } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const StyledTabs = styled(Tabs)({
  minHeight: 40,
  "& .MuiTabs-indicator": {
    backgroundColor: "#303f9f",
    height: 2,
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "13px",
  minWidth: 120,
  minHeight: 40,
  padding: theme.spacing(1, 1.5),
  color: theme.palette.text.secondary,
  transition: "all 0.2s ease",

  "&.Mui-selected": {
    color: "#303f9f",
    fontWeight: 600,
  },

  "& .MuiTab-iconWrapper": {
    marginRight: theme.spacing(1),
    fontSize: "16px",
  },
}));

const CaseSearchNavbar = ({ activeTab, setActiveTab }) => {
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderBottom: 1,
        borderColor: "divider",
        mb: 1,
        borderRadius: "8px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {/* Navigation Tabs */}
        <StyledTabs value={activeTab} onChange={handleTabChange}>
          <StyledTab
            label="Judgements"
            icon={<Search fontSize="small" sx={{ color: "#303f9f" }} />}
            iconPosition="start"
          />
          <StyledTab
            label="Maxims"
            icon={
              <FontAwesomeIcon
                icon={faLightbulb}
                fontSize="18px"
                color="#ff9800"
              />
            }
            iconPosition="start"
          />
          <StyledTab
            label="Legal Terms"
            icon={<GavelIcon fontSize="small" sx={{ color: "#a56d51" }} />}
            iconPosition="start"
          />
          <StyledTab
            label="Words and Phrases"
            icon={
              <LibraryBooksIcon fontSize="small" sx={{ color: "#2A5C8B" }} />
            }
            iconPosition="start"
          />
        </StyledTabs>
      </Container>
    </Box>
  );
};

export default CaseSearchNavbar;
