import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import LetterAutocomplete from "../UI/LetterAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  resetListingFilters,
  setListingFilters,
} from "../../store/CaseSearch/actions";

const LegalTermsFilters = () => {
  const listingType = "legalTerms";
  const dispatch = useDispatch();

  const legalTermsFilters = useSelector(
    (state) => state.listingFilters?.legalTerms
  );
  const letter = legalTermsFilters?.letter;

  const handleLetterChange = (e, value) => {
    dispatch(setListingFilters(listingType, value));
  };

  const handleClearFilters = (e) => {
    dispatch(resetListingFilters(listingType));
  };

  useEffect(() => {
    return () => {
      dispatch(resetListingFilters(listingType));
    };
  }, [dispatch]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          margin: "4px 6px 18px 6px",
          padding: "8px 24px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          Filters
        </Typography>
      </Box>
      {letter && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            color="error"
            sx={{ textTransform: "none" }}
            onClick={handleClearFilters}
          >
            Clear filter(s)
          </Button>
        </Box>
      )}
      <LetterAutocomplete
        letter={letter}
        handleLetterChange={handleLetterChange}
      />
    </>
  );
};

export default LegalTermsFilters;
