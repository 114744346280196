import { Drawer, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import LegalSearchSidebar from "../../components/layout/LegalSearchSidebar";
import SearchResults from "../../components/SearchResults";

export const CaseSearch = () => {
  const [caseLawSearchQuery, setCaseLawSearchQuery] = useState("");
  const [caseLawCurrentPage, setCaseLawCurrentPage] = useState(1);
  const [caseLawSortBy, setCaseLawSortBy] = useState("Most Relevant");
  const [caseLawSearchMode, setCaseLawSearchMode] = useState("keyword");
  const [caseLawResultsCount, setCaseLawResultsCount] = useState(10);
  const [isNewCaseLawSearch, setIsNewCaseLawSearch] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const toggleSidebarVisibility = () => setIsSidebarVisible(!isSidebarVisible);

  const commonSidebarProps = {
    toggleSidebar: toggleSidebarVisibility,
    searchKeyword: caseLawSearchQuery,
    currentPage: caseLawCurrentPage,
    setCurrentPage: setCaseLawCurrentPage,
    isNewCaseLawSearch,
    setIsNewCaseLawSearch,
    searchMode: caseLawSearchMode,
    sortBy: caseLawSortBy,
    resultsCount: caseLawResultsCount,
    activeTab,
  };

  return (
    <Stack direction="row" height="calc(100vh - 50px)" width="100%">
      {isMobileView ? (
        <Drawer
          anchor="left"
          open={isSidebarVisible}
          onClose={toggleSidebarVisibility}
        >
          <LegalSearchSidebar
            {...commonSidebarProps}
            sideBarState={isSidebarVisible}
          />
        </Drawer>
      ) : (
        <LegalSearchSidebar {...commonSidebarProps} />
      )}

      <SearchResults
        toggleSidebar={toggleSidebarVisibility}
        searchKeyword={caseLawSearchQuery}
        setSearchKeyword={setCaseLawSearchQuery}
        currentPage={caseLawCurrentPage}
        setCurrentPage={setCaseLawCurrentPage}
        setIsNewCaseLawSearch={setIsNewCaseLawSearch}
        searchMode={caseLawSearchMode}
        updateSearchMode={setCaseLawSearchMode}
        sortBy={caseLawSortBy}
        updateSortBy={setCaseLawSortBy}
        resultsCount={caseLawResultsCount}
        updateResultsCount={setCaseLawResultsCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </Stack>
  );
};
