import React from "react";
import {
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const LetterAutocomplete = ({ letter, handleLetterChange }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: 600,
          color: "#333",
          ml: "5px",
          mb: "4px",
        }}
      >
        Letter
      </Typography>
      <FormControl fullWidth>
        <Autocomplete
          size="small"
          value={letter || ""}
          onChange={handleLetterChange}
          disableClearable={!letter}
          clearIcon={letter ? <ClearIcon fontSize="small" /> : null}
          options={alphabets}
          getOptionLabel={(option) => option || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Letter"
              sx={{
                width: "95%",
                ml: "5px",
                mb: "16px",
                "& .MuiInputBase-input": {
                  fontSize: "13px",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "12px",
                },
              }}
            />
          )}
          componentsProps={{
            paper: {
              sx: {
                "& .MuiAutocomplete-listbox": {
                  fontSize: "13px",
                  height: "180px",
                },
              },
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export default LetterAutocomplete;
