import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import config from "../config/api";
import backgroundImage from "./../assets/images/bgImage.png";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const EmailVerificationSuccessPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const resendVerificationEmail = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Frontend-Origin": window.location.origin, // Add your Frontend-Origin here
      },
    };

    try {
      const response = await axios.get(
        `${baseUrl}/resendVerificationEmail`,
        {
          params: { token: token, origin: window.location.origin },
        },
        config
      );

      if (response.status === 200) {
        setMessage("Verification email resent successfully");
        setOpenToast(true);
        setShowResendButton(false);
      }
    } catch (error) {
      setMessage("Failed to resend verification email");
      setErrorOpen(true);
    }
  };
  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setVerificationStatus("error");
        setMessage("No verification token provided");
        setErrorOpen(true);
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${baseUrl}/verifyEmail`, {
          params: { token },
        }); 

        if (response.status === 200) {
          if (response.data.message === "User already verified.") {
            setVerificationStatus("already-verified");
          } else {
            setVerificationStatus("success");
          }
          setMessage(response.data.message);
          setOpenToast(true);
        } else { 

          setVerificationStatus("error");

          setMessage(response.data.message || "Email verification failed");
          setErrorOpen(true);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data?.message === "Invalid or expired token.") {
          setShowResendButton(true);
        }
        setVerificationStatus("error");

        setMessage(
          error.response?.data?.message || "An unexpected error occurred"
        );
        setErrorOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [location]);

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorOpen(false);
    navigate("/login");
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress size={50} sx={{ color: "#003D78" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: { xs: "90%", sm: "400px" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            alignSelf: "flex-start",
            fontWeight: "bold",
            color: "#003D78",
            mb: 2,
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 0,
              width: "60px",
              height: "3px",
              backgroundColor: "#2CC4EF",
            },
          }}
        >
          Email Verification
        </Typography>

        {verificationStatus === "success" && (
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 60, color: "#4caf50", mb: 1 }}
            />
            <Typography variant="h6" sx={{ color: "#003D78", mb: 1 }}>
              Email Verified Successfully
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", mb: 2 }}>
              Your email has been successfully verified. You can now log in to
              your account.
            </Typography>
          </Box>
        )}

        {verificationStatus === "already-verified" && (
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <InfoIcon sx={{ fontSize: 60, color: "#2196f3", mb: 1 }} />
            <Typography variant="h6" sx={{ color: "#003D78", mb: 1 }}>
              Email Already Verified
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", mb: 2 }}>
              Your email has already been verified. You can proceed to log in to
              your account.
            </Typography>
          </Box>
        )}

        {verificationStatus === "error" && (
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <ErrorIcon sx={{ fontSize: 60, color: "#f44336", mb: 1 }} />
            <Typography variant="h6" sx={{ color: "#003D78", mb: 1 }}>
              Verification Failed
            </Typography>
            <Typography variant="body1" sx={{ color: "#666", mb: 2 }}>
              {message}
            </Typography>
          </Box>
        )}

        <Button
          size="large"
          fullWidth
          sx={{
            fontWeight: "bold",
            borderRadius: "8px",
            backgroundColor: "#003D78",
            marginTop: "0.5rem",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          variant="contained"
          onClick={() => {
            if (showResendButton) {
              resendVerificationEmail();
            } else {
              handleLoginClick();
            }
          }}
        >
          {showResendButton ? "Resend Verification Email" : "Go to Login Page"}
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        autoHideDuration={3000}
        onClose={handleCloseToast}
      >
        <Alert
          severity={
            verificationStatus === "already-verified" ? "info" : "success"
          }
          variant="filled"
          sx={{ width: "100%" }}
          onClose={handleCloseToast}
        >
          {message}
        </Alert>
      </Snackbar>

      {message !== "Invalid or expired token." && (
        <Dialog
          open={errorOpen}
          onClose={handleCloseErrorDialog}
          PaperProps={{
            sx: {
              borderRadius: "12px",
              padding: "20px",
              width: { xs: "90%", sm: "400px" },
              height: "auto",
              maxWidth: "100%",
            },
          }}
          TransitionComponent={Slide}
          TransitionProps={{ direction: "up", timeout: 500 }}
        >
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
            }}
          >
            <ErrorIcon sx={{ color: "#dc3545", fontSize: "50px" }} />
            <DialogTitle
              sx={{
                color: "#003D78",
                fontWeight: "bold",
                fontSize: "20px",
                padding: "12px 0",
              }}
            >
              Verification Failed
            </DialogTitle>
            <Typography
              variant="body1"
              gutterBottom
              textAlign="center"
              sx={{
                fontSize: "16px",
                marginTop: "8px",
                color: "#333",
                padding: "0 16px",
              }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "16px 0",
            }}
          >
            <Button
              onClick={handleCloseErrorDialog}
              variant="contained"
              sx={{
                bgcolor: "#003D78",
                textTransform: "none",
                padding: "10px 24px",
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#002F60",
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default EmailVerificationSuccessPage;
