import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "./Sidebar";
import ApplicationForm from "./ApplicationForm";
import ApplicationPreview from "./ApplicationPreview";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import HistoryTable from "./HistoryTable";

const ApplicationDrafting = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const dispatch = useDispatch();

  const selectedApplication = useSelector((state) => state.selectedApplication);
  const { selectedApp } = selectedApplication;

  const newApplicationRequest = useSelector(
    (state) => state.newApplicationRequest
  );
  const { isClicked } = newApplicationRequest;

  const [readOnly, setReadOnly] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [generatedApplication, setGeneratedApplication] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isHistoryClicked, setIsHistoryClicked] = useState(false);
  const [applicationToEdit, setApplicationToEdit] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  // New state for desktop sidebar
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const toggleDesktopSidebar = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (isClicked) {
      setGeneratedApplication("");
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
      dispatch({ type: "NEW_APPLICATION_RESET" });
    }
    if (isHistoryClicked) {
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
    }
  }, [isClicked, dispatch, isHistoryClicked]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }}
    >
      {isMobileView ? (
        <Drawer
          anchor="left"
          open={openSidebar}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={toggleSidebar}
        >
          <Sidebar
            toggleSidebar={toggleSidebar}
            setIsHistoryClicked={setIsHistoryClicked}
            setApplicationToEdit={setApplicationToEdit}
            setGeneratedApplication={setGeneratedApplication}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
          />
        </Drawer>
      ) : (
        <Sidebar
          isCollapsed={sidebarCollapsed}
          toggleSidebar={toggleDesktopSidebar}
          setIsHistoryClicked={setIsHistoryClicked}
          setApplicationToEdit={setApplicationToEdit}
          setGeneratedApplication={setGeneratedApplication}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
        />
      )}
      {isHistoryClicked ? (
        <HistoryTable
          toggleSidebar={toggleSidebar}
          setApplicationToEdit={setApplicationToEdit}
          setIsHistoryClicked={setIsHistoryClicked}
        />
      ) : isMobileView && (selectedApp || generatedApplication) ? (
        <ApplicationPreview
        isCollapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          readOnly={readOnly}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setReadOnly={setReadOnly}
          generatedApplication={generatedApplication}
          setGeneratedApplication={setGeneratedApplication}
          isGenerating={isGenerating}
          applicationToEdit={applicationToEdit}
          setApplicationToEdit={setApplicationToEdit}
        />
      ) : isMobileView ? (
        <ApplicationForm
        isCollapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          setGeneratedApplication={setGeneratedApplication}
          setIsGenerating={setIsGenerating}
          isGenerating={isGenerating}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <React.Fragment>
          <ApplicationForm
          isCollapsed={sidebarCollapsed}
            setGeneratedApplication={setGeneratedApplication}
            setIsGenerating={setIsGenerating}
            isGenerating={isGenerating}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
          <ApplicationPreview
          isCollapsed={sidebarCollapsed}
            readOnly={readOnly}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setReadOnly={setReadOnly}
            generatedApplication={generatedApplication}
            setGeneratedApplication={setGeneratedApplication}
            isGenerating={isGenerating}
            applicationToEdit={applicationToEdit}
            setApplicationToEdit={setApplicationToEdit}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ApplicationDrafting;
