import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import LoginPage from "./pages/LoginPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/msAuthConfig";
import { MsalProvider } from "@azure/msal-react";
import { deinitializeGA, initializeGA, trackPageView } from "./analytics";
import HomePage from "./pages/HomePage";
import Bookmarks from "./pages/Bookmarks";
import DocumentAnalysis from "./components/DocumentAnalysis";
import LegalLibrary from "./components/LegalLibrary";
import SignUpForm from "./pages/SignUpForm";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import ApplicationDrafting from "./components/ApplicationDrafting";
import PetitionDrafting from "./components/PetitionDrafting";
import CaseManagmentSystem from "./pages/CaseManagmentSystem/index";
import "./index.css";
import EmailVerificationSuccessPage from "./pages/EmailVerificationSuccessPage";
import SubscriptionPlans from "./pages/Subscriptions";
import { theme } from "./config/muiTheme";
import { TutorialVideos } from "./pages/Tutorial Videos";
import { LegalChat } from "./pages/LegalChat";
import { CaseSearch } from "./pages/CaseSearch";
import { StatuteSearchParent } from "./pages/StatuteSearch";
import { DocumentAnalysisParent } from "./pages/DocumentAnalysis";
import ComingSoonModal from "./components/Modal/CommingSoonModal";
const root = ReactDOM.createRoot(document.getElementById("root"));
initializeGA();

// Subscribe to Redux store for userInfo changes
store.subscribe(() => {
  const userLogin = store.getState().userLogin;
  const { userInfo } = userLogin;

  if (userInfo && userInfo._id) {
    // Reinitialize with the new user's ID
    initializeGA();
  } else {
    // Deinitialize when user logs out
    deinitializeGA();
  }
});
// msal instance
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

// Set the account on successful login
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult = event.payload;
    const account = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});

// Enable account storage events
msalInstance.enableAccountStorageEvents();

const TrackPageViews = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const handleResponse = async (response) => {
      if (response && response.account) {
        const { name, username } = response.account;
        try {
          const res = await fetch(
            `https://rg-law-gpt-be-auth2.azurewebsites.net/api/createUserWithGoogle`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ name, email: username }),
            }
          );
          const data = await res.json();
          localStorage.setItem("userInfo", JSON.stringify(data));
          dispatch({ type: "USER_LOGIN_SUCCESS", payload: data });
          <Navigate to="/app/chat" />;
        } catch (error) {
          console.log("Failed to register user:", error);
        }
      }
    };
    msalInstance.handleRedirectPromise().then(handleResponse);

    // Check if the user is already signed in
    const account = msalInstance.getActiveAccount();
    if (account) {
      // User is already signed in, navigate to the app
      handleResponse({ account });
    }
  }, [dispatch]);

  return children;
};

initializeGA();
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <GoogleOAuthProvider clientId="168527565999-ku8psofqjgsf673vh38ekf94rns19sv2.apps.googleusercontent.com">
            <React.StrictMode>
              <Router>
                <TrackPageViews>
                  <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpForm />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPasswordPage />}
                    />
                    <Route
                      path="/verify-email/:token"
                      element={<EmailVerificationSuccessPage />}
                    />
                    {/* Protected routes */}
                    <Route path="/app" element={<App />}>
                      <Route index element={<Navigate to="legal-chat" />} />
                      <Route path="home" element={<HomePage />} />
                      <Route path="legal-chat" element={<LegalChat />} />
                      <Route path="searchResults" element={<CaseSearch />} />
                      <Route
                        path="application-drafting"
                        element={<ApplicationDrafting />}
                      />
                      <Route
                        path="petition-drafting"
                        element={<PetitionDrafting />}
                      />

                      <Route
                        path="statute-search"
                        element={<StatuteSearchParent />}
                      />
                      <Route path="bookmarks" element={<Bookmarks />} />
                      <Route
                        path="document-analysis"
                        element={<DocumentAnalysisParent />}
                      />
                      <Route path="legal-library" element={<LegalLibrary />} />
                      <Route
                        path="case-management-system"
                        element={<CaseManagmentSystem />}
                      />
                      <Route
                        path="judge-gpt"
                        element={<ComingSoonModal/>}
                      />
                      <Route
                        path="subscriptions"
                        element={<SubscriptionPlans />}
                      />
                      <Route
                        path="tutorial-videos"
                        element={<TutorialVideos />}
                      />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </TrackPageViews>
              </Router>
            </React.StrictMode>
          </GoogleOAuthProvider>
        </MsalProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
