import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Pagination,
  IconButton,
  CircularProgress,
  Modal,
  Button,
  Snackbar,
  Alert,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Stack,
  Chip,
  useMediaQuery,
} from "@mui/material";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import CaseLawPreviewModal from "./UI/CaseLawPreviewModal";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findCaseSummary, searchResults } from "../actions/searchActions";
import CaseLawSearchBar from "./UI/CaseLawSearchBar";
import {
  faLightbulb,
  faRectangleList,
  faScaleBalanced,
} from "@fortawesome/free-solid-svg-icons";

import VisibilityIcon from "@mui/icons-material/Visibility";
import InsightsIcon from "@mui/icons-material/Insights";
import CaseDetailDialog from "./CaseDetailDialog";
import CaseSummaryDialog from "./CaseSummaryDialog";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";
import CollapsibleList from "./UI/CollapsibleList";
import MaximsListing from "./CaseSearch/MaximsListing";
import Maxims from "./UI/Maxims";
import LegalTerms from "./CaseSearch/LegalTerms";
import WordsAndPhrases from "./CaseSearch/WordsAndPhrases";
import CaseSearchNavbar from "./UI/CaseSearchNavbar";
import NoResultsFound from "./UI/NoResultsFound";
import { getOriginalJudgement } from "../utils/HelperFunction/getOriginalJudgement";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const deleteCaseUrl = `${baseUrl}/deleteCaseOrStatuteFromGroup`;

const SearchResults = ({
  toggleSidebar,
  searchKeyword,
  setSearchKeyword,
  currentPage,
  setCurrentPage,
  setIsNewCaseLawSearch,
  searchMode,
  updateSearchMode,
  sortBy,
  updateSortBy,
  resultsCount,
  updateResultsCount,
  activeTab,
  setActiveTab,
}) => {
  const dispatch = useDispatch();

  const searchResultsState = useSelector((state) => state.searchResults);
  const { loading, results } = searchResultsState;

  const searchSelectedFilters = useSelector((state) => state.selectedFilters);
  const { selectedFilters } = searchSelectedFilters;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [openPreview, setOpenPreview] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [caseNoteError, setCaseNoteError] = useState("");
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [bookmarkedCaseIds, setBookmarkedCaseIds] = useState(new Set());
  const [openSummary, setOpenSummary] = useState(false);
  const [openCaseInsights, setOpenCaseInsights] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const [isAddBookmarkLoading, setIsAddBookmarkLoading] = useState(false);
  const [openRelevantStatutes, setOpenRelevantStatutes] = useState({});
  const [openMaxims, setOpenMaxims] = useState({});

  const isInitialSearchDone = useRef(false);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const handleCloseCaseInsights = () => {
    setOpenCaseInsights(false);
  };

  const handleSearchModeChange = (value) => {
    updateSearchMode(value);
  };

  const handleSortByChange = (value) => {
    updateSortBy(value);
  };

  const handleChangeResultsCount = (value) => {
    updateResultsCount(value);
  };

  const handlePreviewClick = (caseItem) => {
    setSelectedCase(caseItem);
    setOpenPreview(true);
  };

  const handleSummaryClick = async (citation) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(findCaseSummary(citation));
    setOpenSummary(true);
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Fetch case summary on Case Search",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleBookmarkClick = async (caseItem) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }

    setSelectedCase(caseItem);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    setOpenBookmarkModal(true);
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Bookmark on case Search" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setCaseNote("");
    setCaseNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }
      setIsAddBookmarkLoading(true);
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/createBookmarkGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupName: bookmarkGroupName,
          caseId: selectedCase?.Bookmark_ID,
          caseNote,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase?.Bookmark_ID])
        );
        setIsAddBookmarkLoading(false);
        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setCaseNote("");
        setIsCreatingNewGroup(false);
        dispatch(fetchBookmarkGroups(userInfo._id));
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      setIsAddBookmarkLoading(false);
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const handleAddCaseToGroup = async () => {
    if (!selectedGroup) return;

    try {
      setIsAddBookmarkLoading(true);
      const userId = userInfo._id;
      const response = await fetch(`${baseUrl}/addCaseToGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupId: selectedGroup,
          caseId: selectedCase?.Bookmark_ID,
          caseNote,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase?.Bookmark_ID])
        );
        setIsAddBookmarkLoading(false);
        setOpenBookmarkModal(false);
        setCaseNote("");
      } else {
        throw new Error(data.message || "Failed to add case to group.");
      }
    } catch (error) {
      setIsAddBookmarkLoading(false);
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const findGroupIdByCaseId = (documents, caseId) => {
    // Find the document where the caseId exists in the 'cases' array
    const group = documents.find((doc) =>
      doc.cases.some((caseItem) => caseItem.id === caseId)
    );

    // Return the _id of the group or null if not found
    return group ? group._id : null;
  };

  const handleUnBookmark = async (caseId) => {
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }

      //fetchBookmarkGroups();
      const groupId = findGroupIdByCaseId(groups, caseId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId,
          caseId,
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark removed",
        });
        // Update the state by removing the case ID from bookmarked case IDs
        setBookmarkedCaseIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(caseId);
          return updatedIds;
        });
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Un Bookmark on Case Search" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        console.error("Error deleting bookmarked case");
      }
    } catch (error) {
      console.error("Error deleting bookmarked case:", error);
    }
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setCaseNote(value);
      setCaseNoteError("");
    } else {
      setCaseNoteError("You can only input 2000 characters");
    }
  };

  const handlePageChange = async (event, value) => {
    setCurrentPage(value);
    dispatch({
      type: "SEARCH_FILTER_PAGINATION_SET",
      payload: { pageNumber: value, resultsCount },
    });

    // dispatch(
    //   searchResults(
    //     searchKeyword,
    //     (value - 1) * resultsCount,
    //     resultsCount,
    //     searchMode,
    //     sortBy
    //   )
    // );
  };

  const formatCourtName = (courtName) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(courtName, "text/html");

    const formatTextContent = (name) => {
      return name
        .toLowerCase()
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const walkNodes = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          child.nodeValue = formatTextContent(child.nodeValue);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          walkNodes(child);
        }
      });
    };

    walkNodes(doc.body);

    return "&bull; " + doc.body.innerHTML;
  };

  const formatCourtNameWithoutTags = (courtName) => {
    if (!courtName) return "";

    return courtName
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatCaseTitle = (title) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(title, "text/html");

    const formatTextContent = (text) => {
      return text
        .split("Honorable")[0]
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    const walkNodes = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          child.nodeValue = formatTextContent(child.nodeValue);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          walkNodes(child);
        }
      });
    };

    walkNodes(doc.body);

    return doc.body.innerHTML;
  };

  const getHighlightedSentences = (description) => {
    if (!description || description?.trim() === "") return "";

    let sentences = [];

    // Split the string into sentences using delimiters like ., ?, !, etc.
    const splitSentences = description.split(/(?<=[.!?,])\s+/); // Split on punctuation followed by space
    sentences = sentences.concat(splitSentences); // Merge into one array of sentences

    // Prioritize sentences that contain <span> tags (highlighted parts)
    const highlightedSentences = sentences.filter((sentence) =>
      /<span/.test(sentence)
    );
    const unhighlightedSentences = sentences.filter(
      (sentence) => !/<span/.test(sentence)
    );

    // Combine the highlighted sentences first, followed by the unhighlighted ones
    const resultSentences = [
      ...highlightedSentences,
      ...unhighlightedSentences,
    ];

    // Join the resulting sentences back together into a single string
    return resultSentences.join(" ").trim();
  };

  const totalResults = results?.count || results?.length || 0;

  useEffect(() => {
    if (!isInitialSearchDone.current) {
      dispatch(
        searchResults(
          searchKeyword,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
      isInitialSearchDone.current = true;
      setIsMount(true);
    }
  }, [dispatch, searchKeyword, currentPage, resultsCount, searchMode, sortBy]);

  useEffect(() => {
    if (!selectedFilters && isMount) {
      dispatch(
        searchResults(
          searchKeyword,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    }
  }, [currentPage, dispatch, resultsCount, sortBy]);

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
    return () => {
      setSearchKeyword("");
      if (isMobileView) {
        dispatch({ type: "SEARCH_SELECTED_FILTERS_RESET" });
      }
    };
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (groups) {
      // Extract all case IDs from the cases array in each group document
      const allCaseIds = groups?.flatMap((group) =>
        group.cases.map((caseItem) => caseItem.id)
      );

      setBookmarkedCaseIds(new Set(allCaseIds)); // Store IDs in a Set for fast lookup
      const defaultGroup = groups?.find(
        (group) => group.groupName === "Bookmarks"
      );
      setSelectedGroup(defaultGroup?._id);
    }
  }, [groups]);

  return (
    <Box
      sx={{
        width: { xs: "100%", lg: "80%" },
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 50px)",
        flex: 5,
        boxSizing: "border-box",
        padding: {
          xs: "8px 8px 0 8px",
          lg: "12px 12px 0 4px",
        },
        backgroundColor: "#f5f5f5",
      }}
    >
      <CaseSearchNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 0 ? (
        <>
          <CaseLawSearchBar
            toggleSidebar={toggleSidebar}
            resultsCount={resultsCount}
            updateResultsCount={handleChangeResultsCount}
            sortBy={sortBy}
            updateSortBy={handleSortByChange}
            searchMode={searchMode}
            updateSearchMode={handleSearchModeChange}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            updateCurrentPage={setCurrentPage}
            setIsNewCaseLawSearch={setIsNewCaseLawSearch}
          />
          {results?.count === 0 ? (
            <NoResultsFound />
          ) : (
            <React.Fragment>
              {loading ? (
                <Box
                  sx={{
                    height: "10%",
                    marginTop: "24px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={isMobileView ? 16 : 20} />
                </Box>
              ) : (
                <React.Fragment>
                  {/* Filter chips for mobile screens */}
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                    sx={{
                      display: { xs: "flex", lg: "none" },
                      justifyContent: "start",
                      width: "100%",
                      mb: "8px",
                    }}
                  >
                    {selectedFilters?.updatedSelectedValues &&
                      Object.entries(
                        selectedFilters?.updatedSelectedValues
                      )?.map(([key, value]) => (
                        <Chip
                          size="small"
                          key={key}
                          label={`${key}: ${
                            key === "Year"
                              ? value?.startYear && value?.endYear
                                ? `${value.startYear} - ${value.endYear}`
                                : value || "N/A"
                              : ["Court", "Judge", "Lawyer"].includes(key) &&
                                value?.formatted
                              ? value.formatted
                              : value || "N/A"
                          }`}
                          aria-label={`Filter applied: ${key}: ${
                            key === "Year"
                              ? `${value?.startYear || "N/A"}-${
                                  value?.endYear || "N/A"
                                }`
                              : value
                          }`}
                          sx={{
                            backgroundColor: "#e3f2fd",
                            color: "#1976d2",
                            fontSize: { xs: "12px", sm: "14px" },
                            fontWeight: "500",
                          }}
                        />
                      ))}
                  </Stack>
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "rgb(255, 255, 255)",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        mt: "8px",
                        p: "16px 16px 8px 16px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: "14px", lg: "18px" },
                        }}
                      >
                        <span style={{ color: "#d2042d", fontWeight: "bold" }}>
                          {totalResults.toLocaleString()}
                        </span>{" "}
                        {totalResults === 182814
                          ? "Total Cases"
                          : "Cases Found"}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InputLabel
                          sx={{
                            marginRight: "4px",
                            fontSize: "13px",
                            color: "#555",
                            fontWeight: "500",
                          }}
                          htmlFor="resultsCount"
                        >
                          Show:
                        </InputLabel>
                        <FormControl
                          variant="outlined"
                          sx={{
                            width: 100,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "6px",
                              height: "30px",
                              padding: "8px",
                            },
                            "& fieldset": {
                              borderColor: "#ddd",
                              borderWidth: "1px",
                            },
                            "& .MuiSelect-icon": {
                              right: "4px",
                            },
                            "& .MuiSelect-select": {
                              padding: "0px",
                              display: "flex",
                              alignItems: "center",
                            },
                          }}
                        >
                          <Select
                            id="resultsCount"
                            value={resultsCount}
                            onChange={async (e) => {
                              let creditsToDeduct = 1;
                              if (credits <= 0 || credits <= creditsToDeduct) {
                                dispatch(showInsufficientCreditsModal());
                                return;
                              }
                              updateResultsCount(e.target.value);
                              setCurrentPage(1);
                              // Deduct credits using the helper
                              const deductionSuccess = deductCredits(
                                {
                                  userId,
                                  creditsToDeduct: 1,
                                  event: "Change Page Size on Case Search",
                                },
                                dispatch
                              );

                              if (!deductionSuccess) {
                                console.warn(
                                  "Failed to deduct credits. Ensure retry or user notification."
                                );
                              }
                            }}
                            sx={{
                              fontSize: { xs: "12px", md: "13px" },
                              height: "30px",
                              bgcolor: "#fff",
                            }}
                          >
                            <MenuItem
                              value={10}
                              sx={{ fontSize: { xs: "12px", md: "14px" } }}
                            >
                              10 results
                            </MenuItem>
                            <MenuItem
                              value={20}
                              sx={{ fontSize: { xs: "12px", md: "14px" } }}
                            >
                              20 results
                            </MenuItem>
                            <MenuItem
                              value={50}
                              sx={{ fontSize: { xs: "12px", md: "14px" } }}
                            >
                              50 results
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <List
                      sx={{
                        flexGrow: 1,
                        p: 0,
                        backgroundColor: "rgb(255, 255, 255)",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollBehavior: "smooth",
                        "::-webkit-scrollbar": {
                          width: "5px",
                        },
                        "::-webkit-scrollbar-track": {
                          background: "#fff",
                        },
                        "::-webkit-scrollbar-thumb": {
                          background: "#bbb",
                          borderRadius: "6px",
                        },
                      }}
                    >
                      {results?.["search_results"] &&
                        results?.["search_results"]?.results?.map(
                          (caseItem) => (
                            <ListItem
                              key={caseItem.doc_id}
                              sx={{
                                alignItems: "flex-start",
                              }}
                            >
                              <Box
                                sx={{
                                  p: 1,
                                  width: "99%",
                                  border: "1px solid #ddd",
                                  maxWidth: "99%",
                                  minWidth: "99%",
                                  borderRadius: "5px",
                                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: { xs: "14px", md: "16px" },
                                          color: "#303f9f",
                                          ml: 0.5,
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => {
                                          dispatch({
                                            type: "CASE_DETAIL_SUCCESS",
                                            payload: caseItem,
                                          });
                                          setOpenCaseInsights(true);
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: formatCaseTitle(
                                            caseItem["@search.highlights"]
                                              ?.Title?.[0] ||
                                              caseItem?.Title ||
                                              caseItem?.highlighted?.Title
                                          ),
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: 1,
                                          alignItems: "center",
                                          color: "#021657",
                                          p: 1,
                                        }}
                                      >
                                        {caseItem["@search.highlights"]
                                          ?.Citation_Name ? (
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: {
                                                xs: "12px",
                                                md: "14px",
                                              },
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: `&#8226; ${caseItem["@search.highlights"]?.Citation_Name}`,
                                            }}
                                          />
                                        ) : (
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: {
                                                xs: "12px",
                                                md: "14px",
                                              },
                                            }}
                                          >
                                            &bull; {caseItem.Citation_Name}
                                          </Typography>
                                        )}

                                        {caseItem["@search.highlights"]
                                          ?.Court ||
                                        caseItem.highlighted?.Court ? (
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: {
                                                xs: "12px",
                                                md: "14px",
                                              },
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: formatCourtName(
                                                caseItem["@search.highlights"]
                                                  ?.Court ||
                                                  caseItem.highlighted.Court
                                              ),
                                            }}
                                          />
                                        ) : (
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: {
                                                xs: "12px",
                                                md: "14px",
                                              },
                                            }}
                                          >
                                            &bull;{" "}
                                            {formatCourtNameWithoutTags(
                                              caseItem.Court
                                            )}
                                          </Typography>
                                        )}
                                      </Box>
                                    </>
                                  }
                                  secondary={
                                    <>
                                      {caseItem["@search.highlights"]
                                        ?.case_description ? (
                                        <Typography
                                          sx={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            overflow: "hidden",
                                            bgcolor: "#f5f5f5",
                                            color: "black",
                                            p: "8px 8px 0px 8px",
                                            mt: { xs: 0, lg: 1 },
                                            borderRadius: "4px",
                                            fontSize: {
                                              xs: "11px",
                                              md: "13px",
                                            },
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: getHighlightedSentences(
                                              caseItem["@search.highlights"]
                                                ?.case_description
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          sx={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            overflow: "hidden",
                                            bgcolor: "#f5f5f5",
                                            color: "black",
                                            p: "8px 8px 0px 8px",
                                            mt: { xs: 0, lg: 1 },
                                            borderRadius: "4px",
                                            fontSize: {
                                              xs: "11px",
                                              md: "13px",
                                            },
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              caseItem.captions?.[0]
                                                .highlights ||
                                              getOriginalJudgement(
                                                caseItem.Case_Description
                                              ),
                                          }}
                                        />
                                      )}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: {
                                            xs: "center",
                                            md: "flex-start",
                                          },
                                          alignItems: "center",
                                          mt: 1,
                                          gap: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            handlePreviewClick(caseItem);
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                          >
                                            <VisibilityIcon
                                              sx={{ color: "#2196f3" }}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              mr: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                          >
                                            Preview
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={async () => {
                                            let creditsToDeduct = 1;
                                            if (
                                              credits <= 0 ||
                                              credits <= creditsToDeduct
                                            ) {
                                              dispatch(
                                                showInsufficientCreditsModal()
                                              );
                                              return;
                                            }
                                            dispatch({
                                              type: "CASE_DETAIL_SUCCESS",
                                              payload: caseItem,
                                            });

                                            setOpenCaseInsights(true);
                                            const deductionSuccess =
                                              deductCredits(
                                                {
                                                  userId,
                                                  creditsToDeduct: 1,
                                                  event:
                                                    "Case Insights on Case Search",
                                                },
                                                dispatch
                                              );

                                            if (!deductionSuccess) {
                                              console.warn(
                                                "Failed to deduct credits. Ensure retry or user notification."
                                              );
                                            }
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                          >
                                            <InsightsIcon
                                              sx={{ color: "#4CAF50" }}
                                              fontSize="small"
                                            />
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              mr: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                          >
                                            Case Insights
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            handleSummaryClick(
                                              caseItem.Citation_Name
                                            );
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faRectangleList}
                                              color="#2A5C8B"
                                              fontSize="16px"
                                            />
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              mr: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                          >
                                            Summary
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <IconButton
                                            sx={{
                                              p: 0,
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                          >
                                            {bookmarkedCaseIds.has(
                                              caseItem.Bookmark_ID
                                            ) ? (
                                              <TurnedInIcon
                                                onClick={() =>
                                                  handleUnBookmark(
                                                    caseItem.Bookmark_ID
                                                  )
                                                }
                                                sx={{ color: "#d2042d" }}
                                                fontSize="small"
                                              />
                                            ) : (
                                              <TurnedInNotIcon
                                                onClick={() =>
                                                  handleBookmarkClick(caseItem)
                                                }
                                                sx={{
                                                  color: "#d2042d",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "transparent",
                                                  },
                                                }}
                                                fontSize="small"
                                              />
                                            )}
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              ml: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                            onClick={() =>
                                              bookmarkedCaseIds.has(
                                                caseItem.Bookmark_ID
                                              )
                                                ? handleUnBookmark(
                                                    caseItem.Bookmark_ID
                                                  )
                                                : handleBookmarkClick(caseItem)
                                            }
                                          >
                                            Bookmark
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            setOpenRelevantStatutes((prev) => {
                                              const isAlreadyOpen =
                                                prev[caseItem?.Bookmark_ID];

                                              const newState = isAlreadyOpen
                                                ? {}
                                                : {
                                                    [caseItem?.Bookmark_ID]: true,
                                                  };
                                              !isAlreadyOpen &&
                                                setOpenMaxims({});
                                              return newState;
                                            });
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faScaleBalanced}
                                              color="#a56d51"
                                              fontSize="16px"
                                            />
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              ml: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              fontWeight: openRelevantStatutes[
                                                caseItem?.Bookmark_ID
                                              ]
                                                ? "bold"
                                                : "normal",
                                              textDecoration:
                                                openRelevantStatutes[
                                                  caseItem?.Bookmark_ID
                                                ]
                                                  ? "underline"
                                                  : "none",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                          >
                                            Statutes
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            setOpenMaxims((prev) => {
                                              const isAlreadyOpen =
                                                prev[caseItem?.Bookmark_ID];
                                              const newState = isAlreadyOpen
                                                ? {}
                                                : {
                                                    [caseItem?.Bookmark_ID]: true,
                                                  };
                                              !isAlreadyOpen &&
                                                setOpenRelevantStatutes({});
                                              return newState;
                                            });
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faLightbulb}
                                              color="#ff9800"
                                              fontSize="16px"
                                            />
                                          </IconButton>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              display: {
                                                xs: "none",
                                                md: "block",
                                              },
                                              ml: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              fontWeight: openMaxims[
                                                caseItem?.Bookmark_ID
                                              ]
                                                ? "bold"
                                                : "normal",
                                              textDecoration: openMaxims[
                                                caseItem?.Bookmark_ID
                                              ]
                                                ? "underline"
                                                : "none",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                          >
                                            Maxims & Terms
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  }
                                />
                                <CollapsibleList
                                  isOpen={
                                    openRelevantStatutes[caseItem?.Bookmark_ID]
                                  }
                                  items={caseItem?.Statute_Collection || []}
                                  label="statutes"
                                />
                                {openMaxims[caseItem?.Bookmark_ID] && (
                                  <Maxims
                                    isOpen={!!openMaxims[caseItem?.Bookmark_ID]}
                                    id={caseItem?.Bookmark_ID}
                                  />
                                )}
                              </Box>
                            </ListItem>
                          )
                        )}
                    </List>
                  </>

                  <CaseLawPreviewModal
                    open={openPreview}
                    onClose={() => {
                      setOpenPreview(false);
                    }}
                    title={
                      selectedCase
                        ? formatCaseTitle(selectedCase?.Title || "")
                        : ""
                    }
                    court={selectedCase?.Court || ""}
                    citation={selectedCase?.Citation_Name || ""}
                    judge={selectedCase?.Judge_Name || ""}
                    caseDescriptionContent={
                      selectedCase?.Case_Description_HTML || ""
                    }
                  />
                  <CaseSummaryDialog
                    open={openSummary}
                    onClose={handleCloseSummary}
                  />
                  <CaseDetailDialog
                    open={openCaseInsights}
                    onClose={handleCloseCaseInsights}
                  />
                  <Modal
                    open={openBookmarkModal}
                    onClose={handleCloseBookmarkModal}
                    sx={{
                      "& .MuiModal-root": {
                        outline: "none",
                      },
                      "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "85vw", md: 400 },
                        margin: "auto",
                        mt: "10%",
                        p: 2,
                        borderRadius: 2,
                        bgcolor: "white",
                        boxShadow: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#303f9f",
                            fontSize: { xs: "16px", lg: "18px" },
                            fontWeight: "bold",
                          }}
                        >
                          Bookmark Case
                        </Typography>
                        <IconButton
                          sx={{
                            padding: 0,
                            color: "gray",
                            "&:hover": { color: "#d32f2f" },
                          }}
                          onClick={handleCloseBookmarkModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>

                      {/* Dropdown for selecting an existing group or creating a new group */}
                      <TextField
                        label="Select or Create Group"
                        variant="outlined"
                        select
                        fullWidth
                        size="small"
                        value={selectedGroup}
                        onChange={(e) => {
                          setSelectedGroup(e.target.value);
                          setIsCreatingNewGroup(e.target.value === "new");
                        }}
                        sx={{ mb: { xs: 1, lg: 2 } }}
                        InputProps={{
                          sx: {
                            bgcolor: "white",
                            fontSize: "13px",
                            "& input:-webkit-autofill": {
                              backgroundColor: "white !important",
                              WebkitBoxShadow:
                                "0 0 0 30px white inset !important",
                              WebkitTextFillColor: "black !important",
                            },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "13px",
                          },
                        }}
                      >
                        <MenuItem
                          value="new"
                          sx={{
                            color: "#303f9f",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Create New Group
                        </MenuItem>
                        {groups
                          ?.sort((a, b) =>
                            a.groupName === "Bookmarks"
                              ? -1
                              : b.groupName === "Bookmarks"
                              ? 1
                              : 0
                          )
                          ?.map((group) => (
                            <MenuItem
                              key={group._id}
                              value={group._id}
                              selected={group.groupName === "Bookmarks"}
                              sx={{
                                fontSize: "13px",
                                maxWidth: "400px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {group.groupName}
                            </MenuItem>
                          ))}
                      </TextField>

                      {/* Input field for creating a new group, only visible if 'Create New Group' is selected */}
                      {isCreatingNewGroup && (
                        <TextField
                          fullWidth
                          size="small"
                          label="New Group Name"
                          variant="outlined"
                          value={bookmarkGroupName}
                          onChange={(e) => setBookmarkGroupName(e.target.value)}
                          sx={{ mb: { xs: 1, lg: 2 } }}
                          InputProps={{
                            sx: {
                              bgcolor: "white",
                              fontSize: "13px",
                              "& input:-webkit-autofill": {
                                backgroundColor: "white !important",
                                WebkitBoxShadow:
                                  "0 0 0 30px white inset !important",
                                WebkitTextFillColor: "black !important",
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "13px",
                            },
                          }}
                        />
                      )}

                      {/* Text input for adding a note */}
                      <TextField
                        label="Add a Note"
                        fullWidth
                        sx={{ mb: { xs: 1, lg: 2 } }}
                        value={caseNote}
                        onChange={(e) => handleNoteChange(e.target.value)}
                        multiline
                        rows={3}
                        error={Boolean(caseNoteError)}
                        helperText={caseNoteError}
                        InputProps={{
                          sx: {
                            bgcolor: "white",
                            fontSize: "13px",
                            "& input:-webkit-autofill": {
                              backgroundColor: "white !important",
                              WebkitBoxShadow:
                                "0 0 0 30px white inset !important",
                              WebkitTextFillColor: "black !important",
                            },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            fontSize: "13px",
                          },
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          gap: "8px",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleCloseBookmarkModal}
                          color="error"
                          sx={{
                            fontSize: { xs: "13px", lg: "14px" },
                            borderRadius: "24px",
                            padding: "8px 24px",
                            textTransform: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        {/* Conditional rendering for buttons based on selection */}
                        <Button
                          variant="contained"
                          startIcon={
                            isAddBookmarkLoading && (
                              <CircularProgress
                                size={16}
                                sx={{ color: "white" }}
                              />
                            )
                          }
                          onClick={() => {
                            isCreatingNewGroup
                              ? handleCreateBookmarkGroup()
                              : handleAddCaseToGroup();
                          }}
                          disabled={
                            isCreatingNewGroup
                              ? !bookmarkGroupName
                              : !selectedGroup || caseNoteError
                          }
                          sx={{
                            fontSize: { xs: "13px", lg: "14px" },
                            borderRadius: "24px",
                            padding: "8px 24px",
                            textTransform: "none",
                            fontWeight: "bold",
                            backgroundColor: "#003D78",
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "#fff",
                            },
                            transition: "background-color 0.3s ease-in-out",
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Modal>

                  {results?.["search_results"] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(totalResults / resultsCount)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{
                          m: "5px 0",
                          "& .MuiPaginationItem-root": {
                            fontSize: "13px",
                            minWidth: "24px",
                            height: "24px",
                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "#303f9f",
                            },
                            "&.Mui-selected": {
                              color: "#fff",
                              backgroundColor: "#213165",
                              "&:hover": {
                                backgroundColor: "#303f9f",
                              },
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                  {snackbarMessage && (
                    <Snackbar
                      open={!!snackbarMessage}
                      autoHideDuration={6000}
                      onClose={() => setSnackbarMessage(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <Alert
                        onClose={() => setSnackbarMessage(null)}
                        severity={snackbarMessage.type}
                        sx={{ width: "100%" }}
                      >
                        {snackbarMessage.text}
                      </Alert>
                    </Snackbar>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </>
      ) : activeTab === 1 ? (
        <MaximsListing toggleSidebar={toggleSidebar} />
      ) : activeTab === 2 ? (
        <LegalTerms />
      ) : (
        <WordsAndPhrases />
      )}
    </Box>
  );
};

export default SearchResults;
