import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Box, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { initializeGA, trackPageView } from "./analytics";
import HomeSidebar from "./components/layout/HomeSidebar";
import Header from "./components/layout/Header";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import InsufficientCreditsModal from "./components/Modal/InsufficientCreditsModal";
import {
  hasSeenCreditsModal,
  setCredits,
  setHasSeenLowCreditAlert,
  showLowCreditModal,
} from "./actions/creditActions";
import CreditAlertModal from "./components/Modal/CreditAlertModal";
import axios from "axios";
import { ToastContainer } from "react-toastify";
const queryClient = new QueryClient();
initializeGA();
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function App() {
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const { credits, hasSeenLowCreditAlert, isCreditsInitialized } = useSelector(
    (state) => state.credit
  );

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useEffect(() => {
    if (isCreditsInitialized) {
      if (credits < 10 && !hasSeenLowCreditAlert) {
        dispatch(showLowCreditModal(true));
      } else if (credits >= 10) {
        dispatch(setHasSeenLowCreditAlert(false));
      }
    }
  }, [credits, hasSeenLowCreditAlert, isCreditsInitialized, dispatch]);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!userInfo?._id) {
          console.warn("User ID not available.");
          return;
        }

        const response = await axios.get(`${baseUrl}/getUserProfile`, {
          params: { userId: userInfo._id },
        });

        if (response.status === 200) {
          const data = response.data;
          dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: data,
          });
          dispatch(
            setCredits(
              data.credits,
              data._id,
              data.creditExpiration,
              data.creditRequestCount
            )
          );

          dispatch(hasSeenCreditsModal(data.hasSeenModal));
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [dispatch, userInfo?._id]);
  return userInfo ? (
    <QueryClientProvider client={queryClient}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Header handleSidebarToggle={toggleSidebar} />
        <ToastContainer />
        <div className="App">
          <TawkMessengerReact
            propertyId="676950dfaf5bfec1dbe0a68c"
            widgetId="1ifpm7q9d"
          />
        </div>
        <Stack
          sx={{ marginLeft: { xs: "0", lg: "45px" } }}
          direction="row"
          height="calc(100vh - 50px)"
        >
          <HomeSidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
          {/* This is where child routes will render */}
          <Outlet />
          <CreditAlertModal />
          <InsufficientCreditsModal />
        </Stack>
      </Box>
    </QueryClientProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default App;
