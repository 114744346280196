import React from "react";
import { TextField, MenuItem, Box } from "@mui/material";

const DropdownField = ({
  label,
  selectedValue,
  options,
  handleChange,
  isLoading,
  disabled = false,
  required = false,
}) => {
  return (
    <TextField
      label={
        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
          {label}
          {required && (
            <Box component="span" sx={{ ml: 0.5 }}>
              *
            </Box>
          )}
        </Box>
      }
      disabled={disabled}
      variant="outlined"
      sx={{ width: "100%" }}
      select
      size="small"
      value={selectedValue}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        sx: {
          bgcolor: "white",
          fontSize: "13px",
        },
      }}
      InputLabelProps={{
        sx: {
          fontSize: "13px",
        },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: "200px", // Limit the height of the dropdown menu
              overflowY: "auto", // Enable scrolling if options exceed height
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
              "& .MuiMenuItem-root": {
                fontSize: "13px",
              },
            },
          },
        },
      }}
    >
      {isLoading ? (
        <MenuItem value="" disabled>
          Loading...
        </MenuItem>
      ) : options?.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.Name}
            value={option}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  maxWidth: "250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {option.Name}
              </Box>
              <Box
                sx={{
                  color:
                    option.status === "Ready" ? "success.main" : "warning.main",
                  fontWeight: "bold",
                }}
              >
                {option.status}
              </Box>
            </Box>
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options Available
        </MenuItem>
      )}
    </TextField>
  );
};

export default DropdownField;
