import React, { useState, useRef } from "react";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteDialog from "../UI/DeleteDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faThumbTackSlash,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const LegalDocumentSidebar = ({
  closeSidebar,
  isLoading,
  chatTitlesList,
  setChatTitlesList,
  selectedChat,
  setSelectedChat,
  handleChatSelect,
  setDocFileUrl,
  setFileType,
  setFileUrl,
  setFile,
  setMetaData,
  previewOpen,
  setPreviewOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [newTitle, setNewTitle] = useState("");
  const [isEditing, setIsEditing] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState(null);
  const [chatToDelete, setChatToDelete] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const pinnedChats = chatTitlesList?.filter((chat) => chat.isPinned);
  const recentChats = chatTitlesList?.filter((chat) => !chat.isPinned);
  const inputRef = useRef(null);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewTitle(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleSaveEdit = async (doc_id) => {
    try {
      if (!userInfo?._id) throw new Error("User not authenticated");
      if (!newTitle.trim()) throw new Error("Title cannot be empty");
      setAnchorEl(null);
      // Find the original chat data for fallback
      const originalChat = chatTitlesList.find(
        (chat) => chat.doc_id === doc_id
      );
      if (!originalChat) throw new Error("Chat not found");

      const response = await fetch(
        `${config.documentAnalysisABaseUrl}/chat_title`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
          body: JSON.stringify({
            user_id: userInfo._id,
            doc_id: doc_id,
            chat_title: newTitle,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update title");
      }

      // Update local state using doc_id
      setChatTitlesList((prevChats) =>
        prevChats.map((chat) =>
          chat.doc_id === doc_id ? { ...chat, chat_title: newTitle } : chat
        )
      );

      setIsEditing({ doc_id: null });
      setNewTitle("");
    } catch (error) {
      console.error("Title update error:", error);
      toast.error(`Title update failed: ${error.message}`);
      // Revert using original chat data
      setNewTitle(
        chatTitlesList.find((chat) => chat.doc_id === doc_id)?.chat_title || ""
      );
    }
  };

  const handleCancelEdit = () => {
    setIsEditing({});
    setNewTitle("");
  };

  const handleEditTitle = (event, doc_id) => {
    event.stopPropagation();
    const chat = chatTitlesList.find((chat) => chat.doc_id === doc_id);
    if (!chat) return;

    setIsEditing({ doc_id });
    setNewTitle(chat.chat_title);
  };
  const handleConfirmDelete = async (doc_id) => {
    try {
      // API call to delete
      const response = await fetch(`${config.documentAnalysisABaseUrl}/reset`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: userInfo._id,
          doc_id: doc_id,
        }),
      });

      if (!response.ok) throw new Error("Delete failed");

      // Update local state
      setChatTitlesList((prev) =>
        prev.filter((chat) => chat.doc_id !== doc_id)
      );

      // Reset selection if deleted chat was selected
      setSelectedChat(null);
      setDocFileUrl("");
      setFileType("");
      setFileUrl("");
      setFile("");
      toast.success("Document Chat Deleted Successfully");
    } catch (error) {
      console.error("Delete error:", error);
      toast.error(`${error.message}`);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleDeleteChat = (event, doc_id) => {
    event.stopPropagation();
    setAnchorEl(null);

    // Find chat for confirmation dialog
    const chat = chatTitlesList.find((c) => c.doc_id === doc_id);
    if (!chat) return;

    // Store doc_id for deletion
    setChatToDelete(doc_id);
    setSelectedChatTitle({
      title: chat.chat_title,
      doc_id: chat.doc_id,
    });
    setOpenDeleteDialog(true);
  };

  // Pin/Unpin Handlers with API Integration
  const handlePinChat = async (doc_id) => {
    try {
      if (!userInfo?._id) throw new Error("User not authenticated");
      setAnchorEl(null);
      const response = await fetch(
        `${config.documentAnalysisABaseUrl}/Pin_Unpin_Document`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userInfo._id,
            doc_id: doc_id,
            action: "pin",
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to pin document");
      }

      // Update local state only after successful API response
      setChatTitlesList((prevChats) =>
        prevChats.map((chat) =>
          chat.doc_id === doc_id ? { ...chat, isPinned: true } : chat
        )
      );
    } catch (error) {
      console.error("Pin error:", error);
      toast.error(`Pin failed: ${error.message}`);
    }
  };

  const handleUnpinChat = async (doc_id) => {
    try {
      if (!userInfo?._id) throw new Error("User not authenticated");
      setAnchorEl(null);
      const response = await fetch(
        `${config.documentAnalysisABaseUrl}/Pin_Unpin_Document`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userInfo._id,
            doc_id: doc_id,
            action: "unpin",
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to unpin document");
      }

      // Update local state
      setChatTitlesList((prevChats) =>
        prevChats.map((chat) =>
          chat.doc_id === doc_id ? { ...chat, isPinned: false } : chat
        )
      );
    } catch (error) {
      console.error("Unpin error:", error);
      toast.error(`Unpin failed: ${error.message}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        width: { xs: "278px", lg: "16.6%" },
        height: { xs: "100%", md: "calc(100vh - 50px)" },
        p: "12px 0px 0 12px",
        bgcolor: "#F5F5F5",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: "8px",
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disabled={!selectedChat}
          sx={{
            backgroundColor: "#003D78",
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
            mr: 0.5,
            marginTop: "8px",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          onClick={() => {
            isMobileView && closeSidebar();
            setMetaData(null);
            setSelectedChat(null);
            setFile(null);
            setPreviewOpen(false);
          }}
        >
          New Chat
        </Button>
        {isLoading ? (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={22} />
          </Box>
        ) : chatTitlesList.length > 0 ? (
          <List
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            {[
              {
                chats: pinnedChats,
                title: "Pinned",
                icon: faThumbTack,
                isPinned: true,
              },
              {
                chats: recentChats,
                title: "Recent",
                icon: null,
                isPinned: false,
              },
            ].map(
              (section, i) =>
                section.chats.length > 0 && (
                  <React.Fragment key={i}>
                    <Typography
                      variant="overline"
                      sx={{
                        display: "flex",
                        gap: section.isPinned ? 0.5 : 0,
                        alignItems: "center",
                        padding: "4px 16px 0px 12px",
                        color: "#3C50B1",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textTransform: "none",
                      }}
                    >
                      {section.title}
                      {section.isPinned && (
                        <FontAwesomeIcon icon={section.icon} fontSize="12px" />
                      )}
                    </Typography>

                    {section.chats.map((title) => (
                      <ListItem disablePadding key={title.doc_id}>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": { backgroundColor: "#ECECEC" },
                            },
                            "&:hover": { backgroundColor: "#ECECEC" },
                          }}
                          selected={selectedChat === title.doc_id}
                          onClick={() => {
                            isMobileView && closeSidebar();
                            handleChatSelect(title.doc_id);
                          }}
                        >
                          {isEditing.doc_id === title.doc_id ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onKeyPress={(e) =>
                                  e.key === "Enter" &&
                                  handleSaveEdit(title.doc_id)
                                }
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit(title.doc_id);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.chat_title}
                              </Typography>

                              {selectedChat === title.doc_id && (
                                <>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                  >
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent event bubbling
                                        section.isPinned
                                          ? handleUnpinChat(title.doc_id)
                                          : handlePinChat(title.doc_id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          section.isPinned
                                            ? faThumbTackSlash
                                            : faThumbTack
                                        }
                                        width="16px"
                                        height="16px"
                                        style={{ marginRight: "8px" }}
                                      />
                                      {section.isPinned ? "Unpin" : "Pin"}
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={(e) =>
                                        handleEditTitle(e, title.doc_id)
                                      }
                                    >
                                      <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        fontSize: "14px",
                                        color: "#d32f2f",
                                      }}
                                      onClick={(e) =>
                                        handleDeleteChat(e, title.doc_id)
                                      }
                                    >
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 16, mr: 1 }}
                                      />
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </React.Fragment>
                )
            )}
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              m: "auto",
            }}
          >
            <Typography
              variant="subtitle2"
              color="textSecondary"
              textAlign="center"
            >
              You have no previous documents.
              <br />
              Start a new conversation! 🚀
            </Typography>
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={() => handleConfirmDelete(chatToDelete)}
        selectedChat={selectedChatTitle}
      />
    </Box>
  );
};

export default LegalDocumentSidebar;
