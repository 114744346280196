import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  formatRefLabel,
  convertHtmlToTitleCase,
} from "../../utils/HelperFunction/textFormatting";
import axios from "axios";
import { useDispatch } from "react-redux";

const caseLawBaseUrl = process.env.REACT_APP_CASE_LAW_BASE_URL;

const formatCaseTitle = (text) => {
  return text
    .split("Honorable")[0]
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const Listing = ({
  data,
  openCitations,
  openCitationText,
  handleCitationsClick,
  setOpenCitationText,
  loading,
  isMobileView,
  setOpenCaseInsights,
  setLoadingCaseInsights,
}) => {
  const dispatch = useDispatch();

  const fetchCaseLawData = async (id) => {
    setLoadingCaseInsights(true);
    const payload = {
      query: "",
      skip: 0,
      top: 10,
      mode: "keyword",
      filter: `Bookmark_ID eq ${id}`,
      sort_by: "Most Relevant",
    };
    try {
      const response = await axios.post(`${caseLawBaseUrl}/lawcases`, payload);
      dispatch({
        type: "CASE_DETAIL_SUCCESS",
        payload: response.data?.["search_results"]?.results[0],
      });
    } catch (error) {
      console.log(
        "Error fetching case law for Maxim citation case: ",
        error.message
      );
    } finally {
      setLoadingCaseInsights(false);
    }
  };

  const handleCitationCaseClick = (bookmarkId) => {
    setOpenCaseInsights(true);
    fetchCaseLawData(bookmarkId);
  };

  return (
    <List
      sx={{
        p: 0,
        flexGrow: 1,
        backgroundColor: "rgb(255, 255, 255)",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        overflowY: "auto",
        scrollBehavior: "smooth",
        "::-webkit-scrollbar": { width: "5px" },
        "::-webkit-scrollbar-track": { background: "#fff" },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      {data &&
        data?.map((maxim) => (
          <ListItem key={maxim.doc_id} sx={{ alignItems: "flex-start" }}>
            <Box
              sx={{
                p: 1,
                width: "100%",
                border: "1px solid #ddd",
                borderRadius: "5px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                transform: "translateY(-2px)",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "14px", md: "16px" },
                      color: "#303f9f",
                      ml: 0.5,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: maxim["@search.highlights"]?.title?.[0]
                        ? convertHtmlToTitleCase(
                            maxim["@search.highlights"].title[0]
                          )
                        : formatRefLabel(maxim.title),
                    }}
                  />
                }
                secondary={
                  <>
                    <Typography
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        bgcolor: "#f5f5f5",
                        color: "black",
                        p: "6px 8px",
                        mt: { xs: 0, lg: 1 },
                        borderRadius: "4px",
                        fontSize: { xs: "11px", md: "13px" },
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#303f9f" }}>
                        Interpretation:
                      </span>{" "}
                      {maxim.definition}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-start" },
                        alignItems: "center",
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        onClick={() => handleCitationsClick(maxim.doc_id)}
                      >
                        <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                          {openCitations[maxim.doc_id] ? (
                            <ExpandLessIcon sx={{ color: "#ff9800" }} />
                          ) : (
                            <ExpandMoreIcon sx={{ color: "#ff9800" }} />
                          )}
                        </IconButton>
                        <Typography
                          variant="body2"
                          sx={{
                            display: { xs: "none", md: "block" },
                            fontSize: { xs: "12px", md: "14px" },
                            mr: 0.5,
                            color: "#303f9f",
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          Relevant Judgements
                        </Typography>
                      </Box>
                    </Box>
                  </>
                }
              />
              <Collapse
                in={openCitations[maxim.doc_id]}
                timeout="auto"
                unmountOnExit
              >
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{ color: "#303f9f" }}
                      size={isMobileView ? 16 : 20}
                    />
                  </Box>
                ) : (
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      maxHeight: "24rem",
                      overflowY: "auto",
                      scrollBehavior: "smooth",
                      "::-webkit-scrollbar": { width: "5px" },
                      "::-webkit-scrollbar-track": { background: "#fff" },
                      "::-webkit-scrollbar-thumb": {
                        background: "#bbb",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    {maxim.citations?.map((citation) => {
                      const isCitationOpen =
                        openCitationText[citation.bookmark_id];
                      return (
                        <Box
                          key={citation.bookmark_id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderTop: "1px solid #ddd",
                            mx: "16px",
                            py: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                setOpenCitationText((prev) => ({
                                  ...prev,
                                  [citation.bookmark_id]:
                                    !prev[citation.bookmark_id],
                                }))
                              }
                              sx={{ color: "#303f9f", ml: 1 }}
                            >
                              {isCitationOpen ? (
                                <RemoveCircleOutlineIcon fontSize="small" />
                              ) : (
                                <AddCircleOutlineIcon fontSize="small" />
                              )}
                            </IconButton>
                            <Typography
                              sx={{
                                fontSize: { xs: "11px", md: "13px" },
                                fontWeight: "bold",
                                flexGrow: 1,
                                "&:hover": {
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() =>
                                handleCitationCaseClick(citation.bookmark_id)
                              }
                            >
                              {formatCaseTitle(citation.case_title)} -{" "}
                              {citation.citation_name}
                            </Typography>
                          </Box>
                          {isCitationOpen && (
                            <Collapse
                              in={isCitationOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: "11px", md: "12px" },
                                  textAlign: "justify",
                                  mt: "8px",
                                  ml: "16px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#303f9f",
                                  }}
                                >
                                  Explanation:
                                </span>{" "}
                                {citation.description ||
                                  "No additional information available."}
                              </Typography>
                            </Collapse>
                          )}
                        </Box>
                      );
                    })}
                  </List>
                )}
              </Collapse>
            </Box>
          </ListItem>
        ))}
    </List>
  );
};

export default Listing;
