import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import TinyMCE from "./TinyMCE";

const TextEditor = ({ initialContent, handleEdit }) => {
  const [content, setContent] = useState(initialContent);

  const handleChange = (value) => {
    setContent(value);
    handleEdit(value);
  };

  return (
    <TinyMCE
      value={content}
      onChange={handleChange}
      ListingToolbar={true}
      height="100%"
      toolbar="fontfamily fontsize bold italic underline forecolor removeformat align numlist bullist strikethrough"
    />
  );
};

export default TextEditor;
