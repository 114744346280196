import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  onKeyPress,
  hasSearched,
  defaultMaxims,
}) => {
  const handleSearch = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    if (newValue === "" && hasSearched) {
      defaultMaxims();
    }
  };

  return (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      value={searchTerm}
      onChange={handleSearch}
      onKeyDown={onKeyPress}
      InputProps={{
        sx: {
          p: 0,
          backgroundColor: "#ffffff",
          borderRadius: "20px",
        },

        startAdornment: (
          <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
        ),
      }}
      sx={{
        width: { xs: "80%", lg: "50%" },
        margin: " 4px auto",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#ccc",
          },
          "&:hover fieldset": {
            borderColor: "#00509e",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#00509e",
          },
        },
        "& input": {
          padding: "10px",
          fontSize: { xs: "12px", md: "14px" },
        },
      }}
    />
  );
};

export default SearchBar;
