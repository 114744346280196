import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { TextField, Box } from "@mui/material";
import { styled } from "@mui/system";
import "react-datepicker/dist/react-datepicker.css";
import { getJournalYearRange } from "../../utils/HelperFunction/minAndMaxYear";
import { fetchOptions } from "../../actions/searchActions";
import { useDispatch } from "react-redux";
const DatePickerWrapper = styled("div")`
  .react-datepicker {
    font-family: "Roboto", Helvetica, Arial, sans-serif !important;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    z-index: 9999;
    background-color: white;
  }
  .react-datepicker-popper {
    top: -7px !important;
  }
  .react-datepicker__year--container {
    padding: 0.4rem;
  }

  .react-datepicker__year-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.1rem;
    max-width: none;
    justify-items: center;
    margin: 0;
  }

  .react-datepicker__year-text {
    padding: 0.1rem;
    width: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #f1f5f9;
    }
  }

  .react-datepicker__year-text--selected {
    background-color: #2563eb !important;
    color: white !important;

    &:hover {
      background-color: #1d4ed8 !important;
    }
  }

  .react-datepicker__navigation {
    top: 0.55rem;

    &--previous {
      left: 0rem;
    }

    &--next {
      right: 0rem;
    }
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid #e2e8f0;
    padding: 0.6rem;
  }
`;
// Styled component to override react-datepicker styles
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 13px;
  padding: 0;
  &:focus {
    outline: none;
  }
`;

const CustomYearRangePicker = ({
  startYear,
  endYear,
  onStartYearChange,
  onEndYearChange,
  Journal,
  handleSelect,
  selectedValues,
  setYearError,
}) => {
  const [startYearError, setStartYearError] = useState(null);
  const [endYearError, setEndYearError] = useState(null);
  const { minYear, maxYear } = getJournalYearRange(Journal);
  const dispatch = useDispatch();

  const handleYear = (newStartYear, newEndYear) => {
    if (!newStartYear && !newEndYear) {
      setYearError(""); // Clear any existing errors
      dispatch(fetchOptions(null, "Year", selectedValues)); // Reset Year filter
      handleSelect("Year", null); // Clear Year selection
      return;
    }

    if (!newStartYear || !newEndYear) {
      setYearError("Both start and end year is require");
      return; // Do nothing if either value is missing
    }

    // Ensure valid year range
    if (newStartYear > newEndYear) {
      setYearError("Start year must be before end year");
    } else {
      setYearError(null); // Clear error if valid
      dispatch(
        fetchOptions(
          { startYear: newStartYear, endYear: newEndYear },
          "Year",
          selectedValues
        )
      );
      handleSelect("Year", { startYear: newStartYear, endYear: newEndYear });
    }
  };

  const handleStartYearChange = (date) => {
    const year = date ? date.getFullYear() : null;
    onStartYearChange(year);
    setStartYearError(null);
    if (year && endYear && year > endYear) {
      setEndYearError("End year must be after start year");
    } else {
      setEndYearError(null);
    }

    handleYear(year, endYear); // Call handleYear with updated values
  };

  const handleEndYearChange = (date) => {
    const year = date ? date.getFullYear() : null;
    onEndYearChange(year);
    setEndYearError(null);
    if (year && startYear && year < startYear) {
      setStartYearError("Start year must be before end year");
    } else {
      setStartYearError(null);
    }

    handleYear(startYear, year); // Call handleYear with updated values
  };

  useEffect(() => {
    if (!startYear && !endYear) {
      setStartYearError(null);
      setEndYearError(null);
    }
    return () => {};
  }, []);

  const CustomInput = React.forwardRef(
    ({ value, onClick, onChange, placeholder, error, helperText }, ref) => (
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
        error={!!error}
        helperText={helperText}
        inputRef={ref}
        sx={{
          width: "95%",
          ml: "5px",
          mb: "12px",
          "& .MuiInputBase-input": {
            fontSize: "13px",
            padding: "8.5px 14px",
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "12px",
          },
        }}
      />
    )
  );

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <DatePickerWrapper>
        <StyledDatePicker
          selected={startYear ? new Date(startYear, 0) : null}
          onChange={handleStartYearChange}
          showYearPicker
          dateFormat="yyyy"
          placeholderText="Start year"
          yearItemNumber={9}
          isClearable={true}
          customInput={
            <CustomInput
              placeholder="Enter Start Year (e.g. 2023)"
              error={startYearError}
              helperText={startYearError}
            />
          }
          minDate={new Date(minYear, 0)}
          maxDate={new Date(maxYear, 0)}
        />
      </DatePickerWrapper>
      <DatePickerWrapper>
        <StyledDatePicker
          selected={endYear ? new Date(endYear, 0) : null}
          onChange={handleEndYearChange}
          showYearPicker
          dateFormat="yyyy"
          placeholderText="End year"
          yearItemNumber={9}
          isClearable={true}
          clearButtonClassName=""
          customInput={
            <CustomInput
              placeholder="Enter End Year (e.g. 2024)"
              error={endYearError}
              helperText={endYearError}
            />
          }
          minDate={new Date(minYear, 0)}
          maxDate={new Date(maxYear, 0)}
        />
      </DatePickerWrapper>
    </Box>
  );
};

export default CustomYearRangePicker;
