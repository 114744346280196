import {
  copyBookmarkApi,
  createNewGroupApi,
  deleteGroupApi,
  fetchBookmarkGroupsApi,
  fetchCasesApi,
  fetchStatutesApi,
  removeNoteApi,
  renameGroupApi,
  updateNoteApi,
} from "./bookmarksApi";
import {
  FETCH_BOOKMARK_GROUPS_REQUEST,
  FETCH_BOOKMARK_GROUPS_SUCCESS,
  FETCH_BOOKMARK_GROUPS_FAILURE,
  FETCH_CASES_REQUEST,
  FETCH_CASES_SUCCESS,
  FETCH_CASES_FAILURE,
  FETCH_STATUTES_REQUEST,
  FETCH_STATUTES_SUCCESS,
  FETCH_STATUTES_FAILURE,
  EDIT_GROUP_TITLE,
  DELETE_GROUP,
  UPDATE_BOOKMARK_CASE_NOTES,
  UPDATE_NOTES_IN_GROUP,
  REMOVE_BOOKMARK_CASE_NOTES,
  UPDATE_BOOKMARK_STATUTE_NOTES,
  REMOVE_BOOKMARK_STATUTE_NOTES,
  COPY_BOOKMARK_TO_GROUP,
} from "./bookmarksTypes";

export const fetchBookmarkGroups =
  (userId, isItemsRequired = false) =>
  async (dispatch) => {
    dispatch({ type: FETCH_BOOKMARK_GROUPS_REQUEST });
    try {
      const data = await fetchBookmarkGroupsApi(userId);
      const reOrderedGroups = data?.sort((a, b) =>
        a.groupName === "Bookmarks" ? -1 : b.groupName === "Bookmarks" ? 1 : 0
      );
      dispatch({
        type: FETCH_BOOKMARK_GROUPS_SUCCESS,
        payload: reOrderedGroups,
      });
      if (isItemsRequired) {
        dispatch(fetchBookmarkCases(reOrderedGroups?.[0]));
        dispatch(fetchBookmarkStatutes(reOrderedGroups?.[0]));
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
      dispatch({ type: FETCH_BOOKMARK_GROUPS_FAILURE, payload: error.message });
    }
  };

export const renameGroup =
  (userId, groupId, newGroupName) => async (dispatch) => {
    try {
      await renameGroupApi(userId, groupId, newGroupName);
      dispatch({ type: EDIT_GROUP_TITLE, payload: { groupId, newGroupName } });
    } catch (error) {
      console.error("Error renaming group:", error);
    }
  };

export const createNewGroup = (userId, groupName) => async (dispatch) => {
  try {
    await createNewGroupApi(userId, groupName);
    dispatch(fetchBookmarkGroups(userId));
  } catch (error) {
    console.error("Error renaming group:", error);
  }
};

export const deleteGroup = (userId, groupId) => async (dispatch) => {
  try {
    await deleteGroupApi(userId, groupId);
    dispatch({ type: DELETE_GROUP, payload: { groupId } });
  } catch (error) {
    console.error("Error renaming group:", error);
  }
};

export const fetchBookmarkCases = (group) => async (dispatch) => {
  dispatch({ type: FETCH_CASES_REQUEST });

  try {
    const caseIds =
      group.cases?.length > 0
        ? group.cases
            .map((caseItem) => `Bookmark_ID eq ${caseItem.id}`)
            .join(" or ")
        : "";

    if (caseIds) {
      const searchData = await fetchCasesApi(caseIds); // Call the API
      const casesWithDates = searchData.map((caseItem) => {
        const matchedCase = group.cases.find(
          (item) => item.id === caseItem.Bookmark_ID
        );
        return {
          ...caseItem,
          addedAt: matchedCase ? matchedCase.addedAt : null,
          note: matchedCase ? matchedCase.note : "",
          type: "case",
        };
      });
      dispatch({ type: FETCH_CASES_SUCCESS, payload: casesWithDates });
    } else {
      dispatch({ type: FETCH_CASES_SUCCESS, payload: [] });
    }
  } catch (error) {
    dispatch({ type: FETCH_CASES_FAILURE, payload: error.message });
    console.error("Error fetching cases:", error);
  }
};

export const fetchBookmarkStatutes = (group) => async (dispatch) => {
  dispatch({ type: FETCH_STATUTES_REQUEST });

  try {
    const statutesIds =
      group.statutes?.length > 0
        ? group.statutes
            .map((statutesItem) => `Bookmark_ID eq ${statutesItem.id}`)
            .join(" or ")
        : "";

    if (statutesIds) {
      const searchData = await fetchStatutesApi(statutesIds); // Call the API
      const statutesWithDates = searchData.map((statutesItem) => {
        const matchedStatutes = group.statutes.find(
          (item) => item.id === statutesItem.Bookmark_ID
        );
        return {
          ...statutesItem,
          addedAt: matchedStatutes ? matchedStatutes.addedAt : null,
          note: matchedStatutes ? matchedStatutes.note : "",
          type: !matchedStatutes?.type ? "statute" : matchedStatutes?.type,
        };
      });
      dispatch({ type: FETCH_STATUTES_SUCCESS, payload: statutesWithDates });
    } else {
      dispatch({ type: FETCH_STATUTES_SUCCESS, payload: [] });
    }
  } catch (error) {
    dispatch({ type: FETCH_STATUTES_FAILURE, payload: error.message });
    console.error("Error fetching statutes:", error);
  }
};

export const updateNote =
  ({ userId, groupId, itemId, itemType, note }) =>
  async (dispatch) => {
    try {
      await updateNoteApi(userId, groupId, itemId, itemType, note);
      if (itemType === "case") {
        dispatch({
          type: UPDATE_BOOKMARK_CASE_NOTES,
          payload: { itemId, updatedNote: note },
        });
      } else {
        dispatch({
          type: UPDATE_BOOKMARK_STATUTE_NOTES,
          payload: { itemId, updatedNote: note },
        });
      }
      dispatch({
        type: UPDATE_NOTES_IN_GROUP,
        payload: { groupId, itemType, itemId, note },
      });
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

export const removeNote =
  ({ userId, groupId, itemId, itemType }) =>
  async (dispatch) => {
    try {
      await removeNoteApi(userId, groupId, itemId, itemType);
      if (itemType === "case") {
        dispatch({ type: REMOVE_BOOKMARK_CASE_NOTES, payload: { itemId } });
      } else {
        dispatch({ type: REMOVE_BOOKMARK_STATUTE_NOTES, payload: { itemId } });
      }
      dispatch({
        type: UPDATE_NOTES_IN_GROUP,
        payload: { groupId, itemId, itemType, note: "" },
      });
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

export const copyBookmark =
  ({ userId, fromGroupId, toGroupId, caseId, statuteId, selectedItem }) =>
  async (dispatch) => {
    try {
      await copyBookmarkApi(userId, fromGroupId, toGroupId, caseId, statuteId);
      dispatch({
        type: COPY_BOOKMARK_TO_GROUP,
        payload: { groupId: toGroupId, selectedItem },
      });
      return { success: true, message: "Bookmark copied!" };
    } catch (error) {
      console.error("Error copying bookmark:", error);
      return { success: false, message: error.message };
    }
  };
