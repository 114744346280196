import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack, useMediaQuery } from "@mui/system";
import FileViewer from "react-file-viewer";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";

const UploadAndPreview = ({
  closeSidebar,
  isUploading,
  loadingDocx,
  file,
  setFile,
  setFileUrl,
  handleFileChange,
  fileUrl,
  docFileUrl,
}) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isDragging, setIsDragging] = useState(false);
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setFileUrl(URL.createObjectURL(droppedFile));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "25%" },
          minWidth: { xs: "100%", md: "25%" },
          maxWidth: { xs: "100%", md: "25%" },
          bgcolor: "#fff",
          borderRadius: "8px",
          p: { xs: 0, lg: 2 },
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            pb: { xs: 1, lg: 0 },
            mb: { xs: 1, lg: 0 },
            borderBottom: 1,
            borderColor: "#ccc",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#303f9f",
              fontSize: { xs: "16px", lg: "18px" },
            }}
          >
            Document Preview
          </Typography>
          <IconButton
            sx={{
              padding: 0,
              color: "gray",
              "&:hover": { color: "#d32f2f" },
              display: { xs: "block", lg: "none" },
            }}
            onClick={closeSidebar}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {/* Upload Section */}
        {isUploading || loadingDocx ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 120,
            }}
          >
            <CircularProgress
              sx={{
                color: "#303f9f",
                animationDuration: "0.8s",
              }}
              size={isMobileView ? 22 : 28}
            />
          </Box>
        ) : !file ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: file ? "none" : "2px dashed #ccc",
              borderRadius: "8px",
              padding: "20px",
              bgcolor: isDragging ? "#E3F2FD" : "#F1F9FF",
              transition: "background-color 0.3s",
              cursor: "pointer",
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <>
              <UploadFileIcon sx={{ fontSize: 40, color: "#3C50B1" }} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{ mt: 2 }}
              >
                Drag & Drop your PDF, Word or Text file here
              </Typography>
              <Button
                variant="contained"
                component="label"
                sx={{
                  mt: 2,
                  backgroundColor: "#003D78",
                  textTransform: "none",
                }}
              >
                Choose a File
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.txt"
                />
              </Button>
            </>
          </Box>
        ) : (
          <Paper
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "#F1F9FF",
            }}
          >
            {file.type === "application/pdf" ? (
              <PictureAsPdfIcon sx={{ color: "#d32f2f" }} />
            ) : (
              <DescriptionIcon sx={{ color: "#1976d2" }} />
            )}
            <Typography
              variant="body1"
              sx={{
                ml: 1,
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {file.name}
            </Typography>
          </Paper>
        )}

        {/* Preview Section */}
        {!isUploading && !loadingDocx && (
          <Box
            sx={{
              border: "2px solid #ccc",
              borderRadius: "8px",
              minHeight: "200px",
              bgcolor: file ? "#f0f4ff" : "#f5f5f5",
              display: "flex",
              flexGrow: file ? 1 : 0,
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
              overflowY: "auto",
            }}
          >
            {!file ? (
              <Typography variant="subtitle2" color="textSecondary">
                No document loaded for preview
              </Typography>
            ) : docFileUrl ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  overflowY: "auto",
                  overflowX: "auto",

                  "::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "::-webkit-scrollbar:horizontal": {
                    height: "4px",
                    width: "5px",
                  },
                  "::-webkit-scrollbar-thumb:horizontal": {
                    background: "#bbb",
                    borderRadius: "6px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#fff",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#bbb",
                    borderRadius: "6px",
                  },
                  "& > .pg-viewer-wrapper": {
                    "::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "::-webkit-scrollbar:horizontal": {
                      height: "4px",
                      width: "5px",
                    },
                    "::-webkit-scrollbar-thumb:horizontal": {
                      background: "#bbb",
                      borderRadius: "6px",
                    },
                    "::-webkit-scrollbar-track": {
                      background: "#fff",
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#bbb",
                      borderRadius: "6px",
                    },
                  },
                }}
              >
                <FileViewer
                  fileType={"docx"}
                  filePath={docFileUrl}
                  onError={(e) => console.log("Error:", e)}
                />
              </Box>
            ) : (
              <iframe
                src={fileUrl}
                title="Document Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  border: "none",
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default UploadAndPreview;
