import { Drawer, Stack } from "@mui/material";
import { useMediaQuery } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import DocumentAnalysis from "../../components/DocumentAnalysis";
import LegalDocumentSidebar from "../../components/layout/LegalDocumentSidebar";
import config from "../../config/api";
import { useSelector } from "react-redux";
import ComingSoonModal from "../../components/Modal/CommingSoonModal";

export const DocumentAnalysisParent = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDocx, setLoadingDocx] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatTitlesList, setChatTitlesList] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [metaData, setMetaData] = useState({});
  const [docFileUrl, setDocFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState(null);
  const abortControllerRef = useRef(null);
  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  // Fetch initial chat data
  useEffect(() => {
    const fetchChatInitInfo = async () => {
      try {
        const response = await fetch(
          `${config.documentAnalysisABaseUrl}/get_init_info`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userInfo?._id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch chat data");
        }

        const data = await response.json();
        setChatTitlesList(data);
      } catch (err) {
        console.error("Error fetching chat data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (userInfo?._id) {
      fetchChatInitInfo();
    }
  }, [userInfo?._id]);

  // Usage when selecting a chat
  const handleChatSelect = (doc_id) => {
    setSelectedChat(doc_id);
    handleReadDocument(doc_id);
  };

  // Function to handle document reading
  const handleReadDocument = async (doc_id) => {
    setLoadingDocx(true);

    // Abort previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    // Reset state
    setDocFileUrl("");
    setFileUrl("");
    setFileType("");
    // setFile(null);

    try {
      // Define the fetch requests
      const readDocumentRequest = fetch(
        `${config.documentAnalysisABaseUrl}/read`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ doc_id, user_id: userInfo?._id }),
          signal: abortController.signal,
        }
      );

      const getMetadataRequest = fetch(
        `${config.documentAnalysisABaseUrl}/get_metadata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ doc_id, user_id: userInfo?._id }),
          signal: abortController.signal,
        }
      );

      // Execute both requests in parallel
      const [readResponse, metadataResponse] = await Promise.all([
        readDocumentRequest,
        getMetadataRequest,
      ]);

      // Handle read document response
      if (!readResponse.ok) {
        throw new Error(`Read document error! status: ${readResponse.status}`);
      }

      const contentType = readResponse.headers.get("content-type") || "";
      const blob = await readResponse.blob();
      const dataUrl = URL.createObjectURL(blob);
      const filename =
        chatTitlesList?.find((x) => x?.doc_id === doc_id)?.file_name ||
        "unknown";
      const type =
        filename.split(".").pop() || contentType.split("/").pop() || "unknown";
      setFileType(type);

      if (
        contentType.includes("pdf") ||
        contentType.includes("image") ||
        contentType.includes("excel")
      ) {
        setFileUrl(dataUrl);
        setDocFileUrl("");
      } else {
        setDocFileUrl(dataUrl);
        setFileUrl("");
      }

      const syntheticFile = new File([blob], filename, {
        type: contentType,
        lastModified: Date.now(),
      });

      setFile(syntheticFile);

      // Handle metadata response
      if (!metadataResponse.ok) {
        throw new Error(
          `Metadata fetch error! status: ${metadataResponse.status}`
        );
      }

      const metadata = await metadataResponse.json();
      setMetaData(metadata);
      setLoadingDocx(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.error("Error:", error);
        setFileUrl("");
        setDocFileUrl("");
        setFileType("");
        setLoadingDocx(false);
      }
    } finally {
      if (abortControllerRef.current === abortController) {
        abortControllerRef.current = null;
      }
    }
  };

  return (
    <Stack direction="row" height="calc(100vh - 50px)" width="100%">
      {isMobileView ? (
        <Drawer
          anchor="left"
          open={isSidebarVisible}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={toggleSidebarVisibility}
        >
          <LegalDocumentSidebar
            closeSidebar={toggleSidebarVisibility}
            isLoading={isLoading}
            chatTitlesList={chatTitlesList}
            setChatTitlesList={setChatTitlesList}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            handleChatSelect={handleChatSelect}
            setDocFileUrl={setDocFileUrl}
            setFileType={setFileType}
            setFileUrl={setFileUrl}
            setFile={setFile}
            setMetaData={setMetaData}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />
        </Drawer>
      ) : (
        !isMobileView && (
          <LegalDocumentSidebar
            isLoading={isLoading}
            chatTitlesList={chatTitlesList}
            setChatTitlesList={setChatTitlesList}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            handleChatSelect={handleChatSelect}
            setDocFileUrl={setDocFileUrl}
            setFileType={setFileType}
            setFileUrl={setFileUrl}
            setFile={setFile}
            setMetaData={setMetaData}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />
        )
      )}

      <DocumentAnalysis
        toggleSidebar={toggleSidebarVisibility}
        chatTitlesList={chatTitlesList}
        setChatTitlesList={setChatTitlesList}
        fileType={fileType}
        fileUrl={fileUrl}
        docFileUrl={docFileUrl}
        setDocFileUrl={setDocFileUrl}
        setFileType={setFileType}
        setFileUrl={setFileUrl}
        file={file}
        setFile={setFile}
        loadingDocx={loadingDocx}
        setSelectedChat={setSelectedChat}
        metaData={metaData}
        setMetaData={setMetaData}
        selectedChat={selectedChat}
        previewOpen={previewOpen}
        setPreviewOpen={setPreviewOpen}
      />
      <ComingSoonModal />
    </Stack>
  );
};
