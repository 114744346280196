const caseSearchTypes = {
  // Generic types for Maxims, Legal Terms and Words & Phrases
  FETCH_LISTING_REQUEST: "FETCH_LISTING_REQUEST",
  FETCH_LISTING_SUCCESS: "FETCH_LISTING_SUCCESS",
  FETCH_LISTING_FAILURE: "FETCH_LISTING_FAILURE",
  SET_LISTING_FILTERS: "SET_LISTING_FILTERS",
  RESET_LISTING_FILTERS: "RESET_LISTING_FILTERS",
  UPDATE_LISTING_SEARCH_TERM: "UPDATE_LISTING_SEARCH_TERM",
};

export default caseSearchTypes;
