import React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import lawgptLogo from "./../assets/images/lawgptLogo.png";
import { StyledImage } from "./ChatInterface";

// Define allowed file types
const ALLOWED_FILE_TYPES = [
  // "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // "text/plain",
  // "application/vnd.ms-excel",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

// Maximum file size (10MB)
const MAX_FILE_SIZE = 10 * 1024 * 1024;

const DocumentUploadDA = ({ handleFileChange, setFile }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateFile = (file) => {
    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setErrorMessage(
        "Invalid file type. Please upload a document file (Word)."
      );
      return false;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage("File is too large. Maximum size is 10MB.");
      return false;
    }

    return true;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);

    if (droppedFiles.length === 0) return;

    // Validate files
    const validFiles = droppedFiles.filter(validateFile);
    if (validFiles.length > 0) {
      setFile(validFiles);
      handleFileChange();
    }
  };

  // Modified handleFileChange to include validation
  const handleInputFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length === 0) return;

    // Validate files
    const validFiles = selectedFiles.filter(validateFile);

    if (validFiles.length > 0) {
      setFile(validFiles);
      setErrorMessage(""); // Clear any previous errors
      handleFileChange(e); // Pass the event to parent if needed
    } else {
      // If all files are invalid, clear selection
      e.target.value = null;
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 550,
        mx: "auto",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          border: "none",
          boxShadow: "none",
          width: "100%",
          maxWidth: 800,
          mx: "auto",
        }}
      >
        <CardContent sx={{ p: 1 }}>
          <Box
            sx={{
              mb: { xs: 4, md: 3 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledImage src={lawgptLogo} alt="LawGPT Logo" />
          </Box>

          {/* Clickable Drop Zone */}
          <Box
            component="label"
            htmlFor="file-upload"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed #ccc",
              borderRadius: 2,
              height: 170,
              backgroundImage:
                "linear-gradient(to bottom right, #f8faff 0%, #f5f9ff 100%)",
              transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
              cursor: "pointer",
              position: "relative",
              width: "100%",
              "&:hover": {
                borderColor: "#2196f3",
                boxShadow: "0 8px 24px rgba(33, 150, 243, 0.1)",
              },
              bgcolor: isDragging ? "#f0f8ff" : "#fafafa",
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {/* Hidden File Input */}
            <input
              id="file-upload"
              type="file"
              hidden
              onChange={handleInputFileChange}
              accept=".doc,.docx"
            />

            {/* Content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1,
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <CloudUpload
                sx={{
                  fontSize: 40,
                  color: "#303f9f",
                  mb: 1,
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#303f9f",
                  mb: 1,
                }}
              >
                Upload Document
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  fontWeight: 600,
                  color: "text.primary",
                  textAlign: "center",
                  mb: 0.5,
                  fontSize: { xs: "14px", lg: "16px" },
                  px: 2,
                }}
              >
                {isDragging
                  ? "Drop files here..."
                  : "Click to upload or drag & drop documents"}
              </Typography>

              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ textAlign: "center", px: 2 }}
              >
                Supported formats: DOC, DOCX (Max 10MB)
              </Typography>
            </Box>
          </Box>

          {errorMessage && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
                borderRadius: 1,
                width: "100%",
              }}
            >
              <Typography variant="body2">{errorMessage}</Typography>
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DocumentUploadDA;
