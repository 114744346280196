import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Typography } from "@mui/material";

const NoResultsFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          fontSize: { xs: "35px", lg: "50px" },
          marginBottom: { xs: "5px", lg: "10px" },
          color: "#303f9f",
        }}
      >
        <SearchIcon fontSize="inherit" />
      </Box>
      <Typography
        variant="h5"
        sx={{
          fontSize: { xs: "16px", lg: "24px" },
          marginBottom: { xs: "5px", lg: "10px" },
        }}
      >
        No Results Found
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: { xs: "12px", lg: "16px" }, color: "#666" }}
      >
        Please review your search term or filter criteria.
      </Typography>
    </Box>
  );
};

export default NoResultsFound;
